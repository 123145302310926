import { defineStore } from 'pinia';
import { CategoryResult } from '~/composables';
import categoryListGql from './graphql/categoryList.gql';
import type { RemovableFilterInterface } from '~/modules/catalog/category/components/filters/useFilters';

interface CategoryState {
  rawCategories: CategoryResult | null,
  compareProducts: any[],
  compareCategoryName: string,
  removableFilterStore: RemovableFilterInterface[],
  selectedCategories: Array<{
    name: string | null | undefined,
    uid: string | number | null | undefined,
    id?: string | number | null | undefined,
    url_path?: string | null | undefined,
    url_suffix?: string | null | undefined
  }> | null
}

export const useCategoryStore = defineStore('category', {
  state: (): CategoryState => ({
    rawCategories: null,
    selectedCategories: [],
    compareProducts: [],
    compareCategoryName: '',
    removableFilterStore: []
  }),
  actions: {
    async load() {
      const { data }: { data: { categories?: CategoryResult } } = await this.$nuxt.app.$vsf.$magento.api.customQuery({ query: categoryListGql });
      this.rawCategories = data?.categories ?? null;
    },
    updatedRemovableFilters (filters: RemovableFilterInterface[]) {
      this.removableFilterStore = filters
    },
    emptyCompareProductArray(state) {
      state.compareProducts = [];
    }
  },
  getters: {
    categories(state) {
      if (state.rawCategories === null) {
        return null;
      }

      return state.rawCategories?.items[0];
    },
  },
});
