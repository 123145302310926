import { render, staticRenderFns } from "./ResetPasswordThankyou.vue?vue&type=template&id=13bc4dfc&scoped=true"
import script from "./ResetPasswordThankyou.vue?vue&type=script&lang=ts"
export * from "./ResetPasswordThankyou.vue?vue&type=script&lang=ts"
import style0 from "./ResetPasswordThankyou.vue?vue&type=style&index=0&id=13bc4dfc&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13bc4dfc",
  null
  
)

export default component.exports