



















import { useUiState } from '~/composables';
import { defineComponent, ref, useRouter } from '@nuxtjs/composition-api';
export default defineComponent({
  name: 'LogoComponent',
  props: {
    symbol: {
      type: Boolean,
      required: false,
      default: () => true
    }
  },
  setup() {
    const router = useRouter()
    const {toggleCompareProductModal, toggleCompareProductDetailModal, isCompareProductDetailModalOpen, isCompareProductModalOpen} = useUiState();
    const logo = ref({
      symbol: '/icons/marwan.svg',
      src: '/global/almarwan_machinery.svg',
      alt: 'Al Marwan'
    })
    const check = () => {
      if (isCompareProductModalOpen.value) {
        toggleCompareProductModal()
      }
      if (isCompareProductDetailModalOpen.value && isCompareProductModalOpen.value) {
        toggleCompareProductDetailModal()
        toggleCompareProductModal()
      }
      //router.push({ path: "/" })
    }

    return {
      toggleCompareProductDetailModal,
      toggleCompareProductModal,
      isCompareProductDetailModalOpen,
      isCompareProductModalOpen,
      router,
      check,
      logo,
    }
  },
})
