
















import { computed, defineComponent, onMounted, ref, useContext, useRouter } from '@nuxtjs/composition-api';
import Icon from '~/almarwan/components/Icon.vue';
import { useUiState, useUser } from '~/composables';
import { useWishlistStore } from '~/modules/wishlist/store/wishlistStore';
import { eventBus } from '~/almarwan/helpers/EventBus';
export default defineComponent({
  components: { Icon },
  setup () {
    const { toggleLoginModal } = useUiState();
    const { isAuthenticated } = useUser();
    const router = useRouter();
    const { app } = useContext();
    const wishlistStore = useWishlistStore();
    const guestWishlist = ref([])
    const wishlistItemsQty = computed(() => wishlistStore.wishlist?.items_count ?? 0);
    const wishlistHasProducts = computed(() => wishlistItemsQty.value > 0);
    const handleWishlistClick = async () => {
      if (isAuthenticated.value) {
        await router.push(app.localeRoute({ name: 'customer-my-wishlist' }));
      } else {
        toggleLoginModal();
      }
    };
    const updateGuestWishlist = () => {
      if (localStorage.getItem("wishListForGuestUser")) {
        guestWishlist.value = JSON.parse(localStorage.getItem("wishListForGuestUser"))
      }
    }
    onMounted(() => {
      if (isAuthenticated.value) {
        updateGuestWishlist();
      }
      eventBus.$on('updateWishlistInAppHeader', () => {
        updateGuestWishlist();
      })
    })
    return {
      handleWishlistClick,
      isAuthenticated,
      wishlistItemsQty,
      wishlistHasProducts,
      guestWishlist
    }
  }
})
