import { render, staticRenderFns } from "./WishlistSidebar.vue?vue&type=template&id=349b1853&scoped=true"
import script from "./WishlistSidebar.vue?vue&type=script&lang=ts"
export * from "./WishlistSidebar.vue?vue&type=script&lang=ts"
import style0 from "./WishlistSidebar.vue?vue&type=style&index=0&id=349b1853&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "349b1853",
  null
  
)

export default component.exports