













import { defineComponent } from '@nuxtjs/composition-api';
import { useUiState } from '~/composables';
export default defineComponent({
  name: 'Cart',
  props: {
    cartTotalItems: {
      type: Number,
      default: 0
    },
    disableCart: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const { toggleCartSidebar } = useUiState();
    return {
      toggleCartSidebar,
    }
  }
})
