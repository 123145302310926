<template>
  <i :class="`icon icon--${this.name}`"/>
</template>

<script>
export default {
  name: "IconComponent",
  props: {
    name: {
      type: String,
      required: false,
      default:() => ''
    }
  }
}
</script>
