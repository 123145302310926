import {
  ref,
  readonly,
  computed,
  useContext,
} from '@nuxtjs/composition-api';
import type {Ref} from '@nuxtjs/composition-api';
import mask from '~/composables/utils/mask';
import {Logger} from '~/helpers/logger';
import {useCustomerStore} from '~/modules/customer/stores/customer';
import {useCart} from '~/modules/checkout/composables/useCart';
import {generateUserData} from '~/modules/customer/helpers/generateUserData';
import {useUiNotification} from '~/composables/useUiNotification';
import type {Customer} from '~/modules/GraphQL/types';
import {eventBus} from "@/almarwan/helpers/EventBus"
import { useUiState } from '~/composables';
import type {
  UseUserInterface,
  UseUserErrors,
  UseUserLoadParams,
  UseUserLoginParams,
  UseUserLogoutParams,
  UseUserRegisterParams,
  UseUserUpdateUserParams,
  UseUserChangePasswordParams,
} from './useUser';

/**
 * Allows loading and manipulating data of the current user.
 *
 * See the {@link UseUserInterface} for a list of methods and values available in this composable.
 */
export function useUser(): UseUserInterface {
  const customerStore = useCustomerStore();
  const {app} = useContext();
  const {setCart} = useCart();
  const {send: sendNotification} = useUiNotification();
  const {socialLogin, setSocialLogin} = useUiState();
  const loading: Ref<boolean> = ref(false);
  const errorsFactory = (): UseUserErrors => ({
    updateUser: null,
    updateUserEmail: null,
    register: null,
    login: null,
    logout: null,
    changePassword: null,
    load: null,
  });
  const error: Ref = ref(errorsFactory());

  const setUser = (newUser: Customer) => {
    customerStore.user = newUser;
    Logger.debug('useUserFactory.setUser', newUser);
  };

  const resetErrorValue = () => {
    error.value = errorsFactory();
  };

  const updateCustomerEmail = async (credentials: { email: string, password: string }): Promise<void> => {
    const {errors} = await app.context.$vsf.$magento.api.updateCustomerEmail(credentials);

    if (errors) {
      throw errors.map((e) => e.message).join(',');
    }
  };

  // eslint-disable-next-line consistent-return
  const updateUser = async ({user: providedUser, customQuery}: UseUserUpdateUserParams) => {
    Logger.debug('[Magento] Update user information', {providedUser, customQuery});
    resetErrorValue();

    try {
      loading.value = true;
      const {email, password, ...updateData} = providedUser;

      const userData = generateUserData(updateData);

      const {data, errors} = await app.context.$vsf.$magento.api.updateCustomer(userData, customQuery);
      Logger.debug('[Result]:', {data});

      if (errors) {
        const allErrorMessages = errors.map((e) => e.message).join(',');
        Logger.error(allErrorMessages);
        error.value.updateUser = allErrorMessages;
      }

      customerStore.user = data?.updateCustomerV2?.customer || {};
      error.value.updateUser = null;
    } catch (err) {
      error.value.updateUser = err;
      Logger.error('useUser/updateUser', err);
    } finally {
      loading.value = false;
    }
    return customerStore.user
  };

  const updateUserEmail = async ({user: providedUser, customQuery}: UseUserUpdateUserParams) => {
    Logger.debug('[Magento] Update user information', {providedUser, customQuery});
    resetErrorValue();

    try {
      loading.value = true;
      const {email: oldEmail} = customerStore.user;
      const {email, password, ...updateData} = providedUser;

      const userData = generateUserData(updateData);

      if (email && email !== oldEmail) {
        await updateCustomerEmail({
          email,
          password,
        });
      }

      const {data, errors} = await app.context.$vsf.$magento.api.updateCustomer(userData, customQuery);
      Logger.debug('[Result]:', {data});

      if (errors) {
        const allErrorMessages = errors.map((e) => e.message).join(',');
        Logger.error(allErrorMessages);
        error.value.updateUser = allErrorMessages;
      }

      customerStore.user = data?.updateCustomerV2?.customer || {};
      error.value.updateUser = null;
    } catch (err) {
      error.value.updateUser = err;
      Logger.error('useUser/updateUser', err);
    } finally {
      loading.value = false;
    }
  };

  const updateCustomerPicture = async (file_name, file_content): Promise<any> => {
    const {data, errors} = await app.context.$vsf.$magento.api.updateCustomerPicture({file_name: file_name, file_content: file_content});
    if (errors) {
      const joinedErrors = errors.map((e) => e.message).join(',');
      Logger.error(joinedErrors);
      return errors[0].message;
    } else {
      Logger.debug('[Result]:', {data});
      return data;
    }
  };

  const logout = async ({customQuery = {}}: UseUserLogoutParams = {}) => {
    Logger.debug('[Magento] useUserFactory.logout');
    resetErrorValue();
    setSocialLogin(false)
    try {
      const apiState = app.context.$vsf.$magento.config.state;

      await app.context.$vsf.$magento.api.revokeCustomerToken(customQuery);
      localStorage.removeItem('isAuth')
      apiState.removeCustomerToken();
      apiState.removeCartId();
      setCart(null);
      customerStore.setIsLoggedIn(false);
      customerStore.setFirstTimeUser(false);
      error.value.logout = null;
      customerStore.user = null;
    } catch (err) {
      error.value.logout = err;
      Logger.error('useUser/logout', err);
    }
  };

  const load = async ({customQuery = {}}: UseUserLoadParams = {}) => {
    Logger.debug('[Magento] useUser.load');
    resetErrorValue();

    try {
      loading.value = true;
      const apiState = app.context.$vsf.$magento.config.state;

      if (!apiState.getCustomerToken()) {
        return null;
      }
      try {
        const {data} = await app.context.$vsf.$magento.api.customer(customQuery);

        Logger.debug('[Result]:', {data});

        customerStore.user = data?.customer ?? {};
      } catch {
        // eslint-disable-next-line no-void
        // @ts-ignore
        await logout();
      }
      error.value.load = null;
    } catch (err) {
      error.value.load = err;
      Logger.error('useUser/load', err);
    } finally {
      loading.value = false;
    }

    return customerStore.user;
  };

  // eslint-disable-next-line @typescript-eslint/require-await,no-empty-pattern
  const login = async ({ user: providedUser, customQuery }: UseUserLoginParams) : Promise<void> => {
    Logger.debug('[Magento] useUser.login', providedUser);
    resetErrorValue();

    try {
      loading.value = true;
      const apiState = app.context.$vsf.$magento.config.state;

      const { data, errors } = await app.$vsf.$magento.api.generateCustomerToken(
        {
          email: providedUser.email,
          password: providedUser.password,
          recaptchaToken: providedUser.recaptchaToken,
        },
        customQuery || {},
      );
      Logger.debug('[Result]:', { data });

      if (errors) {
        const joinedErrors = errors.map((e) => e.message).join(',');
        Logger.error(joinedErrors);
        throw new Error(joinedErrors);
      }

      customerStore.setIsLoggedIn(true);
      const randomString = [...Array(10)].map(() => String.fromCharCode(97 + Math.floor(Math.random() * 26))).join('');
      localStorage.setItem('isAuth',randomString)
      apiState.setCustomerToken(data.generateCustomerToken.token);
      eventBus.$emit('updateUserInAppHeader')

      // merge existing cart with customer cart
      // todo: move this logic to separate method
      const currentCartId = apiState.getCartId();
      const cart = await app.context.$vsf.$magento.api.customerCart();
      const newCartId = cart.data.customerCart.id;

      try {
        if (newCartId && currentCartId && currentCartId !== newCartId) {
          const { data: dataMergeCart } = await app.context.$vsf.$magento.api.mergeCarts(
            {
              sourceCartId: currentCartId,
              destinationCartId: newCartId,
            },
          );

          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          setCart(dataMergeCart.mergeCarts);

          apiState.setCartId(dataMergeCart.mergeCarts.id);
        } else {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          setCart(cart.data.customerCart);
        }
      } catch {
        // Workaround for Magento 2.4.4 mergeCarts mutation error related with Bundle products
        // It can be removed when Magento 2.4.5 will be release
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        setCart(cart.data.customerCart);
      }
      error.value.login = null;
    } catch (err) {
      error.value.login = err;
      Logger.error('useUser/login', err);
    } finally {
      loading.value = false;
    }
  };
  // eslint-disable-next-line @typescript-eslint/require-await,no-empty-pattern
  const productAlertNotifyInStock = async (id): Promise<void> => {
    const user = await load()
    const {data, errors} = await app.context.$vsf.$magento.api.productAlertNotifyInStock({product_id: id, email: user.email});
    if (data['ProductAlertNotifyInStock']['message']) {
      sendNotification({
        id: Symbol('product_added_to_cart_from_wishlist'),
        message: data.ProductAlertNotifyInStock.message,
        type: 'success',
        icon: 'check',
        persist: false,
        title: 'Express Interest',
      });
    }
  };
  /*-----------below code blocks will be moved to seperate file soon --------------*/

  // eslint-disable-next-line @typescript-eslint/require-await,no-empty-pattern
  const generateSocialCustomerToken = async (type, token): Promise<any> => {
    const {data, errors} = await app.context.$vsf.$magento.api.generateSocialCustomerToken({type: type, token: token});
    if (errors) {
      const joinedErrors = errors.map((e) => e.message).join(',');
      Logger.error(joinedErrors);
      errors.forEach((joinedErrors, i) => sendNotification({
        icon: 'error',
        id: Symbol(`registration_error-${i}`),
        message: joinedErrors.message,
        persist: false,
        title: 'Registration error',
        type: 'danger',
      }));
      return errors[0].message;
    } else {
      Logger.debug('[Result]:', {data});
      return data;
    }
  };
  /*-----------below code blocks will be moved to seperate file soon --------------*/

  // eslint-disable-next-line @typescript-eslint/require-await,no-empty-pattern
  const createMakeOffer = async (sku, price): Promise<any> => {
    const {data, errors} = await app.context.$vsf.$magento.api.createMakeOffer({sku: sku, offer_price: price});
    if (errors) {
      eventBus.$emit('makeOfferFailed', errors[0].message)
      const joinedErrors = errors.map((e) => e.message).join(',');
      Logger.error(joinedErrors);
      const respArr = [{makeOffer: 'failed', stat: errors[0].message}]
      return respArr;
    } else {
      if (data.createMakeOffer.hasOwnProperty('make_offer') && data.createMakeOffer.make_offer.status == "Offer Placed") {
        Logger.debug('[Result]:', {data});
        //eventBus.$emit('makeOfferSuccess', 'success')
        const respArr = [{makeOffer: 'success', stat: data.createMakeOffer.make_offer.status, id: data.createMakeOffer.make_offer.id}]
        return respArr;
      }
    }
  };
  // eslint-disable-next-line @typescript-eslint/require-await,no-empty-pattern
  const updateMakeOffer = async (updateArr): Promise<any> => {
    const {data, errors} = await app.context.$vsf.$magento.api.updateMakeOffer({id: updateArr[0].id, offer_price: updateArr[0].price});
    if (errors) {
      eventBus.$emit('updateMakeOfferFailed', errors[0].message)
      const joinedErrors = errors.map((e) => e.message).join(',');
      Logger.error(joinedErrors);
      const respArr = [{updateMakeOffer: 'failed', stat: errors[0].message}]
      return respArr;
    } else {
      if (data.updateMakeOffer.hasOwnProperty('make_offer') && (data.updateMakeOffer.make_offer.status == "Offer Placed" || data.updateMakeOffer.make_offer.status == "Withdrawn")) {
        Logger.debug('[Result]:', {data});
        //eventBus.$emit('updateMakeOfferSuccess', 'success');
        const respArr = [{updateMakeOffer: 'success', stat: data.updateMakeOffer.make_offer.status}]
        return respArr;
      }
    }
    // return data;
  };
  // eslint-disable-next-line @typescript-eslint/require-await,no-empty-pattern
  const createInquiry = async (inquiryArr): Promise<void> => {
    const inquiryArray = inquiryArr.value[0]
    const {data, errors} = await app.context.$vsf.$magento.api.createInquiry({
      sku: inquiryArray.sku,
      cat_id: inquiryArray.cat_id,
      type: inquiryArray.type,
      comments: inquiryArray.comments
    });

    if (errors) {
      eventBus.$emit('createInquiryFailed', errors[0].message)
      const joinedErrors = errors.map((e) => e.message).join(',');
      Logger.error(joinedErrors);
    } else {
      if (data.createInquiry.hasOwnProperty('inquiry')) {
        Logger.debug('[Result]:', {data});
        eventBus.$emit('inquirySuccess', 'success')
      }
    }
    return data;
  };
//result = await createCustomerAddressCommand.execute(context, customerAddressInput, customQuery);
  const makeOfferWithOrder = async (): Promise<any> => {
    let makeOfferData = []
    try {
      loading.value = true;
      const {data, errors} = await app.context.$vsf.$magento.api.makeOffersAndOrders();
      if (data) {
        Logger.debug('[Result]:', {data});
      }
      if (errors) {
        const joinedErrors = errors.map((e) => e.message).join(',');
        Logger.error(joinedErrors);
      }
      makeOfferData = data
    } catch (err) {
      error.value = err;
      Logger.error('useUser/updateUser', err);
    } finally {
      loading.value = false;
    }
    return makeOfferData;
  };
  const makeOffers = async (pageNo: any, currPage: any): Promise<any> => {
    var pageSize = pageNo;
    var currentPage = currPage
    const makeOffer = await app.context.$vsf.$magento.api.makeOffers({pageSize, currentPage});
    if (makeOffer?.data) {
      Logger.debug('[Result]:', makeOffer.data);
    }
    if (makeOffer?.errors) {
      const joinedErrors = makeOffer?.errors.map((e) => e.message).join(',');
      Logger.error(joinedErrors);
    }
    return makeOffer?.data?.makeOffers;
  };

  const makeOffersPDF = async (id): Promise<any> => {
    const result = await app.context.$vsf.$magento.api.makeOffersPDF({id: id});
    if (result.data) {
      Logger.debug('[Result]:', result.data);
      return result.data?.makeOffers?.items;
    }
    if (result.errors) {
      const joinedErrors = result.errors.map((e) => e.message).join(',');
      Logger.error(joinedErrors);
      return result.errors;
    }
  };

  //result = await createCustomerAddressCommand.execute(context, customerAddressInput, customQuery);
  const inquiries = async (pageNo: any, currPage: any): Promise<void> => {
    let pageSize = pageNo;
    let currentPage = currPage
    const {data, errors} = await app.context.$vsf.$magento.api.inquiries({pageSize, currentPage});
    if (data) {
      Logger.debug('[Result]:', {data});
    }
    if (errors) {
      const joinedErrors = errors.map((e) => e.message).join(',');
      Logger.error(joinedErrors);
    }
    return data?.inquiries;
  };

  const financeRequest = async (form): Promise<any> => {
    const {data, errors} = await app.context.$vsf.$magento.api.financeRequest(form);
    if (errors) {
      eventBus.$emit('financeRequestFailed', errors[0].message)
      const joinedErrors = errors.map((e) => e.message).join(',');
      Logger.error(joinedErrors);
      return [{financeRequest: 'failed', stat: errors[0].message, result: null}];
    } else {
      if (data && data?.financeRequest.hasOwnProperty('request') && (data?.financeRequest.request.id)) {
        Logger.debug('[Result]:', {data});
        return [{financeRequest: 'success', stat: data.financeRequest.request.id, result: data.financeRequest.request.status}];
      }
    }
  };
  const financeRequests = async (params:any): Promise<any> => {
    // id = null
    let finParams = null
    if(params !==null) {
      const financeRequestsParams = params?.[0]
      finParams = {
        id: financeRequestsParams?.id,
        order_id: financeRequestsParams?.order_id ,
        status: financeRequestsParams?.status,
        sku: financeRequestsParams?.sku,
        pageSize: financeRequestsParams?.pageSize,
        currentPage: financeRequestsParams?.currentPage,
      }
    }
    else {
      finParams = {}
    }

    const {data, errors} = await app.context.$vsf.$magento.api.financeRequests(finParams);
    if (data) {
      Logger.debug('[Result]:', {data});
    }
    if (errors) {
      const joinedErrors = errors.map((e) => e.message).join(',');
      Logger.error(joinedErrors);
    }
    return data?.financeRequests?.items;
  };
  const financeApplication = async (id = null): Promise<any> => {
    const {data, errors} = await app.context.$vsf.$magento.api.financeApplication({id});
    if (data) {
      Logger.debug('[Result]:', {data});
    }
    if (errors) {
      const joinedErrors = errors.map((e) => e.message).join(',');
      Logger.error(joinedErrors);
    }
    return data?.financeRequests?.items;
  };
  const financeInvoice = async (id = null): Promise<any> => {
    const {data, errors} = await app.context.$vsf.$magento.api.financeInvoice({id});
    if (data) {
      Logger.debug('[Result]:', {data});
    }
    if (errors) {
      const joinedErrors = errors.map((e) => e.message).join(',');
      Logger.error(joinedErrors);
    }
    return data?.financeRequests?.items;
  };
  const recentViewedProducts = async (product_ids): Promise<any> => {
    const {data, errors} = await app.context.$vsf.$magento.api.recentViewedProducts({product_ids: product_ids});
    if (errors) {
      const joinedErrors = errors.map((e) => e.message).join(',');
      Logger.error(joinedErrors);
      return errors[0].message;
    } else {
      Logger.debug('[Result]:', {data});
      return data;
    }
  };

  const getRecentViewedProducts = async () : Promise<void> => {
    const viewedProducts = await app.context.$vsf.$magento.api.getRecentViewedProducts();
    if(viewedProducts && viewedProducts.data) {
      Logger.debug('[Result]:', viewedProducts.data);
      return viewedProducts.data;
    }
    if (viewedProducts && viewedProducts.errors) {
      const joinedErrors = viewedProducts.errors.map((e) => e.message).join(',');
      Logger.error(joinedErrors);
      return viewedProducts.errors;
    }
  };

  const maintenanceMaster = async (): Promise<void> => {
    const {data, errors} = await app.context.$vsf.$magento.api.maintenanceMaster();
    if (data) {
      Logger.debug('[Result]:', {data});
    }
    if (errors) {
      const joinedErrors = errors.map((e) => e.message).join(',');
      Logger.error(joinedErrors);
    }
    return data.maintenanceMaster;
  };

  const maintenanceRegulars = async (): Promise<any> => {
    const {data, errors} = await app.context.$vsf.$magento.api.maintenanceRegulars();
    if (data) {
      Logger.debug('[Result]:', {data});
    }
    if (errors) {
      const joinedErrors = errors.map((e) => e.message).join(',');
      Logger.error(joinedErrors);
    }
    return data;
  };

  const maintenanceRepairs = async (): Promise<any> => {
    const {data, errors} = await app.context.$vsf.$magento.api.maintenanceRepairs();
    if (data) {
      Logger.debug('[Result]:', {data});
    }
    if (errors) {
      const joinedErrors = errors.map((e) => e.message).join(',');
      Logger.error(joinedErrors);
    }
    return data;
  };

  const createMaintenanceRepair = async (form): Promise<any> => {
    const {data, errors} = await app.context.$vsf.$magento.api.createMaintenanceRepair({input: form});
    if (errors) {
      eventBus.$emit('createMaintenanceRepairFailed', errors[0].message)
      const joinedErrors = errors.map((e) => e.message).join(',');
      Logger.error(joinedErrors);
      const respArr = [{createMaintenanceRepair: 'failed', stat: errors[0].message}]
      return respArr;
    } else {
      if (data.createMaintenanceRepair.hasOwnProperty('maintenance') && (data.createMaintenanceRepair.maintenance.id)) {
        Logger.debug('[Result]:', {data});
        const respArr = [{createMaintenanceRepair: 'success', stat: data.createMaintenanceRepair.maintenance.id}]
        return respArr;
      }
    }
  };

  const createMaintenanceRegular = async (form): Promise<any> => {
    const {data, errors} = await app.context.$vsf.$magento.api.createMaintenanceRegular({input: form});
    if (errors) {
      eventBus.$emit('createMaintenanceRegularFailed', errors[0].message)
      const joinedErrors = errors.map((e) => e.message).join(',');
      Logger.error(joinedErrors);
      const respArr = [{createMaintenanceRegular: 'failed', stat: errors[0].message}]
      return respArr;
    } else {
      if (data.createMaintenanceRegular.hasOwnProperty('maintenance') && (data.createMaintenanceRegular.maintenance.id)) {
        Logger.debug('[Result]:', {data});
        const respArr = [{createMaintenanceRegular: 'success', stat: data.createMaintenanceRegular.maintenance.id}]
        return respArr;
      }
    }
  };

  const scheduleMaintenanceRegular = async (id, date): Promise<any> => {
    const {data, errors} = await app.context.$vsf.$magento.api.scheduleMaintenanceRegular({id: id, scheduled_at: date});
    if (errors) {
      eventBus.$emit('scheduleMaintenanceRegularFailed', errors[0].debugMessage)
      const joinedErrors = errors.map((e) => e.message).join(',');
      Logger.error(joinedErrors);
      return [{scheduleMaintenanceRegular: 'failed', stat: errors[0].debugMessage}]
    } else {
      if (data.scheduleMaintenanceRegular.hasOwnProperty('maintenance') && (data.scheduleMaintenanceRegular.maintenance.id)) {
        Logger.debug('[Result]:', {data});
        return [{scheduleMaintenanceRegular: 'success', stat: data.scheduleMaintenanceRegular.maintenance.id}]
      }
    }
  };

  const scheduleMaintenanceRepair = async (id, date): Promise<any> => {
    const {data, errors} = await app.context.$vsf.$magento.api.scheduleMaintenanceRepair({id: id, scheduled_at: date});
    if (errors) {
      eventBus.$emit('scheduleMaintenanceRepairFailed', errors[0].debugMessage)
      const joinedErrors = errors.map((e) => e.message).join(',');
      Logger.error(joinedErrors);
      const respArr = [{scheduleMaintenanceRepair: 'failed', stat: errors[0].debugMessage}]
      return respArr;
    } else {
      if (data.scheduleMaintenanceRepair.hasOwnProperty('maintenance') && (data.scheduleMaintenanceRepair.maintenance.id)) {
        Logger.debug('[Result]:', {data});
        const respArr = [{scheduleMaintenanceRepair: 'success', stat: data.scheduleMaintenanceRepair.maintenance.id}]
        return respArr;
      }
    }
  };

  const estimateShippingMethods = async (estArr): Promise<any> => {
    const {data, errors} = await app.context.$vsf.$magento.api.estimateShippingMethods({
      sku: estArr[0].sku,
      country_id: estArr[0].countryId,
      postalcode: estArr[0].cityId
    });
    if (data) {
      Logger.debug('[Result]:', {data});
    }
    if (errors) {
      const joinedErrors = errors.map((e) => e.message).join(',');
      Logger.error(joinedErrors);
    }
    return data;
  };

  const customerCards = async (): Promise<void> => {
    const {data, errors} = await app.context.$vsf.$magento.api.customerCards();
    if (data) {
      Logger.debug('[Result]:', {data});
    }
    if (errors) {
      const joinedErrors = errors.map((e) => e.message).join(',');
      Logger.error(joinedErrors);
    }
    return data.customer;
  };

  const customerPaymentId = async (): Promise<void> => {
    const {data, errors} = await app.context.$vsf.$magento.api.customerPaymentId();
    if (data) {
      Logger.debug('[Result]:', {data});
    }
    if (errors) {
      const joinedErrors = errors.map((e) => e.message).join(',');
      Logger.error(joinedErrors);
    }
    return data;
  };

  const customerSetDefaultCard = async (id): Promise<void> => {
    // @ts-ignore
    const {data, errors} = await app.context.$vsf.$magento.api.customerSetDefaultCard({id: id});
    if (data) {
      Logger.debug('[Result]:', {data});
    }
    if (errors) {
      const joinedErrors = errors.map((e) => e.message).join(',');
      Logger.error(joinedErrors);
    }
    return data;
  };

  const customerAddCard = async (id): Promise<void> => {
    // @ts-ignore
    const {data, errors} = await app.context.$vsf.$magento.api.customerAddCard({id: id});
    if (data) {
      Logger.debug('[Result]:', {data});
    }
    if (errors) {
      const joinedErrors = errors.map((e) => e.message).join(',');
      Logger.error(joinedErrors);
    }
    return data;
  };

  const customerDeleteCard = async (id): Promise<void> => {
    // @ts-ignore
    const {data, errors} = await app.context.$vsf.$magento.api.customerDeleteCard({id: id});
    if (data) {
      Logger.debug('[Result]:', {data});
    }
    if (errors) {
      const joinedErrors = errors.map((e) => e.message).join(',');
      Logger.error(joinedErrors);
    }
    return data;
  };

  const confirmCustomerAccount = async (confirmArr): Promise<any> => {
    // @ts-ignore
    const {data, errors} = await app.context.$vsf.$magento.api.confirmCustomerAccount({id: confirmArr.id, key: confirmArr.key});
    if (data) {
      Logger.debug('[Result]:', {data});
    }
    if (errors) {
      const joinedErrors = errors.map((e) => e.message).join(',');
      Logger.error(joinedErrors);
      return joinedErrors
    } else {
      return data;
    }
  };


  /*-----------------------end blocks----------------------------------------*/

  // eslint-disable-next-line consistent-return
  const register = async ({user: providedUser, customQuery}: UseUserRegisterParams): Promise<void> => {
    Logger.debug('[Magento] useUser.register', providedUser);
    resetErrorValue();

    try {
      loading.value = true;

      const {
        email,
        password,
        recaptchaToken,
        ...baseData
      } = generateUserData(providedUser);

      const {data, errors} = await app.$vsf.$magento.api.createCustomer(
        {
          email,
          password,
          recaptchaToken,
          ...baseData,
        },
        customQuery || {},
      );

      Logger.debug('[Result]:', {data});

      if (errors) {
        const joinedErrors = errors.map((e) => e.message).join(',');
        Logger.error(joinedErrors);
        errors.forEach((registerError, i) => sendNotification({
          icon: 'error',
          id: Symbol(`registration_error-${i}`),
          message: registerError.message,
          persist: false,
          title: 'Registration error',
          type: 'danger',
        }));
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        throw new Error(joinedErrors);
      }

      // if (recaptchaToken) { // todo: move recaptcha to separate module
      //   // generate a new token for the login action
      //   const { recaptchaInstance } = params;
      //   const newRecaptchaToken = await recaptchaInstance.getResponse();
      //
      //   return factoryParams.logIn(context, { username: email, password, recaptchaToken: newRecaptchaToken });
      // }
      error.value.register = null;
    } catch (err) {
      error.value.register = err;
      Logger.error('useUser/register', err);
    } finally {
      loading.value = false;
    }
  };

  // eslint-disable-next-line consistent-return
  const changePassword = async (params: UseUserChangePasswordParams) => {
    Logger.debug('[Magento] useUser.changePassword', {currentPassword: mask(params.current), newPassword: mask(params.new)});
    resetErrorValue();

    try {
      loading.value = true;

      const {data, errors} = await app.context.$vsf.$magento.api.changeCustomerPassword({
        currentUser: customerStore.user,
        currentPassword: params.current,
        newPassword: params.new,
      }, params.customQuery);

      let joinedErrors = null;

      if (errors) {
        joinedErrors = errors.map((e) => e.message).join(',');
        Logger.error(joinedErrors);
      }

      Logger.debug('[Result] ', {data});

      customerStore.user = data?.changeCustomerPassword;
      error.value.changePassword = joinedErrors;
    } catch (err) {
      error.value.changePassword = err;
      Logger.error('useUser/changePassword', err);
    } finally {
      loading.value = false;
    }
  };

  return {
    makeOfferWithOrder,
    generateSocialCustomerToken,
    productAlertNotifyInStock,
    createMakeOffer,
    updateMakeOffer,
    createInquiry,
    makeOffers,
    makeOffersPDF,
    getRecentViewedProducts,
    inquiries,
    financeRequest,
    financeRequests,
    financeApplication,
    financeInvoice,
    setUser,
    updateUser,
    updateUserEmail,
    updateCustomerPicture,
    register,
    login,
    logout,
    changePassword,
    load,
    recentViewedProducts,
    maintenanceMaster,
    maintenanceRegulars,
    maintenanceRepairs,
    createMaintenanceRepair,
    createMaintenanceRegular,
    scheduleMaintenanceRepair,
    scheduleMaintenanceRegular,
    estimateShippingMethods,
    customerCards,
    customerPaymentId,
    customerSetDefaultCard,
    customerAddCard,
    customerDeleteCard,
    confirmCustomerAccount,
    loading: readonly(loading),
    error: readonly(error),
    user: computed(() => customerStore.user),
    isAuthenticated: computed(() => customerStore.isLoggedIn),
  };
}

export default useUser;
export * from './useUser';
