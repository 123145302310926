import { computed, reactive } from '@nuxtjs/composition-api';
import { StateInterface, UseUiStateInterface } from '~/composables/useUiState/useUiState';

const state = reactive<StateInterface>({
  isCartSidebarOpen: false,
  isWishlistSidebarOpen: false,
  isLoginModalOpen: false,
  isCookieModalOpen: true,
  isMailingListModalOpen: false,
  isNewsletterModalOpen: false,
  isCategoryGridView: false,
  isWishlistGridView: true,
  isFilterSidebarOpen: false,
  isMobileMenuOpen: false,
  isMakeOfferModalOpen: false,
  isCompareProductModalOpen: false,
  isCompareProductDetailModalOpen: false,
  expressInterestActive: false,
  makeOfferActive: false,
  isOfferOpen: true,
  makeOfferSubmited: false,
  prodNameValue: '',
  prodSku: '',
  prodId: 0,
  expressInterestSent: false,
  prodInfo: [],
  offerAmount: '',
  withdrawOfferActive: false,
  offerInfo: [],
  isReserveProd: false,
  maintenanceActive: false,
  maintenanceType: 'repair',
  downloadSpecActive: false,
  checkedValues: [],
  productInCart: false,
  isSimilarItemsModalOpen: false,
  skuForSimilarItem: '',
  authTab: 'signIn',
  socialLogin: false,
  userNotified: false,
  searchBarTerm: '',
  showSignByCooki: false,
  disappearCompare: false,
  notifyMeActive: false,
  notifyMeSubmitted: false,
  quotesTab: 'order',
  styleCompare: false,
  trackAnalytics: {
    accepted: false,
    marketing: false,
    analytics: false
  },
  oneTapLogin: false,
  emptySearchResult: false,
});

/**
 * Global store for managing UI state.
 *
 * See the {@link UseUiStateInterface} for a list of methods and values available in this composable.
 */
export function useUiState(): UseUiStateInterface {
  const toggleMobileMenu = () => {
    state.isMobileMenuOpen = !state.isMobileMenuOpen;
  };

  const toggleCartSidebar = () => {
    if (state.isMobileMenuOpen) toggleMobileMenu();
    state.isCartSidebarOpen = !state.isCartSidebarOpen;
  };

  const toggleWishlistSidebar = () => {
    if (state.isMobileMenuOpen) toggleMobileMenu();
    state.isWishlistSidebarOpen = !state.isWishlistSidebarOpen;
  };

  const toggleLoginModal = () => {
    if (state.isMobileMenuOpen) toggleMobileMenu();
    state.isLoginModalOpen = !state.isLoginModalOpen;
  };

  const toggleCookieModal = () => {
    if (state.isMobileMenuOpen) toggleMobileMenu();
    state.isCookieModalOpen = !state.isCookieModalOpen;
  };

  const toggleMailingListModal = () => {
    if (state.isMobileMenuOpen) toggleMobileMenu();
    state.isMailingListModalOpen = !state.isMailingListModalOpen;
  };

  const toggleNewsletterModal = () => {
    state.isNewsletterModalOpen = !state.isNewsletterModalOpen;
  };

  const changeToCategoryGridView = () => {
    state.isCategoryGridView = true;
  };
  const changeToCategoryListView = () => {
    state.isCategoryGridView = false;
  };

  const changeToWishlistGridView = () => {
    state.isWishlistGridView = true;
  };

  const changeToWishlistListView = () => {
    state.isWishlistGridView = false;
  };
  const toggleFilterSidebar = () => {
    state.isFilterSidebarOpen = !state.isFilterSidebarOpen;
  };
  const toggleMakeOfferModal = () => {
    state.isMakeOfferModalOpen = !state.isMakeOfferModalOpen;
  };
  const toggleCompareProductModal = () => {
    state.isCompareProductModalOpen = !state.isCompareProductModalOpen;
  };
  const toggleCompareProductDetailModal = () => {
    state.isCompareProductDetailModalOpen = !state.isCompareProductDetailModalOpen;
  };
  const setExpressInterest = () => {
    state.expressInterestActive = !state.expressInterestActive;
  };
  const setMakeOffer = () => {
    state.makeOfferActive = !state.makeOfferActive;
  };
  const setOfferOpen = (val) => {
    state.isOfferOpen = val;
  };
  const setMakeOfferSubmited = (val) => {
    state.makeOfferSubmited = val;
  };
  const setInterestSent = () => {
    state.expressInterestSent = !state.expressInterestSent;
  };
  const setProdName = (name) => {
    state.prodNameValue = name;
  }
  const setProdSku = (sku) => {
    state.prodSku = sku;
  }
  const setProdId = (id) => {
    state.prodId = id;
  }
  const setProdInfo = (info) => {
    state.prodInfo = info;
  }
  const setOfferAmount = (amt) => {
    state.offerAmount = amt;
  }
  const setWithdrawOffer = (val) => {
    state.withdrawOfferActive = val;
  }
  const setOfferInfo = (info) => {
    state.offerInfo = info;
  }
  const setReserveProd = (val) => {
    state.isReserveProd = val;
  }
  const setMaintenanceActive = (val) => {
    state.maintenanceActive = val;
  }
  const setMaintenanceType = (val) => {
    state.maintenanceType = val;
  }
  const setDownloadSpecActive = (val) => {
    state.downloadSpecActive = val;
  }
  const setCheckedValue = (val) => {
    state.checkedValues=val;
  }
  const setProductInCart = (val) => {
    state.productInCart = val
  }
  const toggleSimilarItemsModal = () => {
    state.isSimilarItemsModalOpen = !state.isSimilarItemsModalOpen;
  };
  const setSkuForSimilarItem = (val) => {
    state.skuForSimilarItem = val;
  }
  const setAuthTab = (val) => {
    state.authTab = val;
  }
  const setSocialLogin = (val) => {
    state.socialLogin = val;
  }
  const setUserNotified = (val) => {
    state.userNotified = val
  }
  const setsearchBarTerm = (val) => {
    state.searchBarTerm = val
  }
  const setShowSignByCooki = () => {
    state.showSignByCooki = !state.showSignByCooki
  }
  const setDisappearCompare = () => {
   state.disappearCompare = !state.disappearCompare
  }
  const setNotifyMe = () => {
    state.notifyMeActive = !state.notifyMeActive;
  };
  const setNotifyMeSubmitted = (val) => {
    state.notifyMeSubmitted = val;
  };
  const setQuotesTab = (val) => {
    state.quotesTab = val;
  };
  const setStyleCompare = () => {
    state.styleCompare = !state.styleCompare
  };
  const setTrackAnalytics = (info) => {
    state.trackAnalytics = info;
  }
  const setOneTapLogin = (val) => {
    state.oneTapLogin = val;
  }
  const setEmptySearch = (val) => {
    state.emptySearchResult = val;
  }
  return {
    isMobileMenuOpen: computed(() => state.isMobileMenuOpen),
    isCartSidebarOpen: computed(() => state.isCartSidebarOpen),
    isWishlistSidebarOpen: computed(() => state.isWishlistSidebarOpen),
    isLoginModalOpen: computed(() => state.isLoginModalOpen),
    isCookieModalOpen: computed(() => state.isCookieModalOpen),
    isMakeOfferModalOpen: computed(() => state.isMakeOfferModalOpen),
    isCompareProductModalOpen: computed(() => state.isCompareProductModalOpen),
    isCompareProductDetailModalOpen: computed(() => state.isCompareProductDetailModalOpen),
    isMailingListModalOpen: computed(() => state.isMailingListModalOpen),
    isNewsletterModalOpen: computed(() => state.isNewsletterModalOpen),
    isCategoryGridView: computed(() => state.isCategoryGridView),
    isWishlistGridView: computed(() => state.isWishlistGridView),
    isFilterSidebarOpen: computed(() => state.isFilterSidebarOpen),
    expressInterestActive: computed(() => state.expressInterestActive),
    makeOfferActive: computed(() => state.makeOfferActive),
    isOfferOpen: computed(() => state.isOfferOpen),
    makeOfferSubmited: computed(() => state.makeOfferSubmited),
    prodNameValue: computed(() => state.prodNameValue),
    prodSku: computed(() => state.prodSku),
    prodId: computed(() => state.prodId),
    expressInterestSent: computed(() => state.expressInterestSent),
    prodInfo: computed(() => state.prodInfo),
    offerAmount: computed(() => state.offerAmount),
    withdrawOfferActive: computed(() => state.withdrawOfferActive),
    offerInfo: computed(() => state.offerInfo),
    isReserveProd: computed(() => state.isReserveProd),
    maintenanceActive: computed(() => state.maintenanceActive),
    maintenanceType: computed(() => state.maintenanceType),
    downloadSpecActive: computed(() => state.downloadSpecActive),
    checkedValues: computed(() => state.checkedValues),
    productInCart: computed(() => state.productInCart),
    isSimilarItemsModalOpen: computed(() => state.isSimilarItemsModalOpen),
    skuForSimilarItem: computed(() => state.skuForSimilarItem),
    authTab: computed(() => state.authTab),
    socialLogin: computed(() => state.socialLogin),
    userNotified: computed(() => state.userNotified),
    searchBarTerm: computed(() => state.searchBarTerm),
    showSignByCooki:computed(() => state.showSignByCooki),
    disappearCompare:computed(() => state.disappearCompare),
    notifyMeActive: computed(() => state.notifyMeActive),
    notifyMeSubmitted: computed(() => state.notifyMeSubmitted),
    quotesTab: computed(() => state.quotesTab),
    styleCompare:computed(() => state.styleCompare),
    trackAnalytics:computed(() => state.trackAnalytics),
    oneTapLogin: computed(() => state.oneTapLogin),
    emptySearchResult: computed(() => state.emptySearchResult),
    toggleMobileMenu,
    toggleCartSidebar,
    toggleWishlistSidebar,
    toggleLoginModal,
    toggleCookieModal,
    toggleMailingListModal,
    toggleNewsletterModal,
    changeToCategoryGridView,
    changeToCategoryListView,
    changeToWishlistGridView,
    changeToWishlistListView,
    toggleFilterSidebar,
    toggleMakeOfferModal,
    toggleCompareProductModal,
    toggleCompareProductDetailModal,
    setExpressInterest,
    setMakeOffer,
    setOfferOpen,
    setMakeOfferSubmited,
    setProdName,
    setProdSku,
    setProdId,
    setInterestSent,
    setProdInfo,
    setOfferAmount,
    setWithdrawOffer,
    setOfferInfo,
    setReserveProd,
    setMaintenanceActive,
    setMaintenanceType,
    setDownloadSpecActive,
    setCheckedValue,
    setProductInCart,
    toggleSimilarItemsModal,
    setSkuForSimilarItem,
    setAuthTab,
    setSocialLogin,
    setUserNotified,
    setsearchBarTerm,
    setShowSignByCooki,
    setDisappearCompare,
    setNotifyMeSubmitted,
    setNotifyMe,
    setQuotesTab,
    setStyleCompare,
    setTrackAnalytics,
    setOneTapLogin,
    setEmptySearch
  };
}

export default useUiState;
export * from './useUiState';
