


























































































































































import {defineComponent, watch, ref, useRouter, useContext, nextTick, computed, onMounted, toRef} from "@nuxtjs/composition-api";
import {eventBus} from "~/almarwan/helpers/EventBus";
import {useUiNotification, useUiState, useUser, useMagentoConfiguration} from "~/composables";
import useWishlist from '~/modules/wishlist/composables/useWishlist';
import {useCategoryStore} from "~/modules/catalog/category/stores/category";
import {useAddToCart} from "~/helpers/cart/addToCart";
import { Product } from '~/modules/catalog/product/types';
import { useCart } from '~/modules/checkout/composables/useCart';
import ProductTag from "~/modules/catalog/category/components/views/ProductTag.vue";
import useUiHelpers from "../../../../../composables/useUiHelpers";
import { clickOutside } from '~/components/directives/click-outside/click-outside-directive';
import { getPrice } from '~/modules/catalog/product/getters/productGetters';

export default defineComponent({
  name: 'CompareProductDetailModel',
  directives: { clickOutside },
  components: {ProductTag},
  data() {
    return {
      brands: [
        {
          value: 629,
          name: 'Kobelco'
        },{
          value: 642,
          name: 'Dynapac'
        },{
          value: 645,
          name: 'Sakai'
        },{
          value: 646,
          name: 'Cat'
        },{
          value: 647,
          name: 'Komatsu'
        },{
          value: 648,
          name: 'JCB'
        },{
          value: 649,
          name: 'Hitachi'
        },{
          value: 650,
          name: 'Sany'
        },{
          value: 651,
          name: 'Vögele'
        },{
          value: 652,
          name: 'Bomag'
        },{
          value: 653,
          name: 'Ditch Witch'
        },{
          value: 654,
          name: 'Liebherr'
        },{
          value: 655,
          name: 'Wirtgen'
        },{
          value: 656,
          name: 'Yuchai'
        },{
          value: 657,
          name: 'Interoc'
        },{
          value: 658,
          name: 'Volvo'
        },{
          value: 659,
          name: 'Klemm'
        },{
          value: 660,
          name: 'McCloskey'
        },{
          value: 661,
          name: 'Manitou'
        },{
          value: 662,
          name: 'Sandvik'
        },{
          value: 663,
          name: 'Ammann'
        },{
          value: 665,
          name: 'Beta'
        },{
          value: 666,
          name: 'Craft'
        },{
          value: 687,
          name: 'Hyster'
        },{
          value: 688,
          name: 'DAF'
        },{
          value: 689,
          name: 'Chevrolet'
        },{
          value: 691,
          name: 'Cifa'
        },{
          value: 692,
          name: 'Piller'
        },{
          value: 699,
          name: 'Simex'
        },{
          value: 685,
          name: 'World Attachments'
        },{
          value: 684,
          name: 'Jisung'
        },{
          value: 683,
          name: 'Toku'
        }
      ],
      selectedProd: {}
    }
  },
  watch: {
    offerAmt (newValue) {
      const result = newValue.replace(/\D/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      nextTick(() => this.offerAmt = result);
    },
    offerAmt1 (newValue) {
      const result = newValue.replace(/\D/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      nextTick(() => this.offerAmt1 = result);
    },
    offerAmt2 (newValue) {
      const result = newValue.replace(/\D/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      nextTick(() => this.offerAmt2 = result);
    },
    isAuthenticated () {
      if(this.isAuthenticated) {
        if(this.buyNowClicked){
          this.addItem(this.selectedProd);
        }
      }
    }
  },
  methods: {
    addItem(prod) {
      this.addItemToCart({ product: prod, quantity: 1 })
      setTimeout(() => {
        this.closeCompareProducts()
      }, 300)
    },
    getBrandName(brandId) {
      const brandFound = this.brands.find((brand)=> brand.value == brandId)
      return brandFound !== undefined ? brandFound.name : ' - '
    },
    makeOffer(prod) {
      if (this.isAuthenticated && ! this.buyPrice(prod)){
        this.makeNewOffer(prod);
      }else{
        let pArr =[
          {
            'name': prod.name,
            'sku': prod.sku,
          }
        ];
        if(this.offerAmt) {
          this.setOfferAmount(this.offerAmt);
        }
        if(!this.isAuthenticated && !this.offerAmt) {
            this.setOfferAmount('');
        }
        if(prod.reserve_fee > 0) {
          this.setReserveProd(true);
          this.offeredProditem = prod
          localStorage.setItem('compareOfferProd', JSON.stringify(this.offeredProditem))
        }
        this.setProdInfo(pArr);
        this.closeCompareProducts()
        setTimeout(()=>{
          this.toggleMakeOfferModal();
          this.setMakeOffer();
        }, 300)
      }

    },
    closeCompareProducts () {
      this.toggleCompareProductDetailModal()
      eventBus.$emit('closeCompareProduct')
      document.body.style.overflowY = 'visible'
    },
    fixedScroll() {
      document.body.style.overflowY = 'visible'
    },
    canMakeOffer(prod) {
      if(prod.hasOwnProperty('can_makeoffer') && prod.can_makeoffer) {
        return true
      }
      else {
        return false
      }
    },
    postInterest(prod, type) {
      let prodCatObj = prod.categories.find(o => o.level == 2);
      let pArr =[
        {
          'id': prodCatObj.id,
          'name': prod.name,
          'sku': prod.sku,
          'type': type
        }
      ];
      this.setProdInfo(pArr);
      setTimeout(()=>{
        this.closeCompareProducts()
        this.toggleMakeOfferModal();
        this.setExpressInterest();
      },300)
    },
    canInquiry(prod) {
      if(prod.hasOwnProperty('can_inquiry') && prod.can_inquiry) {
        return true
      } else {
        return false
      }
    },
    displayPropertyValue(value, property) {
        switch (property) {
          case 'hours':
            return value === null || value > 15000 ? 'Upon request' : this.numberWithCommas(value) + ' hrs';
          case 'machine_weight':
            return value === null || value === '' ? ' - ' : this.numberWithCommas(value);
          case 'year':
            return value === null || value === '' ? ' - ' : value;
          case 'filter_milage':
            return value > 250000 ? 'Upon request' : this.numberWithCommas(value);
          case 'brand':
            return value === null || value === '' ? ' - ' : this.getBrandName(value);
          case 'country_of_origin':
            return value === null || value === '' ? ' - ' : value;
          default:
            return value === null || value === '' ? ' - ' : value;
        }
      },
    getPropertyTitle(property) {
      switch (property) {
        case 'hours':
          return 'Working Hours';
        case 'machine_weight':
          return 'Operating weight (kg)';
        case 'year':
          return 'Year Of Manufacture';
        case 'filter_milage':
          return 'Mileage (km)';
        case 'brand':
          return 'Brand';
        case 'country_of_origin':
          return 'Country Of Manufacture';
        case 'ex_bucket_cap':
          return 'Bucket Capacity';
        case 'engine_tier_us_eu':
          return 'Emissions';
        case 'ce_certification':
          return 'CE';
        case 'epa':
          return 'EPA';
        case 'engine_make':
          return 'Engine Make';
        case 'engine_model':
          return 'Engine Model';
       case 'fuel_cap':
          return 'Fuel Cap';
        case 'location':
          return 'Location';

        default:
          return property;
      }
    },

  },
  setup (_, { emit }) {
    const { toggleCompareProductDetailModal, isCompareProductDetailModalOpen,  toggleLoginModal, toggleCompareProductModal, expressInterestActive, makeOfferActive, setMakeOffer, setExpressInterest, setProdName, setProdSku, setProdId, setProdInfo, setMakeOfferSubmited, setOfferAmount, toggleMakeOfferModal, setReserveProd, disappearCompare, setDisappearCompare} = useUiState();
    const { compareProducts, $state } = useCategoryStore();
    const { send: sendNotification } = useUiNotification();
    const { numberWithCommas } = useUiHelpers()
    const { addItemToCart } = useAddToCart();
    const { cart } = useCart();
    const { addOrRemoveItem } = useWishlist();
    const { app } = useContext();
    const { isAuthenticated, productAlertNotifyInStock, createMakeOffer } = useUser();
    const { selectedCurrency } = useMagentoConfiguration();
    const router = useRouter();
    const expressInterestLoading = ref(false);
    const isInGuestWishlist = ref(false);
    const wishListForGuestUser = ref([]);
    const wishlistCompProd = ref([]);
    const notifyLoading = ref(false);
    const notifyLoading1 = ref(false);
    const notifyLoading2 = ref(false);
    const loading = ref(false);
    const loading1 = ref(false);
    const loading2 = ref(false);
    const rentORbuy = ref('');
    const offerAmt = ref('');
    const offerAmt1 = ref('');
    const offerAmt2 = ref('');
    const offeredProditem = ref<Product>();
    let labelClass = ref('label--green');
    let isHighlightActive = ref(false);
    let buyNowClicked = ref(false);
    let compareData = ref({
      'workingHours': false,
      'operatingWeight': false,
      'price': false,
      'yearOfManufacture': false,
      'mileage': false,
      'brands': false,
    });
    let tagLabelComp = 0;
    const availableForFunc = (product) => product['listings'];
    const getTagLabelComp = (prod) => {
      tagLabelComp++;
      let x =0;
      if (tagLabelComp < 200) {
        if(prod.hasOwnProperty('available_for_tags') && prod.available_for_tags.length) {
          let array = ['','label--purple','label--red','label--purple','label--red','label--green','label--green'];
          switch (prod['listings']){
            case '763,764':
              if(rentORbuy.value === 'buy') {
                return prod?.available_for_tags[0]?.label;
              }else{
                return prod?.available_for_tags[1]?.label;
              }
            case '763':
              return prod?.available_for_tags[0]?.label;
            case '764':
              return prod?.available_for_tags[0]?.label;
            default:
              return '';
          }
        } else {
          return '';
        }
      }
    };
    const wishlistCheck = (product) => wishListForGuestUser.value.find((prod) => prod.id === product.id)
    const saveProduct = (product) => {
      if(!isAuthenticated.value) {
        let storedNames = []
        if (localStorage.getItem("wishListForGuestUser")) {
          storedNames = JSON.parse(localStorage.getItem("wishListForGuestUser"));
        }
        let checkIfItIsNotSameProduct = storedNames.find((item) => item.id === product.id)
        if(!checkIfItIsNotSameProduct) {
          storedNames.push(product)
          wishListForGuestUser.value = storedNames
          productInGuestWishlist(product)
          localStorage.setItem("wishListForGuestUser", JSON.stringify(storedNames));
          eventBus.$emit('updateWishlistInAppHeader')
          wishlistCompProd.value.push(product.id);
        }
        return
      }
      addOrRemoveItem({product});
      // @ts-ignore: Unreachable code error
    };
    const productAlertNotifyInStockCTA = async (product) => {
      if (isAuthenticated.value) {
        notifyLoading.value = true;
        await productAlertNotifyInStock(product.id);
        notifyLoading.value = false;
        setTimeout(()=>{
          toggleCompareProductDetailModal()
          eventBus.$emit('closeCompareProduct')
        }, 300)
      } else {
        toggleCompareProductDetailModal()
        setTimeout(()=>{
          eventBus.$emit('closeCompareProduct')
          toggleLoginModal();
        }, 300)

      }
    };
    const productAlertNotifyInStockCTA1 = async (product) => {
      if (isAuthenticated.value) {
        notifyLoading1.value = true;
        await productAlertNotifyInStock(product.id);
        notifyLoading1.value = false;
      } else {
        toggleCompareProductDetailModal()
        setTimeout(()=>{
          eventBus.$emit('closeCompareProduct')
          toggleLoginModal();
        }, 300)

      }
    };
    const productAlertNotifyInStockCTA2 = async (product) => {
      if (isAuthenticated.value) {
        notifyLoading2.value = true;
        await productAlertNotifyInStock(product.id);
        notifyLoading2.value = false;
      } else {
        toggleCompareProductDetailModal()
        setTimeout(()=>{
          eventBus.$emit('closeCompareProduct')
          toggleLoginModal();
        }, 300)

      }
    };
    const makeNewOffer = async (prod) => {
      loading.value = true;
      if (isAuthenticated.value) {
        offerAmt.value = offerAmt?.value.replace(/\,/g,'');
        if(parseInt(offerAmt.value) > 0){
          const offerNew = await createMakeOffer(prod.sku, offerAmt.value);
          setMakeOfferSubmited(false)
          loading.value = false;
          if(offerNew[0]['makeOffer'] == 'success' && offerNew[0]['id']) {
            toggleCompareProductDetailModal()
            toggleCompareProductModal()
            $state.compareProducts = []
            await addItemToCart({ product: prod, quantity: 1, custom_option: { reserve_makeoffer: offerNew[0]['id'] } });
          } else {
            toggleCompareProductDetailModal()
            toggleCompareProductModal()
          }
        }
        else {
          sendNotification({
            id: Symbol('product_removed'),
            message: `Please enter a valid amount.`,
            type: 'danger',
            icon: 'error',
            persist: true,
            title: 'Invalid amount!',
          });
          loading.value = false;
        }
      } else {
        toggleCompareProductDetailModal()
        toggleMakeOfferModal();
        setMakeOffer()
      }
    };
    const makeNewOffer1 = async (prod) => {
      loading1.value = true;
      if (isAuthenticated.value) {
        offerAmt1.value = offerAmt1?.value.replace(/\,/g,'');
        if(parseInt(offerAmt1.value) > 0){
          const offerNew = await createMakeOffer(prod.sku, offerAmt1.value);
          setMakeOfferSubmited(false)
          loading1.value = false;
          if(offerNew[0]['makeOffer'] == 'success') {
            toggleCompareProductDetailModal()
            toggleCompareProductModal()
            $state.compareProducts = []
            await addItemToCart({ product: prod, quantity: 1, custom_option: { reserve_makeoffer: offerNew[0]['id'] } });
          }
        } else {
          sendNotification({
            id: Symbol('product_removed'),
            message: `Please enter a valid amount.`,
            type: 'danger',
            icon: 'error',
            persist: true,
            title: 'Invalid amount!',
          });
          loading1.value = false;
        }
      } else {
        toggleCompareProductDetailModal()
        toggleMakeOfferModal();
        setMakeOffer()
      }
    };
    const makeNewOffer2 = async (prod) => {
      loading2.value = true;
      if (isAuthenticated.value) {
        offerAmt2.value = offerAmt2?.value.replace(/\,/g,'');
        if(parseInt(offerAmt2.value) > 0){
          const offerNew = await createMakeOffer(prod.sku, offerAmt2.value);
          setMakeOfferSubmited(false)
          loading2.value = false;
          if(offerNew[0]['makeOffer'] == 'success') {
            toggleCompareProductDetailModal()
            toggleCompareProductModal()
            $state.compareProducts = []
            await addItemToCart({ product: prod, quantity: 1, custom_option: { reserve_makeoffer: offerNew[0]['id'] } });
          }
        }
        else {
          sendNotification({
            id: Symbol('product_removed'),
            message: `Please enter a valid amount.`,
            type: 'danger',
            icon: 'error',
            persist: true,
            title: 'Invalid amount!',
          });
          loading2.value = false;
        }
      } else {
        toggleCompareProductDetailModal()
        toggleMakeOfferModal();
        setMakeOffer()
      }
    };

    const removeProductFromGuestWishlist = (prod) => {
      let storedNames = []
      if (localStorage.getItem("wishListForGuestUser")) {
        storedNames = JSON.parse(localStorage.getItem("wishListForGuestUser"));
      }
      wishListForGuestUser.value =  wishListForGuestUser.value.filter((product) => product.id != prod.id)
      storedNames = storedNames.filter((product) => product.id != prod.id)
      localStorage.setItem("wishListForGuestUser", JSON.stringify(storedNames));
      productInGuestWishlist(prod)
      wishlistCompProd.value = wishlistCompProd.value.filter(item => item !== prod.id);
      eventBus.$emit('updateWishlistInAppHeader')
    }

    const productCurrency = (product) =>{
      return product?.price_range?.minimum_price?.regular_price?.currency
    }
    const productPrice = (product) => {
      return product?.price_range?.minimum_price?.regular_price?.value
    }
    const getProductLabel = (product) => {
      if(!product.condition) {
        return null
      }
      if(product.condition === '621') {
        return 'Used'
      } else if(product.condition === '620') {
        return 'Like New'
      } else if(product.condition === '619') {
        return 'New'
      }
    }
    const buyPrice = (product) => {
      return !!product?.price_range?.minimum_price?.regular_price?.value
    }
    const highlightDifferences = () => {
      if (compareProducts && compareProducts.length > 1) {
        const attributesToCompare = [
          'hours',
          'machine_weight',
          'price_range.minimum_price.regular_price.value',
          'year',
          'filter_milage',
          'brand',
          'country_of_origin',
          'ex_bucket_cap',
          'engine_tier_us_eu',
          'ce_certification',
          'epa',
          'engine_make',
          'engine_model',
          'fuel_cap',
          'location'
        ];
        attributesToCompare.forEach(attribute => {
          for (let i = 1; i < compareProducts.length; i++) {
            if (compareProducts[0][attribute] !== compareProducts[i][attribute]) {
              compareData.value[attribute] = true;
              break;
            }
          }
        });
      }
      isHighlightActive.value = !isHighlightActive.value
      if (!isHighlightActive.value) {
        compareData.value = {
          'workingHours': false,
          'operatingWeight': false,
          'price': false,
          'yearOfManufacture': false,
          'mileage': false,
          'brands': false,
        }
      }
    }

    const productInGuestWishlist = (product) => {
      let productInGuestList = wishListForGuestUser.value.find((prod) => prod.id === product.id)
      isInGuestWishlist.value = !!productInGuestList;
    }

    const wishlistCompProdId = () => {
      if (compareProducts?.length > 0 && localStorage.getItem("wishListForGuestUser")) {
        for(let index = 0; index < 3; index++){
          if (wishListForGuestUser.value.find((prod) => prod.id === compareProducts[index]?.id)) {
            wishlistCompProd.value[index] = compareProducts[index]?.id;
          }
        }
      }
    }

    const alreadyAdded = (sku) => {
      let find = cart?.value?.items?.filter((item)=> item.product.sku === sku)
      return !!find?.length;
    }

    onMounted(()=>{
      rentORbuy.value = localStorage.getItem('rentOrBuy')
      if (!isAuthenticated.value) {
        if (localStorage.getItem("wishListForGuestUser")) {
          wishListForGuestUser.value = JSON.parse(localStorage.getItem("wishListForGuestUser"));
          wishlistCompProdId();
        }
      }
    })
    return {
      wishlistCheck,
      buyNowClicked,
      highlightDifferences,
      compareData,
      isHighlightActive,
      rentORbuy,
      saveProduct,
      removeProductFromGuestWishlist,
      isInGuestWishlist,
      productInGuestWishlist,
      wishlistCompProd,
      wishListForGuestUser,
      addItemToCart,
      numberWithCommas,
      buyPrice,
      getProductLabel,
      productCurrency,
      productPrice,
      getTagLabelComp,
      labelClass,
      availableForFunc,
      setProdName,
      setProdSku,
      setProdId,
      expressInterestActive,
      makeOfferActive,
      notifyLoading,
      notifyLoading1,
      notifyLoading2,
      productAlertNotifyInStockCTA,
      productAlertNotifyInStockCTA1,
      productAlertNotifyInStockCTA2,
      expressInterestLoading,
      setOfferAmount,
      offerAmt,
      offerAmt1,
      offerAmt2,
      setExpressInterest,
      toggleMakeOfferModal,
      setProdInfo,
      isAuthenticated,
      loading,
      loading1,
      loading2,
      makeNewOffer,
      makeNewOffer1,
      makeNewOffer2,
      toggleCompareProductModal,
      toggleLoginModal,
      compareProducts,
      toggleCompareProductDetailModal,
      isCompareProductDetailModalOpen,
      setMakeOffer,
      addItemToWishlist: addOrRemoveItem,
      setReserveProd,
      offeredProditem,
      alreadyAdded,
      app,
      setDisappearCompare,
      disappearCompare,
      selectedCurrency,
      getPrice
    }
  }
})
