
















































































































































































































































































































































































import {
  ref,
  defineComponent,
  onMounted,
  useRoute,
  useRouter,
  computed,
  watch,
  useContext, nextTick, onBeforeUnmount
} from '@nuxtjs/composition-api';
  import { useUiState } from '~/composables/useUiState';
  import { useUser } from '~/modules/customer/composables/useUser';
  import {eventBus} from '@/almarwan/helpers/EventBus'
  import { useUiNotification, useMagentoConfiguration } from '~/composables';
  import Logo from '@/almarwan/components/Logo.vue'
  import Icon from '@/almarwan/components/Icon.vue';
  import { InquiryTypeEnum } from "~/modules/catalog/category/enums/InquiryTypeEnum";
  import AuthForm from '~/modules/customer/components/LoginModal/AuthForm.vue';
  import { useCustomerStore } from '~/modules/customer/stores/customer';
  import userGetters from '~/modules/customer/getters/userGetters';
  import { useCart } from '~/modules/checkout/composables/useCart';
  import { useAddToCart } from '~/helpers/cart/addToCart';
  import { Product } from '~/modules/catalog/product/types';

export default defineComponent({
  components: {
    Logo,
    Icon,
    AuthForm
  },

  data(){
    return {
      activeInput: '',
      isHovered: '',
      scenario1: false,
      responseText: '',
      expressInterestSent: false,
      isMobile: false,
      //notVisibleYet: true
    }
  },
  mounted () {
    eventBus.$on('makeOfferFailed', (val) => {
      this.responseText = val;
    });
    eventBus.$on('createInquiryFailed', (val) => {
      this.responseText = val;
    });
    if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
      if(window.innerWidth < 1024) {
        this.isMobile = true;
      }

    }
  },
  beforeDestroy() {
    eventBus.$off('makeOfferFailed')
    eventBus.$off('createInquiryFailed')
  },
  methods: {
    placeNewOffer() {
      let pArr =[
        {
          'name': this.offerInfo[0]['name'],
          'sku': this.offerInfo[0]['sku'],
        }
      ];
      this.offerWithdraw = false
      this.setWithdrawOffer(false);
      this.setProdInfo(pArr);
      this.setMakeOffer();
      this.setDownloadSpecActive(false)
    },
    handleKeyUp(inputField){
      if (this.activeInput === inputField.target.name) {
        this.activeInput = '';
      }
    },
    handleHover(inputField){
      this.isHovered = inputField.target.name
    },
    setActive(item) {
      this.activeInput = item
    },
  },
  setup(_, { emit }) {
    const customerStore = useCustomerStore();
    const { user } = useUser();
    const { addItem, error: cartError } = useCart();
    const { addItemToCart } = useAddToCart();
    const route = useRoute();
    const router = useRouter()
    const { app } = useContext();
    const isActive = ref(false);
    const signedIn = ref(false);
    const alreadySignedIn = ref(false);
    let amount = ref('');
    let comment = ref('');
    const isFirst = ref(false);
    const loading = ref(false)
    const notVisibleYet = ref(true);
    const expressInterestSignedIn = ref(false);
    const expressInterestSubmited = ref(false);
    const offerWithdraw = ref(false);
    const showErrorMessage = ref(false);
    const expressInterestSignIn = ref(false);
    //const maintenanceActive = ref(true)
    const offeredProditem = ref<Product>();
    const { isAuthenticated, createMakeOffer, createInquiry, makeOffers, updateMakeOffer, productAlertNotifyInStock } = useUser();
    const { send: sendNotification } = useUiNotification();
    const { isMakeOfferModalOpen, expressInterestActive, makeOfferActive,  setMakeOffer, setExpressInterest,
      makeOfferSubmited, setMakeOfferSubmited, prodNameValue, prodSku, expressInterestSent,
      setInterestSent, prodInfo, offerAmount, withdrawOfferActive, offerInfo, setProdInfo,
      setWithdrawOffer, isReserveProd, setOfferAmount, maintenanceActive, setMaintenanceActive, maintenanceType,
      setMaintenanceType, downloadSpecActive, setDownloadSpecActive, notifyMeActive, notifyMeSubmitted, setNotifyMeSubmitted, setNotifyMe} = useUiState();
      const { selectedCurrency } = useMagentoConfiguration();
    const rentORbuy = computed(() => route.value.path.includes('/rent') ? 'rent' : 'buy');
    if (isMakeOfferModalOpen.value) {
      let bodyElement = document.querySelector('body')
      bodyElement.classList.add('has-overflow')
    };
    const userDetails = computed(() => {
      return {
        firstname: userGetters.getFirstName(user.value),
      }
    });
    const userEmail = computed(() => {
      return {
        email: userGetters.getEmailAddress(user.value),
      }
    });
    const closeModal = () => {
      if(makeOfferActive.value)
        setMakeOffer()
      setMakeOfferSubmited(false)
      if(expressInterestActive.value)
        setExpressInterest()
      if(withdrawOfferActive.value)
        setWithdrawOffer(false)
      setMaintenanceActive(false);
      setDownloadSpecActive(false);
      if(notifyMeActive.value) {
        setNotifyMe()
        setNotifyMeSubmitted(false)
      }
      let bodyElement =   document.querySelector('body')
      bodyElement.classList.remove('has-overflow')
      // if(alreadySignedIn.value){
      //   setTimeout(() => {
      //     location.reload();
      //   }, 4000)
      // }
      emit('close')
    };

    const makeNewOffer = async () => {
      loading.value = true;
      if(amount.value && typeof amount.value !== 'number'){
        amount.value = amount?.value.replace(/\,/g,'');
      }
      if (signedIn.value) {
        if(parseInt(amount.value) > 0){
          setOfferAmount(parseInt(amount.value));
          const offerNew = await createMakeOffer(prodInfo.value[0]['sku'], amount.value);
          setMakeOfferSubmited(false)
          loading.value = false;
          if(offerNew[0]['makeOffer'] == 'success') {
            eventBus.$emit('makeOfferSuccess', true)
            if(localStorage.getItem('compareOfferProd')) {
              offeredProditem.value  = JSON.parse(localStorage.getItem('compareOfferProd'));
              await addItemToCart({ product: offeredProditem.value, quantity: 1, custom_option: {reserve_makeoffer: offerNew[0]['id'] } });
              localStorage.removeItem('compareOfferProd');
            }
            else {
              eventBus.$emit('successOfferId', { offferid: offerNew[0]['id'], prodSku: prodInfo.value[0]['sku'] })
            }
          }
          closeModal();
          eventBus.$emit('offerPlacedCheck', true)
        }
        else {
          sendNotification({
            id: Symbol('product_removed'),
            message: `Please enter a valid amount.`,
            type: 'danger',
            icon: 'error',
            persist: true,
            title: 'Invalid amount!',
          });
          loading.value = false;
        }
      } else {
        //toggleLoginModal();
      }
    };

    const makeNewInquiry = async () => {
      loading.value = true;
      let prodType = 'rent';
      if (signedIn.value) {
        const inqArr = ref([]);
        if(prodInfo.value[0]['type'] == ''){
          prodType = rentORbuy.value=='rent'? InquiryTypeEnum.RENT: InquiryTypeEnum.BUY
        }
        else {
          prodType =prodInfo.value[0]['type'] =='rent'? InquiryTypeEnum.RENT: InquiryTypeEnum.BUY
        }
        inqArr.value.push({'sku':prodInfo.value[0]['sku'], 'cat_id':prodInfo.value[0]['id'], 'type': prodType, 'comments':comment.value});
        if(comment.value != ''){
          const inquiryNew = await createInquiry(inqArr);

          setMakeOfferSubmited(true)
          loading.value = false;
        }
        else {
          sendNotification({
            id: Symbol('product_removed'),
            message: `Please add your enquiry..`,
            type: 'danger',
            icon: 'error',
            persist: false,
            title: 'Invalid input!',
          });
          loading.value = false;
        }
      } else {
        //toggleLoginModal();
      }
    };

    const withdrawOffer = async () => {

      let offerArr =[
        {
          'id': offerInfo.value[0]['id'],
          'price': offerInfo.value[0]['price'],
        }
      ];
      if (isAuthenticated.value) {
        const updateRes = await updateMakeOffer(offerArr);
        if(updateRes[0]['updateMakeOffer'] == 'success') {
          offerWithdraw.value = true
          setMakeOfferSubmited(false);
          amount.value = '';
          eventBus.$emit('withdrawOfferSuccess', true)
          //await router.push(app.localeRoute({ name: 'offer-confirmation' }));
        }
        else {
          sendNotification({
            id: Symbol('product_removed'),
            message: updateRes[0]['stat'],
            type: 'warning',
            icon: 'error',
            persist: false,
            title: 'Invalid amount!',
          });
        }
      }
    };

    const openMintenance = (mainType) => {
      setMaintenanceActive(true);
      setMaintenanceType(mainType);
      router.push({ name: 'maintenance', params: { reqType: mainType }});
      closeModal();
    }

    const downloadSpec = () => {
      var specUrl = ''
      if(localStorage.getItem('fullSpecUrl')) {
        specUrl = localStorage.getItem('fullSpecUrl')
        window.open(specUrl);
      }
    }

    const notifyMe = async(productId) => {
      loading.value = true;
      await productAlertNotifyInStock(productId);
      loading.value = false;
      setNotifyMeSubmitted(true)
    }

    watch(() => customerStore.user, function() {
      if(customerStore.user) {
        setTimeout(function(){
          signedIn.value = true;
        }, 1000);

        setTimeout(function(){
          notVisibleYet.value = false
        }, 2000);
      }
    });
    watch(() => amount.value, function() {
      if(amount.value && typeof amount.value !== 'number'){
        const result = amount.value?.replace(/\D/g, "")
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        nextTick(() => amount.value = result);
      }
    });
    watch(() => customerStore.isFirstTimeUser, function() {
      if(customerStore.isFirstTimeUser) {
        isFirst.value = true
      }
    });
    onMounted(async () => {
      const userName =  userGetters.getFirstName(user.value);
      if((localStorage.getItem('isAuth') && app.$vsf.$magento.config.state.getCustomerToken()) || customerStore.isLoggedIn || userName){
        alreadySignedIn.value = true
        notVisibleYet.value = false
        signedIn.value = true
      }
      eventBus.$on('makeOfferFailed', (val) => {
        sendNotification({
          id: Symbol('product_removed'),
          message: val,
          type: 'warning',
          icon: 'error',
          persist: false,
          title: 'Invalid amount!',
        });
      });
      if(offerAmount.value) {
        amount.value = offerAmount.value
      }
      else { amount.value = '' }
    });
    onBeforeUnmount(() => {
      amount.value = '';
      setOfferAmount('');
      eventBus.$off('offerPlacedCheck');
    })

    return {
      closeModal,
      isActive,
      amount,
      makeOfferActive,
      makeOfferSubmited,
      expressInterestSignedIn,
      showErrorMessage,
      isMakeOfferModalOpen,
      expressInterestSubmited,
      expressInterestActive,
      expressInterestSignIn,
      offerWithdraw,
      withdrawOfferActive,
      setMakeOffer,
      setExpressInterest,
      createMakeOffer,
      makeNewOffer,
      createInquiry,
      makeNewInquiry,
      comment,
      expressInterestSent,
      setInterestSent,
      makeOffers,
      prodInfo,
      loading,
      signedIn,
      alreadySignedIn,
      withdrawOffer,
      offerInfo,
      setProdInfo,
      setWithdrawOffer,
      userDetails,
      userEmail,
      notVisibleYet,
      isReserveProd,
      setOfferAmount,
      addItem,
      addItemToCart,
      offeredProditem,
      maintenanceActive,
      setMaintenanceActive,
      maintenanceType,
      openMintenance,
      downloadSpecActive,
      setDownloadSpecActive,
      downloadSpec,
      isFirst,
      notifyMeActive,
      notifyMeSubmitted,
      notifyMe,
      isAuthenticated,
      selectedCurrency
    }
  }
});
