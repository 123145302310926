
















































































































































































































































































































import {
  defineComponent,
  onMounted, onUnmounted,
  ref,
  useContext,
  useFetch
} from '@nuxtjs/composition-api';
import Logos from '~/almarwan/components/Logos.vue';
import { useCustomerStore } from '~/modules/customer/stores/customer';
import Icon from '~/almarwan/components/Icon.vue';
import { clickOutside } from '~/components/directives/click-outside/click-outside-directive';
import useCategory from '~/modules/catalog/category/composables/useCategory';

export default defineComponent({
  name: 'Navigation',
  components: {
    Icon,
    Logos,
  },
  directives: { clickOutside },
  setup() {
    const { app, localePath } = useContext();
    const { categories: categoryList, load: categoriesListLoad } = useCategory();
    const customerStore = useCustomerStore();
    const isMainNavActive = ref(false);
    const isSubMenuSelected = ref(458);
    const isMainMenuSelected = ref(457);
    const keepFirstMenuOpen = ref(true);
    const machineryChild = ref('');
    const currentTab = ref('buy');
    const isSubNavActive = ref(false);
    const filters = ref({
      isNew: false,
      isLikeNew: false,
      isUsed: false,
    });
    const disableNew = ref(false);
    const disableLikeNew = ref(false);
    const disableUsed = ref(false);
    const navBrands = ref([]);
    const navBrandsRent = ref([]);
    const navRentBrands = ref([]);
    const navBuyNewBrands = ref([]);
    const navBuyUsedBrands = ref([]);
    const attachmentBrandLogos = ref([]);
    const isActive = ref([1]);
    const categoryTree = ref([]);
    const isMobOrTab = ref(false);
    const showSubMenu = ref(false);
    const toggleNav = () => {
      isMainNavActive.value = !isMainNavActive.value;
      document.body.style.overflow = isMainNavActive.value ? 'hidden' : 'auto';
      resetNav()
    };
    const toggleAccordion = (index, mainMenuName) => {
      keepFirstMenuOpen.value = false;
      if (isActive.value.includes(index)) {
        isActive.value = isActive.value.filter((item) => item !== index);
        isSubMenuSelected.value = null
        return true;
      }
      isActive.value = [];
      isActive.value.push(index);
      isMainMenuSelected.value = mainMenuName.id;
      isSubMenuSelected.value = mainMenuName.menus?.[0].id;
    };
    const showSubCategories = (category) => {
      isSubMenuSelected.value = category.id;
      showSubMenu.value = true;
    };
    const handleClick = (val) => {
      currentTab.value = val;
      customerStore.setRentOrBuy(currentTab.value);
      if (val === 'rent') {
        isMainMenuSelected.value = 450;
        isSubMenuSelected.value = 452;
      }
      if (val === 'buy') {
        isMainMenuSelected.value = 457;
        isSubMenuSelected.value = 458;
        isActive.value = [1]
      }
    };
    const generateUrl = (item) => {
      const conditions = [];

      if (filters.value.isNew) {
        conditions.push('619');
      }
      if (filters.value.isLikeNew) {
        conditions.push('620');
      }
      if (filters.value.isUsed) {
        conditions.push('621');
      }
      const slugs = item?.url_path?.split('/')?.filter((slug) => slug !== '');
      const path = localePath({
        name: 'category',
        params: { slug: slugs },
        query: { condition: conditions }
      })
      const title = item.name;
      return { path, title };
    };
    const closeNav = () => {
      isMainNavActive.value = false;
      document.body.style.overflow = 'auto';
    };
    const resetNav = () => {
      if(!keepFirstMenuOpen.value) {
        toggleAccordion(1, categoryTree)
        keepFirstMenuOpen.value = true
      }
      isSubMenuSelected.value = 458;
      isMainMenuSelected.value = 457;
      showSubMenu.value = false;
      keepFirstMenuOpen.value = true;
      currentTab.value = 'buy'
    }
    const goToResult = () => {
      isMainNavActive.value = false;
      isActive.value = [1];
      document.body.style.overflow = 'auto';
    }
    const getHeight = () => {
      if (app.$device.isIos && app.$device.isMobile) {
        return '500px'
      } else if (app.$device.isMobile) {
        return '550px'
      } else {
        return ''
      }
    }
    useFetch(async () => {
      await categoriesListLoad({ pageSize: 20 });
      categoryTree.value = categoryList.value?.[0]?.children
      categoryTree.value = categoryTree.value?.filter((category) => category.include_in_menu);
    })
    onMounted(() => {
      if (app.$device.isMobileOrTablet) {
        isMobOrTab.value = true;
      }
    });
    onUnmounted(() => {
      document.body.style.overflow = 'auto';
      isSubMenuSelected.value = null
    })
    const handleClickOutside = () => {
      isMainNavActive.value = false;
      document.body.style.overflow = 'auto';
      resetNav()
    };
    return {
      showSubCategories,
      handleClickOutside,
      toggleAccordion,
      isSubMenuSelected,
      isMainMenuSelected,
      keepFirstMenuOpen,
      isActive,
      closeNav,
      generateUrl,
      goToResult,
      toggleNav,
      isMainNavActive,
      navBrands,
      navBrandsRent,
      navRentBrands,
      navBuyNewBrands,
      navBuyUsedBrands,
      attachmentBrandLogos,
      handleClick,
      disableNew,
      disableLikeNew,
      disableUsed,
      filters,
      currentTab,
      categoryTree,
      isSubNavActive,
      machineryChild,
      isMobOrTab,
      showSubMenu,
      getHeight
    };
  },
});
