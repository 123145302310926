







































































































































































































































import { defineComponent, onMounted, ref, useContext } from '@nuxtjs/composition-api';
import Locale from '~/components/Header/Locale.vue';
import Icon from '~/almarwan/components/Icon.vue';
import { clickOutside } from '~/components/directives/click-outside/click-outside-directive';
import useUser from '~/modules/customer/composables/useUser';
import { useUiState } from '~/composables';

export default defineComponent({
  name: 'MenuBar',
  directives: { clickOutside },
  components: { Icon, Locale },
  setup() {
    const { isAuthenticated } = useUser();
    const { toggleLoginModal } = useUiState();
    const { app } = useContext();
    const showDrop = ref(false);
    const showMoreDrop = ref(false);
    const showDropDown = () => {
      showDrop.value = !showDrop.value
    }
    const handleClickOutside = () => {
      if(app.$device.isDesktop) {
        showDrop.value = false
      }
    }
    const handleClickOutsideMore = () => {
      showMoreDrop.value = false
    }
    const handleClickOutsideWrapper = () => {
      if (showDrop.value) {
        showDrop.value = false
      }
    }
    onMounted(() => {
      if (process.browser) {
        window.addEventListener('scroll', () => {
          if (showDrop.value || showMoreDrop.value) {
            showDrop.value = false;
            showMoreDrop.value = false;
          }
        });
      }
    })
    return {
      handleClickOutsideMore,
      handleClickOutside,
      toggleLoginModal,
      showDropDown,
      isAuthenticated,
      showMoreDrop,
      showDrop,
      handleClickOutsideWrapper
    }
  }
})
