
























import { defineComponent, useRoute } from '@nuxtjs/composition-api';
import { eventBus } from '~/almarwan/helpers/EventBus';

export default defineComponent({
  name: 'Logo',
  props: {
    cartActiveStep: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      isMobile: false,
    }
  },
  mounted() {
    // if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
    //   this.isMobile = true;
    // } else {
    //   this.isMobile = false;
    // }
  },
  setup() {
    const route = useRoute();
    const scrollByLogo = () => {
      eventBus.$emit('clearSearchResults', true)
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    }
    return {
      scrollByLogo,
      route,
    }
  }
})
