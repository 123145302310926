/* eslint-disable  */

export default {
  Arabic: 'عربي',
  homeTitle: 'Al Marwan | Rent & Buy Heavy Equipment & Parts | New & Used',
  // home page description
  'Al Marwan is your one-stop shop for new & used heavy equipment & machinery rental and sale in the UAE, GCC, and Middle East':
    'Al Marwan is your one-stop shop for new & used heavy equipment & machinery rental and sale in the UAE, GCC, and Middle East',
  dealerTitle: 'Official Heavy Equipment Dealer in UAE, Oman, KSA',
  // dealer description
  'Discover Al Marwan, brand new heavy equipment dealer in the UAE, Oman, and Saud, including KOBELCO, Powerscreen, ABI.':
    'Discover Al Marwan, brand new heavy equipment dealer in the UAE, Oman, and Saud, including KOBELCO, Powerscreen, ABI.',
  maintenanceTitle: 'Book Heavy Equipment Service or Repair',
  // maintenance description
  'Request heavy equipment service tickets and choose from regular, scheduled repair services or urgent breakdown maintenance in-house or on-site.':
    'Request heavy equipment service tickets and choose from regular, scheduled repair services or urgent breakdown maintenance in-house or on-site.',
  newsTitle: 'Read new blogs, customer stories, and news',
  KobelcoLimitedOfferTitle: 'Limited-Time KOBELCO Excavators Offer',
  KobelcoZeroDownPaymentTitle: 'Kobelco Excavators | Zero Downpayment | 24 months installments',
  KobelcoZeroDownPaymentDesc: 'Buy diggers with 0 down payment and 24 months installment plan lease-to-own from official dealer',
  "kobelcoNavtitle": "Buy Kobelco Excavators with Zero Down payment",
  "limittedOfferHeading-1":"Limited Time Offer",
  "limittedOfferHeading-2":"in UAE & Oman",
  "registerInterest":"Register interest today",
  // news description
  'Discover heavy equipment and construction updates, machinery news, blog posts, and customer stories on Al Marwan.':
    'Discover heavy equipment and construction updates, machinery news, blog posts, and customer stories on Al Marwan.',
  'About us': 'About us',
  Account: 'Account',
  'Add new address': 'Add new address',
  'Add to compare': 'Add to compare',
  'Add to Wishlist': 'Add to Wishlist',
  'Additional Information': 'Additional Information',
  'All Orders': 'All Orders',
  'Allow order notifications': 'Allow order notifications',
  Apply: 'Apply',
  'Applied Coupon': 'Applied Coupon',
  'Attention!': 'Attention!',
  'Back to home': 'Back to home',
  'Back to homepage': 'Back to homepage',
  Billing: 'Billing',
  'Billing address': 'Billing address',
  Brand: 'Brand',
  Cancel: 'Cancel',
  Cart: 'Cart',
  Categories: 'Categories',
  Change: 'Change',
  'Change password your account': 'If you want to change the password to access your account, enter the following information',
  'Clear all': 'Clear all',
  Color: 'Color',
  'Commercial information': 'and agree to receive personalized commercial information from Brand name by email',
  'Contact details updated': 'Keep your addresses and contact details updated.',
  'Contact us': 'Contact us',
  contactUsTitle: 'Contact us or find machinery stores near you',
  // contact us description
  'Contact Al Marwan Heavy Machinery for your heavy equipment rental and sale needs. Find heavy equipment workshops near you, visit our stores in UAE.':
    'Contact Al Marwan Heavy Machinery for your heavy equipment rental and sale needs. Find heavy equipment workshops near you, visit our stores in UAE.',
  'Continue to billing': 'Continue to billing',
  'Continue to payment': 'Continue to payment',
  'Continue to shipping': 'Continue to shipping',
  'Cookies Policy': 'Cookies Policy',
  'Create an account': 'Create an account',
  'Customer Reviews': 'Customer Reviews',
  'Customer service': 'Customer service',
  Date: 'Date',
  'Default Billing Address': 'Default Billing Address',
  'Default Shipping Address': 'Default Shipping Address',
  Delete: 'Delete',
  Departments: 'Departments',
  Description: 'Description',
  'Details and status orders': 'Check the details and status of your orders in the online store. You can also cancel your order or request a return.',
  Discount: 'Discount',
  Done: 'Done',
  Download: 'Download',
  'Download all': 'Download all',
  Edit: 'Edit',
  'Email address': 'Email address',
  Empty: "Looks like you haven't added any items to the bag yet. Start shopping to fill it in.",
  'Empty bag': 'Empty bag',
  'Enjoy your free account': 'Enjoy these perks with your free account!',
  'Enter promo code': 'Enter promo code',
  Feedback: 'Your feedback is important to us. Let us know what we could improve.',
  'Feel free to edit': 'Feel free to edit any of your details below so your account is always up to date',
  Filters: 'Filters',
  'Find out more': 'Find out more',
  'First Name': 'First Name',
  'Forgot Password': 'Forgot Password?',
  'Forgot Password Modal Email': 'Email you are using to sign in:',
  forgotPasswordConfirmation:
    'Thanks! If there is an account registered with the {0} email, you will find message with a password reset link in your inbox.',
  'Forgotten password?': 'Forgotten password?',
  'Go back': 'Go back',
  'Go back shopping': 'Go back shopping',
  'Go back to shop': 'Go back to shop',
  'Go to checkout': 'Go to checkout',
  Guarantee: 'Guarantee',
  Help: 'Help',
  'Help & FAQs': 'Help & FAQs',
  hide: 'hide',
  Home: 'Home',
  'I agree to': 'I agree to',
  'I confirm subscription': 'I confirm subscription',
  'I want to create an account': 'I want to create an account',
  'Info after order':
    'You can log to your account using e-mail and password defined earlier. On your account you can edit your profile data, check history of transactions, edit subscription to newsletter.',
  Instruction1: 'Take care of me',
  Instruction2: 'Just here for the care instructions?',
  Instruction3: 'Yeah, we thought so',
  'It was not possible to request a new password, please check the entered email address.':
    'It was not possible to request a new password, please check the entered email address.',
  Item: 'Item',
  Items: 'Items',
  Kidswear: 'Kidswear',
  'Last Name': 'Last Name',
  "Let's start now – we'll help you": "Let's start now – we'll help you.",
  'Log into your account': 'Log into your account',
  Login: 'Login',
  'login in to your account': 'login in to your account',
  "Looks like you haven't added any items to the bag yet. Start shopping to fill it in.":
    "Looks like you haven't added any items to the bag yet. Start shopping to fill it in.",
  "Looks like you haven't added any items to the Wishlist.": "Looks like you haven't added any items to the Wishlist.",
  'Make an order': 'Make an order',
  'Manage addresses':
    'Manage all the addresses you want (work place, home address...) This way you won"t have to enter the address manually with each order.',
  'Manage billing addresses':
    'Manage all the billing addresses you want (work place, home address...) This way you won"t have to enter the billing address manually with each order.',
  'Manage shipping addresses':
    'Manage all the shipping addresses you want (work place, home address...) This way you won"t have to enter the shipping address manually with each order.',
  'Match it with': 'Match it with',
  'Men fashion': 'Men fashion',
  Menu: 'Menu',
  'My billing and shipping address are the same': 'My billing and shipping address are the same',
  'My Cart': 'My Cart',
  'No account': "Don't have an account yet?",
  or: 'or',
  'or fill the details below': 'or fill the details below',
  'Order ID': 'Order ID',
  'Order information': 'Order information',
  'Order No.': 'Order No.',
  'Order summary': 'Order summary',
  'Other products you might like': 'Other products you might like',
  'Out of stock': 'Out of stock',
  Password: 'Password',
  'Password Changed': 'Password successfuly changed. You can now go back to homepage and sign in.',
  'Pay for order': 'Pay for order',
  Payment: 'Payment',
  'Payment & Delivery': 'Payment & Delivery',
  'Payment Method': 'Payment Method',
  'Payment Methods': 'Payment Methods',
  'Personal details': 'Personal details',
  'Please type your current password to change your email address.': 'Please type your current password to change your email address.',
  Price: 'Price',
  Privacy: 'Privacy',
  'Privacy Policy': 'Privacy Policy',
  Product: 'Product',
  'Product suggestions': 'Product suggestions',
  Products: 'Products',
  'Products found': 'Products found',
  'Purchase terms': 'Purchase terms',
  'Quality in the details': 'Quality in the details',
  Quantity: 'Quantity',
  'Read all reviews': 'Read all reviews',
  'Read and understand': 'I have read and understand the',
  'Read reviews': 'Read reviews',
  Register: 'Register',
  'Register today': 'Register today',
  Remove: 'Remove',
  'Remove Address': 'Remove Address',
  'Remove from Wishlist': 'Remove from Wishlist',
  'Repeat Password': 'Repeat Password',
  'Reset Password': 'Reset Password',
  'Review my order': 'Review my order',
  'Same as shipping address': 'Same as shipping address',
  'Save changes': 'Save changes',
  'Save for later': 'Save for later',
  'Save Password': 'Save Password',
  Search: 'Search',
  'Search for items': 'Search for items',
  'Search results': 'Search results',
  'Sections that interest you': 'Sections that interest you',
  'See all results': 'See all results',
  'See more': 'See more',
  'Select payment method': 'Select payment method',
  'Select shipping method': 'Select shipping method',
  'Send my feedback': 'Send my feedback',
  'Set up newsletter':
    'Set up your newsletter and we will send you information about new products and trends from the sections you selected every week.',
  'Share your look': 'Share your look',
  Shipping: 'Shipping',
  'Shipping address': 'Shipping address',
  'Shipping details': 'Shipping details',
  'Shipping method': 'Shipping method',
  Show: 'Show',
  'show more': 'show more',
  'Show on page': 'Show on page',
  'Sign in': 'Sign in',
  'Size guide': 'Size guide',
  'Sign Up for Newsletter': 'Sign Up for Newsletter',
  'Sort by': 'Sort by',
  'Sort: Default': 'Default',
  'Sort: Name A-Z': 'Name A-Z',
  'Sort: Name Z-A': 'Name Z-A',
  'Sort: Price from high to low': 'Price from high to low',
  'Sort: Price from low to high': 'Price from low to high',
  'Start shopping': 'Start shopping',
  Status: 'Status',
  Subscribe: 'Subscribe',
  'Subscribe to newsletter': 'Subscribe to newsletter',
  subscribeToNewsletterModalContent:
    'After signing up for the newsletter, you will receive special offers and messages from VSF via email. We will not sell or distribute your email to any third party at any time. Please see our {0}.',
  Subtotal: 'Subtotal',
  'Subtotal price': 'Subtotal price',
  'Successful placed order':
    'You have successfully placed the order. You can check status of your order by using our delivery status feature. You will receive an order confirmation e-mail with details of your order and a link to track its progress.',
  'Terms and conditions': 'Terms and conditions',
  'Thank you': 'Thank you',
  'Thank You Inbox': "If the message is not arriving in your inbox, try another email address you might've used to register.",
  Total: 'Total',
  'Order Total': 'Order Total',
  'Total items': 'Total items',
  'Total price': 'Total price',
  'Update password': 'Update password',
  'Update personal data': 'Update personal data',
  'Use your personal data':
    'At Brand name, we attach great importance to privacy issues and are committed to protecting the personal data of our users. Learn more about how we care and use your personal data in the',
  'User Account': 'User Account',
  View: 'View',
  'View details': 'View details',
  'Vue Storefront Next': 'Vue Storefront Next',
  'Who we are': 'Who we are',
  Wishlist: 'Wishlist',
  'Women fashion': 'Women fashion',
  'You added {product} to your shopping cart.': 'You added {product} to your shopping cart.',
  'You are not authorized, please log in': 'You are not authorized, please log in',
  'You can unsubscribe at any time': 'You can unsubscribe at any time',
  'You currently have no orders': 'You currently have no orders',
  "You haven't searched for items yet": "You haven't searched for items yet.",
  'Your bag is empty': 'Your bag is empty',
  'Your current email address is': 'Your current email address is',
  'Your email': 'Your email',
  'The email field must be a valid email': 'The email field must be a valid email',
  'You have submitted no reviews': 'You have submitted no reviews',
  'The account sign-in was incorrect or your account is disabled temporarily. Please wait and try again later.':
    'The account sign-in was incorrect or your account is disabled temporarily. Please wait and try again later.',
  'A customer with the same email address already exists in an associated website.':
    'A customer with the same email address already exists in an associated website.',
  'Invalid email': 'Invalid email',
  'SUMMER COLLECTION {year}': 'SUMMER COLLECTION {year}',
  'Colorful summer dresses are already in store': 'Colorful summer dresses are already in store',
  'Learn more': 'Learn more',
  Dresses: 'Dresses',
  'Cocktail & Party': 'Cocktail & Party',
  'Linen Dresses': 'Linen Dresses',
  'T-Shirts': 'T-Shirts',
  'The office life': 'The office life',
  "Find stunning women's cocktail dresses and party dresses. Stand out in lace and metallic cocktail dresses from all your favorite brands.":
    "Find stunning women's cocktail dresses and party dresses. Stand out in lace and metallic cocktail dresses from all your favorite brands.",
  'Shop now': 'Shop now',
  'The Office Life': 'The Office Life',
  'Summer Sandals': 'Summer Sandals',
  'Eco Sandals': 'Eco Sandals',
  'Subscribe to Newsletters': 'Subscribe to Newsletters',
  'Be aware of upcoming sales and events. Receive gifts and special offers!':
    'Be aware of upcoming sales and events. Receive gifts and special offers',
  'Fashion to take away': 'Fashion to take away',
  'Download our application to your mobile': 'Download our application to your mobile',
  'Share Your Look': 'Share Your Look',
  'My Account': 'My Account',
  'My profile': 'My profile',
  'Personal Details': 'Personal Details',
  'Addresses details': 'Addresses details',
  'My newsletter': 'My newsletter',
  'Log out': 'Log out',
  'My reviews': 'My reviews',
  'Order history': 'Order history',
  'Order details': 'Order details',
  'My wishlist': 'My wishlist',
  'Password change': 'Password change',
  'Personal data': 'Personal data',
  'Your e-mail': 'Your e-mail',
  'Current Password': 'Current Password',
  'You are not authorized, please log in.': 'You are not authorized, please log in.',
  'Go To Product': 'Go To Product',
  'Change to list view': 'Change to list view',
  'Change to grid view': 'Change to grid view',
  Returns: 'Returns',
  'My orders': 'My orders',
  'Add the address': 'Add the address',
  'Set as default shipping': 'Set as default shipping',
  'Set as default billing': 'Set as default billing',
  'House/Apartment number': 'House/Apartment number',
  'Street Name': 'Street Name',
  City: 'City',
  'State/Province': 'State/Province',
  'Zip-code': 'Zip-code',
  Country: 'Country',
  'Phone number': 'Phone number',
  'Please select a country first': 'Please select a country first',
  'This field is required': 'This field is required',
  'The field should have at least 2 characters': 'The field should have at least 2 characters',
  'The field should have at least 4 characters': 'The field should have at least 4 characters',
  'The field should have at least 8 characters': 'The field should have at least 8 characters',
  'New Password': 'New Password',
  'New Products': 'New Products',
  'There are no shipping methods available for this country. We are sorry, please try with different country.':
    'There are no shipping methods available for this country. We are sorry, please try with different country.',
  'There was some error while trying to fetch shipping methods. We are sorry, please try with different shipping details.':
    'There was some error while trying to fetch shipping methods. We are sorry, please try with different shipping details.',
  'There was some error while trying to select this shipping method. We are sorry, please try with different shipping method.':
    'There was some error while trying to select this shipping method. We are sorry, please try with different shipping method.',
  "We can't find products matching the selection.": "We can't find products matching the selection.",
  'Page not found': 'Page not found',
  'Back to Home page': 'Back to Home page',
  'An error occurred': 'An error occurred',
  AllProductsFromCategory: 'All {categoryName}',
  'Show more': 'Show more',
  'Show less': 'Show less',
  Yes: 'Yes',
  No: 'No',
  'Apply filters': 'Apply filters',
  "The coupon code isn't valid. Verify the code and try again.": "The coupon code isn't valid. Verify the code and try again.",
  From: 'From',
  To: 'To',
  'Your customization': 'Your customization',
  "Passwords don't match": "Passwords don't match",
  'The password must be at least 8 characters long and must contain at least: 1 uppercase or lowercase letter, 1 number, or one special character (E.g. , . _ & ? etc)':
    'The password must be at least 8 characters long and must contain at least: 1 uppercase or lowercase letter, 1 number, or one special character (E.g. , . _ & ? etc)',
  'Show all products': 'Show all products',
  'Select previously saved address': 'Select previously saved address',
  'Enter different address': 'Enter different address',
  'You must confirm your account. Please check your email for the confirmation link.':
    'You must confirm your account. Please check your email for the confirmation link.',
  'Change Store': 'Change Store',
  'Choose Currency': 'Choose Currency',
  'Add a review': 'Add a review',
  'Add to cart': 'Add to cart',
  Title: 'Title',
  Name: 'Name',
  Review: 'Review',
  'Add review': 'Add review',
  'Are you sure you would like to remove this item from the shopping cart?':
    'Are you sure you would like to remove this item from the shopping cart?',
  'Your cart is empty': 'Your cart is empty',
  'Are you sure?': 'Are you sure?',
  '{0} has been successfully removed from your cart': '{0} has been successfully removed from your cart',
  Amount: 'Amount',
  'Thank you for your order!': 'Thank you for your order!',
  'Your Purchase': 'Your Purchase',
  'Primary contacts for any questions': 'Primary contacts for any questions',
  'Your Account': 'Your account',
  'What can we improve': 'What can we improve',
  'Payment date': 'Payment date',
  'The user password was changed successfully updated!': 'The user password was changed successfully updated!',
  'The user account data was successfully updated!': 'The user account data was successfully updated!',
  'You submitted your review for moderation.': 'You submitted your review for moderation.',
  'Starting at': 'Starting at',
  Subscription: 'Newsletter Subscription',
  'Exclusive Dealer': 'Exclusive Dealer',
  'Achieve Your Business Goals': 'Achieve Your Business Goals',
  dealers: {
    kobelco: {
      title: 'Official KOBELCO Dealer in the UAE, Oman, Saudi and Iraq',
      description: 'Authorized distributor for Kobelco crawler cranes and excavators for the UAE, Oman, Saudi, Lebanon, Iraq & jordan.',
    },
    pronar: {
      title: 'Exclusive Pronar Recycling Partner in UAE, Oman, Saudi',
      description: 'Al Marwan is the partner of Pronar Recycling in UAE, Oman, and Saudi, supplying recycling and waste management equipment',
    },
    powerscreen: {
      title: 'Powerscreen by Terex Dealer in the UAE',
      description: 'Al Marwan supplies mobile vibratory and power screeners in the UAE as the official Powerscreen by a Terex agent.',
    },
    dynapaclight: {
      title: 'Dynapac Light Compaction Machines Agent in UAE',
      description: 'Get genuine plate compactors, rammers, and rollers from the official Dynapac light compaction equipment agent for UAE.',
    },
    dynapacredline: {
      title: 'Redline by Dynapac Distributors in the UAE',
      description: 'Al Marwan supplies the UAE with genuine Redline by Dynapac heavy compaction equipment, including pavers and road rollers.',
    },
    toku: {
      title: 'Official Toku Jackhammers Supplier in UAE',
      description: 'Al Marwan is the sole distributor of Toku jackhammers, hydraulic rock breakers, and concrete hammers in the UAE.',
    },
    jisung: {
      title: 'Jisung Korea Hammer Distributor in UAE, Saudi and Oman',
      description: 'Get brand-new Korean-made Jisung jackhammers from the exclusive UAE, Saudi and Oman distributor.',
    },
    simex: {
      title: 'Simex Machinery Attachments Dealer in UAE',
      description: 'Al Marwan is the distributor of Simex Italy machinery attachments in the UAE. Make the most out of your excavator.',
    },
    abi: {
      title: 'Dealer of ABI Vibrating Hammers in UAE, Oman and Saudi',
      description: 'Get piling rigs, foundation equipment and vibratory hammers from ABI distributor in the UAE, Saudi and Oman.',
    },
    worldattachments: {
      title: 'Official UAE Supplier of World Attachments',
      description: 'World Attachments provides screening and crushing buckets in the UAE through Al Marwan. Get OEM attachments and parts.',
    },
    cifa: {
      title: "CIFA Concrete Machinery Official Dealer in UAE and Oman",
      description: "Buy brand-new CIFA concrete machines. Truck pumps, truck mixers, portable pumps, flooring, readymix, Energya, and more.",
    }
  },
  FAQuestion: [
    {
      question: 'Who is Al Marwan Machinery?',
      answer:
        'Al Marwan Machinery operates in the UAE, Saudi Arabia, and Oman, specializing in heavy equipment rental with young, high-performance machinery and certified operators for all project sizes, including specialized equipment like long-boom excavators. As an authorized distributor for KOBELCO, Powerscreen, Dynapac, and ABI, we offer brand-new OEM machines, parts, and attachments. We also sell pre-owned equipment from renowned brands such as CAT, Komatsu, Volvo, and others, backed by detailed inspection reports and service histories, all accessible through our online catalog.',
    },
    {
      question: 'What is heavy machinery?',
      answer:
        'Heavy machinery typically refers to mobile units or vehicles, weighing 1.5 tons and upwards, used for tasks that require more power than manual labor. Starting from mini excavators and skid steer/compact track loaders, this category extends to enormous machines like large excavators, crawler cranes, and bulldozers, which can weigh up to 250 tons. In construction, common heavy equipment includes excavators, bulldozers, wheel loaders, cranes, trucks, graders, scrapers, rollers, pavers, millers, crushers, screeners, as well as concrete mixing and pumping trucks.',
    },
    {
      question: 'I have a question about a specific machine, who can I turn to?',
      answer:
        "For detailed insights on any machine listed on our website, each product page features a comprehensive image gallery, an up-to-date inspection report, comparable technical specifications, downloadable spec sheets, operating videos, and 360-degree product renders, providing a thorough overview of the machine's condition. If you need further information or have specific inquiries about a piece of heavy equipment, feel free to reach out to us through the contact options provided in each listing, including WhatsApp, phone call, or email.",
    },
    {
      question: 'Is my information safe on Al Marwan?',
      answer:
        'Yes, your information is safe on Al Marwan. We have measures in place to protect your data and we do not share it without your consent. Advanced cloud systems are used to secure your billing and personal details.',
    },
    {
      question: 'What happens when I mark a machine as a favorite?',
      answer:
        'When you mark a machine as a favorite on the website adds it to your wishlist, signaling your interest in that particular machine. While this action does not reserve the equipment, it does initiate periodic notifications regarding its availability, and routine reminders about potential purchase or rental opportunities. Our team may also reach out to provide personalized assistance based on your marked preferences, ensuring you stay informed and engaged with your selected equipment.',
    },
    {
      question: 'Can I see any machine physically?',
      answer:
        "Yes. Customers are welcome to physically inspect our machines at our multiple showroom locations. We have two yards in Sharjah Industrial Area 15th (our main yards), an additional yard in Sharjah Industrial Area 2, one in Dubai's Jebel Ali Free Zone (JAFZA), another in Musaffah, Abu Dhabi, as well as international locations in Riyadh, Saudi Arabia, and Muscat, Oman. Each machine listing on our website includes the specific location where it's available for walk-in viewing and inspection. Visit almarwan.com/contact-us for more information about our open hours and showroom locations.",
    },
    {
      question: 'How can I buy equipment on Al Marwan?',
      answer:
        'For machines listed with prices, you can directly add them to your cart and proceed with the purchase. If you come across used equipment without a displayed price, this is an opportunity for you to make an offer. For brand-new equipment, expressing your interest in a machine prompts our sales team to get in touch with you promptly. In cases where a machine is already sold or reserved, the "notify me" option allows you to receive alerts on similar listings that match your requirements.',
    },
    {
      question: 'I would like to finance my machine, how can I do that?',
      answer:
        'For those interested in financing their machinery purchase, Al Marwan provides multiple convenient options. Customers can apply for financing directly from our website\'s homepage, or explore machine-specific financing by hovering over the payment options tab, or by clicking "apply for financing" under the description of each machine. We offer a variety of financing solutions, including bank financing, Al Marwan financing, credit facilities, Buy Now Pay Later (BNPL) options, among others, to facilitate your journey towards machinery ownership.',
    },
    {
      question: 'How do I make an offer on Al Marwan?',
      answer:
        'Making an offer on Al Marwan is a straightforward process available for both priced and unpriced used equipment. Simply click the "make offer" button on the listing page of the machine you\'re interested in. Enter your desired price and proceed to pay a refundable booking fee. Once submitted, your offer is reviewed by our team. We may accept it, propose a counteroffer, or in some cases, reject it. Throughout the negotiation, our team might contact you for additional details or clarifications to ensure a smooth and transparent transaction process.',
    },
    {
      question: 'What is the booking fee? And is it refundable in case of acceptance, rejection, or withdrawal?',
      answer:
        "The booking fee is a small, upfront amount paid when you make an offer on a used machine, usually around $100. This fee is fully refundable if your offer is rejected or if you choose to withdraw your offer. In the event your offer is accepted, this fee isn't lost; instead, it is conveniently deducted from the total purchase price of the machine.",
    },
    {
      question: 'Do you offer a warranty or guarantee for new and used equipment purchased on Al Marwan?',
      answer:
        "As an authorized distributor, Al Marwan offers basic warranty coverage for new equipment from brands we represent, including KOBELCO, Powerscreen, Dynapac, and ABI. The duration and extent of this coverage vary by brand and model. Additionally, customers have the option to opt for extended warranty packages. We also offer service contracts on a case-by-case basis, ensuring added peace of mind. However, used equipment that is beyond the manufacturer's warranty period does not come under an Al Marwan coverage plan.",
    },
    {
      question: 'Do I pick up my equipment or is it delivered?',
      answer:
        'When buying heavy equipment on Al Marwan, customers can choose between self-pickup and delivery. For self-pickup, customers are responsible for transport and must collect the equipment within 8 business days from one of our yards located in the Industrial Areas 2 and 15, Sharjah, JAFZA Dubai, Musaffah Abu Dhabi in the UAE, as well as in Muscat, Oman, and Riyadh, Saudi Arabia. Alternatively, we offer worldwide shipping to the nearest port for those opting for delivery. The initial shipping estimate is subject to change, and the actual costs may vary. Both delivery times and fees are approximate and not guaranteed.',
    },
    {
      question: 'Can I rent heavy equipment on Al Marwan?',
      answer:
        'Yes. You can rent equipment on Al Marwan. Accessible directly from our homepage, our user-friendly online rental catalog allows you to effortlessly browse and filter options by category, size, and specialty to find exactly what you need. Once you\'ve made your choice, simply click "express interest" on the desired equipment. A rental operations representative will promptly get in touch with you to gather the necessary information and finalize the rental details. We cater to a wide range of projects across the UAE, Oman, and Saudi Arabia, ensuring you have the right equipment for your specific needs.',
    },
    {
      question: 'Will Al Marwan rent machinery to me if I do not have a credit/bank account?',
      answer:
        'Yes. Al Marwan offers flexible payment solutions for heavy machinery rentals, accommodating customers who prefer to pay in cash. Recognizing the diverse financial situations of our clients, we also provide financing and flexible installment payment plans for those with hard cash. This approach ensures that all our customers, regardless of their banking status, have access to the necessary equipment for their projects, with a focus on practicality and convenience.',
    },
    {
      question: 'How can I add an item to my cart?',
      answer:
        'To add an item to your cart on Al Marwan, there are a few options available. If you\'re interested in a specific item listed for sale, you can click the "buy now" button on the listing to add it directly to your cart. Additionally, if you "make an offer" on a listing, that offer will also be added to your cart. Furthermore, if you apply for financing within a listing, it will automatically be added to your cart. Alternatively, if you want to save an item for future reference without purchasing it immediately, you can click the "heart" button on the listing to add it to your wishlist. This allows you to keep track of items you\'re interested in, even if they are not currently in your cart.',
    },
    {
      question: 'Do you offer rental protection for the equipment I rent on Al Marwan?',
      answer:
        'We provide basic insurance coverage for our own machines that are rented out. However, hirers are responsible for arranging any additional insurance or protection required based on regional regulations, project needs, and specific equipment hired. It is advisable to consult with insurance providers and discuss coverage requirements with our team for the rental process.',
    },
    {
      question: 'Do I need an account to buy or rent machinery on Al Marwan?',
      answer:
        'Browsing our extensive machinery listings on Al Marwan does not require an account. However, to engage further – such as making offers, expressing interest, or adding items to your cart – you will need to log in or create a free account. Having an account also enhances your experience, allowing access to a suite of useful resources. These include viewing detailed inspection reports, downloading specification sheets, applying for financing, requesting service tickets, and more. This account setup ensures a more personalized and efficient experience on our platform.',
    },
    {
      question: 'What are the payment options available?',
      answer:
        "Al Marwan offers multiple payment options, including debit/credit card payment, manual bank transfer, and online wire transfer. We also offer various machinery financing options including credit facility, lease-to-own, and installment plans to ease our customers' machinery ownership journey. Customers can choose the method that suits them best for completing their purchase.",
    },
    {
      question: 'Do you offer heavy machinery repair or maintenance services?',
      answer:
        "Yes, Al Marwan provides comprehensive repair and maintenance services tailored to our customers' needs. For those renting heavy equipment, we offer a mobile service workshop that remains on standby at the construction site, ensuring rapid response to any breakdowns. Customers purchasing brand-new equipment benefit from our periodic service options, which can be conducted either at our expansive 285,000 sq.ft. heavy machinery workshop facilities or directly on-site. This includes both preventive and reactive service kits, which cover essential consumables for a specified period, ensuring your machinery remains in top condition.",
    },
    {
      question: 'Are all used equipment on Al Marwan checked thoroughly?',
      answer:
        'Yes, all used machinery listed for sale on Al Marwan undergo thorough inspections. Detailed inspection reports with ratings and comments are provided for each machine, covering various aspects of their condition. Expert mechanics and technicians conduct these heavy equipment inspections to assess the operational capacity and service life of the machines. Customers can also visit the yard and hire their own inspector for an independent inspection if desired.',
    },
    {
      question: 'How do I book a maintenance appointment/service ticket on Al Marwan?',
      answer:
        "To book a maintenance appointment or service ticket on Al Marwan you can navigate to the website's home menu and click on the \"Maintenance\" section. Choose the type of maintenance service you require. Repair service is available for machines we rent out. It includes two types of repairs: On-site repair where it's conducted at the machine's location. Workshop repair where the machine is recovered and repaired at our workshop. As for regular service, it's scheduled for the brands we represent, and it's typically done every 1000 hours or 1 year of operation, as per the warranty package purchased. By selecting the appropriate service option, you can proceed with booking a maintenance appointment or service ticket for your specific needs.",
    },
    {
      question: 'What kind of heavy equipment repairs can be handled on-site?',
      answer:
        'Thanks to our mobile service workshop staffed with a skilled crew ready to maintain machines on standby, we handle preventive maintenance tasks like greasing, fueling, welding, and cleaning of the machinery. Additionally, we are equipped to manage reactive maintenance for machines that experience breakdowns, including part replacements, hydraulic hose repairs, and refilling service fluids like oils. Our on-site team is adept at swiftly addressing a wide range of maintenance and repair needs, minimizing downtime and ensuring your equipment stays operational.',
    },
    {
      question: 'How will my equipment be taken to the workshop?',
      answer:
        'Al Marwan has an extensive fleet of heavy transportation equipment, including low-bed trucks, tow-trucks, recovery trucks, prime movers, and trailers, all designed to securely move machines between our yards, sites, and workshop facilities. Our skilled team ensures that your equipment is safely and promptly transported for repair or maintenance, minimizing any inconvenience and downtime.',
    },
    {
      question: 'How do I contact your workshop?',
      answer:
        "To contact our heavy equipment workshops, visit our 'Contact Us' page. There, you'll find detailed information on our multiple workshop across the GCC, including those in Sharjah, Dubai, Abu Dhabi in the UAE, as well as Muscat in Oman, and Riyadh in Saudi Arabia. You can also find details on our opening hours, phone numbers, addresses, and emails.",
    },
    {
      question: 'What happens to my deposit when I cancel my order on Al Marwan?',
      answer:
        "For canceled orders on Al Marwan, customers will need to follow the specific refund process based on their scenario, whether it's regarding a booking fee refund or a cancellation of a purchased item. It's important to contact our sales team to initiate the cancellation and discuss the refund process, which may vary depending on the cycle of the order. Deposits paid of any amount to purchase a machine are non-refundable.",
    },
    {
      question: 'How do I change my delivery address after ordering a machine?',
      answer:
        'Upon purchasing a machine on Al Marwan, customers have two options, either self pick-up or delivery/shipping. If a customer needs to change their delivery address or mode of delivery, they can contact us via email at hello@almarwan.com to make the necessary amendments.',
    },
    {
      question: 'How do I check on the status of my order?',
      answer:
        "To ensure you're consistently informed about your order's status, we make contact through periodic notifications sent to you via email and other communication channels. Additionally, you may contact our customer service help desk during business hours for the latest update on your order.",
    },
    {
      question: 'What happens after I place my order?',
      answer:
        "During checkout, you'll be prompted to provide your shipping and payment details. Following this, you'll receive a confirmation email acknowledging your order. Our sales team may reach out to you to address any additional details or adjustments required. In cases of credit card payments, be aware that the final amount may be adjusted to reflect actual shipping costs. For those opting for manual bank transfers, the item is reserved for you for 48 hours. Upon confirmation of your payment within this timeframe, we initiate the shipping process to ensure your equipment is on its way to you promptly.",
    },
    {
      question: 'Is my payment secure on Al Marwan?',
      answer:
        'Yes, your payment is secure on Al Marwan. We use global standard secured payment methods to protect your personal and billing information. Multiple online and offline payment methods are available for customers to choose from, ensuring a secure and convenient payment process.',
    },
    {
      question: 'Can I replace a spare part for another instead of requesting a refund?',
      answer:
        'Yes. You can put in a request for a different part instead of a refund or a return. You can connect with our customer service help desk and we will provide you with the details related to the exchange or a refund as appropriate.',
    },
    {
      question: 'How can I order machinery spare parts on Al Marwan?',
      answer:
        'To order heavy machinery spare parts on our website, you can navigate to the spare parts portal, add the part number and the VIN of your machine and hit search. The desired part will appear and you can then proceed to check out. If, for instance, the part you need is not found on the website, you can contact us to check availability and we will arrange one for you.',
    },
    {
      question: 'Do you provide rebuilt replacement parts?',
      answer:
        'We provide both new and rebuilt replacement parts for a range of heavy machinery. Our heavy equipment spare parts specialists will be able to recommend the best replacement part for your needs.',
    },
    {
      question: 'How quickly do you stock heavy equipment spare parts?',
      answer:
        'Our heavy equipment spare parts inventory is regularly stocked with the brands we represent as authorized distributors, giving us a rapid response rate to orders.',
    },
    {
      question: 'Can I order heavy equipment parts in bulk?',
      answer:
        'Yes, select offers on heavy equipment spare parts are available in bulk. Check in with our sales team for the full heavy equipment spare parts list.',
    },
    {
      question: 'Can someone help me to find the right spare parts for my machine?',
      answer:
        'We want to make sure you can find the right replacement part, which is why we provide compatibility confirmation. You can find a list of different makes and models on our website. You can also contact our customer service team by email or phone who will confirm the compatibility of your particular item.',
    },
    {
      question: 'What brands of heavy equipment spare parts do you provide?',
      answer:
        "We provide a range of OEM brands to help you find the heavy equipment spare parts you're looking for. We carry Kobelco, Powerscreen, Dynapac, ABI, CAT, JCB, Hitachi, and Komatsu, among other options. We also offer remanufactured/rebuilt parts as well as a portion of commercial and aftermarket parts for sale.",
    },
  ],
  ServicesSubText: 'Services',
  checkoutMessage: 'Checkout can be carried out in USD only. Please confirm to switch currency or click cancel to go back.',
  popupTitle: 'Notice',
  confirmBtn: 'Confirm',

  //services meta
  serviceTitle: "Heavy Equipment Services Solutions for Construction Projects",
  serviceDesc: "Buy new machines and parts from dealer. Find machinery rentals and low-hours used equipment for sale. Get maintenance.",
  partsTitle: "Buy machinery original parts and attachments",
  partsDesc: "For sale: OEM heavy equipment parts and attachments. Jackhammers, buckets, auger drives from ABI, Jisung (JSC), Toku.",
  newMachineryTitle: "Buy brand-new heavy machinery with authorized dealer support",
  newMachineryDesc: "Buy new cranes, excavators, crushers, screeners, piling rigs, from global brands: Kobelco, ABI, Dynapac, Powerscreen.",
  usedMachineryTitle: "Used heavy machinery for sale with international shipping",
  usedMachineryDesc: "Well-maintained in stock used heavy equipment for sale from reputable global brands like Caterpillar, Volvo, and Komatsu",
  machineryRentalTitle: "Heavy equipment rentals from GCC award-winning company",
  machineryRentalDesc: "Rent heavy machinery from largest fleet of 40-45 ton articulated dump trucks to largest dozers, excavators, and more.",
  machineryMaintainlTitle: "Machinery maintenance and heavy equipment repair service",
  machineryMaintainDesc: "Easy access to specialized machinery workshops for maintenance and repairs. Get on site service with mobile workshops.",
  machineryFinancelTitle: "Easy and quick financing options to buy heavy equipment",
  machineryFinanceDesc: "From lease-to-own to bank financing, equipment ownership made easy. Get machinery financing with fast approvals today.",
  creditApplicationTitle: "Quick Heavy Machinery financing approvals and installments",
  creditApplicationDesc: "Apply for financing and own your heavy equipment with our line of credit, lease to own, and installment options and more",
  currencyNames: {
    USD: "USD",
    AED: "AED",
    SAR: "SAR",
    OMR: "OMR",
    EUR: "EUR",
    GBP: "GBP",
    JPY: "JPY",
  },
  uponReq: 'Upon request',
  "CIFA": "CIFA",
  "whatsappOfferText":"Can I learn more about your excavators offer?",
  "alMarwanTitle": "Al Marwan Heavy Machinery"
};
