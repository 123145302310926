

































































































































































































































































import {defineComponent, ref, watch, computed, onMounted, useContext} from '@nuxtjs/composition-api';
import type { PropType } from '@nuxtjs/composition-api';
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import {
  SfInput,
  SfButton,
  SfCheckbox,
  SfLoader,
} from '@storefront-ui/vue';
import { required, email } from 'vee-validate/dist/rules';
import { customerPasswordRegExp, invalidPasswordMsg } from '~/modules/customer/helpers/passwordValidation';
import { RegisterFormFields, FormName } from './types';
import Icon from '@/almarwan/components/Icon.vue';
import {eventBus} from "@/almarwan/helpers/EventBus";
import useUser from "~/modules/customer/composables/useUser";
import {useCustomerStore} from "~/modules/customer/stores/customer";
import {useUiState, useCart, useUiNotification} from "~/composables";
import VueCountryCode from "vue-country-code";
import userGetters from '~/modules/customer/getters/userGetters';
import {clickOutside} from "~/components/directives/click-outside/click-outside-directive";

extend('email', {
  ...email,
  message: 'Invalid email',
});

extend('required', {
  ...required,
  message: 'This field is required',
});

extend('terms', {
  ...required,
  message: "Read and agree to Terms of Services and Privacy Policy.",
});

extend('password', {
  message: invalidPasswordMsg,
  validate: (value: string) => customerPasswordRegExp.test(value),
});

export default defineComponent({
  directives: { clickOutside },
  components: {
    SfInput,
    SfButton,
    SfCheckbox,
    SfLoader,
    ValidationObserver,
    ValidationProvider,
    Icon,
    VueCountryCode
  },
  data(){
    return {
      activeInput: '',
      isHovered: '',
      terms: '',
      inputError: '',
      isValidPwd: false,
      showPassword: false
    }
  },
  methods: {
    setActive(item) {
        this.activeInput = item;
      // @ts-ignore: Unreachable code error
      item === 'email' ? this.$refs.formEmail.focus() : null
      // @ts-ignore: Unreachable code error
      item === 'password' ? this.$refs.formPassword.focus() : null
      // @ts-ignore: Unreachable code error
      item === 'registerName' ? this.$refs.registerName.focus() : null
      // @ts-ignore: Unreachable code error
      item === 'registerPhone' ? this.$refs.registerPhone.focus() : null
      // @ts-ignore: Unreachable code error
      item === 'registerCompany' ? this.$refs.registerCompany.focus() : null
    },
    handleClickOutside(event) {
        this.activeInput = '';
    },
    clearInput(item) {
      this.formCopy[item] = ''
      if (this.activeInput === item) {
        this.activeInput = '';
      }
    },

    termsFunction () {
      if(this.terms == 'true'){
        return true
      }
    },
    checkValidPassword () {
      this.isValidPwd = customerPasswordRegExp.test(this.formCopy.password);
    },
    handleKeyUp(inputField){
      if((inputField.target.name == 'name'  && this.activeInput == 'registerName') || (this.activeInput === inputField.target.name ) ){
        this.activeInput = '';
      }
    },
    handleHover(inputField){
      this.isHovered = inputField.target.name

    },
  },
  props: {
      form: {
        type: Object as PropType<RegisterFormFields>,
        required: true,
      },
      loading: {
        type: Boolean,
        default: false,
      },
      showRecaptcha: {
        type: Boolean,
        default: false,
      },
    },
  setup(props, { emit }) {
    const currentStep = ref(1);
    const { user, generateSocialCustomerToken, isAuthenticated, updateUser } = useUser()
    const { setCart } = useCart();
    const customerStore = useCustomerStore();
    const {app, $config, env} = useContext();
    const YOUR_GOOGLE_CLIENT_ID = env.GOOGLE_CLIENT_ID;
    const MICROSOFT_REDIRECT_URI = env.MICROSOFT_REDIRECT_URI;
    const MICROSOFT_CLIENT_ID =  env.MICROSOFT_CLIENT_ID;
    const { toggleLoginModal, setSocialLogin, socialLogin, productInCart, isLoginModalOpen } = useUiState();
    const {send: sendNotification} = useUiNotification();
    const formCopy = ref<RegisterFormFields>();
    const companySelected = ref(false);
    const getPhone = ref(false);
    const userPhone = ref(null);
    const userCompany = ref(null);
    const userCountryCode = ref('');
    const updateLoading = ref(false);
    let myMSALObj = null

    const userDetails = computed(() => {
      return {
        telephone: userGetters.getPhoneNumber(user.value)
      }
    });
    const changeForm = (form) => {
      emit('change-form', form);
    };
    const checkFields = () => {
      if(formCopy.value['email'].length == 0 || formCopy.value['firstName'].length == 0 || formCopy.value['password'].length == 0   ) {
        currentStep.value = 1
      }
      companySelected.value = true
    }

    const inputEmpty =  () => {
      if(formCopy.value['telephone'].length == 0 || formCopy.value['company_local'].length == 0 ) {
        return true
      }
    };

    const loginBtnClick = () => {
      // initialize Google Sign in
      // @ts-ignore: Unreachable code error
      google.accounts.id.initialize({
        client_id: YOUR_GOOGLE_CLIENT_ID,
        context: 'signin'
      })

      // @ts-ignore: Unreachable code error
      google.accounts.oauth2.initTokenClient({
        client_id: YOUR_GOOGLE_CLIENT_ID,
        scope: 'profile \
          email \
          openid',
        include_granted_scopes: true,
        callback: getCustomerLoginToken
      }).requestAccessToken()
    }

    // Config object to be passed to Msal on creation
    const msalConfig = {
      auth: {
        clientId: MICROSOFT_CLIENT_ID,
        authority: "https://login.microsoftonline.com/common",
        redirectUri: MICROSOFT_REDIRECT_URI,
      },
      cache: {
        cacheLocation: "sessionStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
      }
    };

      // Add scopes for the id token to be used at Microsoft identity platform endpoints.
      const loginRequest = {
          scopes: ["openid", "profile", "User.Read"],
      };

      const loginWithMicrosoft = async() => {
      await myMSALObj.loginPopup(loginRequest)
        .then((loginResponse) => {
          setTimeout(() => {
            getAccessToken()
          }, 1000);
      }).catch(function (error) {
          console.log(error);
      });
    }
    const getAccessToken = async() => {
      await myMSALObj.acquireTokenSilent(loginRequest)
        .then((loginResponse) => {
        //Login Success callback code here
        getCustomerLoginToken(loginResponse)
      }).catch(function (error) {
        myMSALObj.acquireTokenPopup(loginRequest).then(
          function (accessTokenResponse) {
          // Acquire token interactive success
          let accessToken = accessTokenResponse.accessToken;
          getCustomerLoginToken(accessTokenResponse)
        })
        .catch(function (error) {
          console.log(error);
        });
        console.log(error);
      });
    }

    const updateCustomerPhone = async() => {
      if (isAuthenticated.value) {
        updateLoading.value = true;
        const res = await updateUser({ user: { telephone: userCountryCode.value.toString()+userPhone.value.toString(), company_local: userCompany.value } });
        if(res.telephone !== null) {
          sendNotification({
            id: Symbol('user_updated'),
            message: 'Account data updated successfully!',
            type: 'success',
            icon: 'check',
            persist: false,
            title: 'User Account',
          });
        }
        setSocialLogin(false)
        updateLoading.value = false
        closeModal();
        toggleLoginModal()
      }
    }

    const closeModal = () => {
      let bodyElement = document.querySelector('body')
      if (bodyElement) bodyElement.classList.remove('has-overflow')
    };

    const getCustomerLoginToken = async (response) => {
      let socialAccessToken = '';
      let socialPlatfrom = '';
      if(response && response.access_token !== undefined) {
        socialAccessToken = response.access_token;
        socialPlatfrom = 'google'
      }
      else if(response && response.accessToken !== undefined) {
        socialAccessToken = response.accessToken;
        socialPlatfrom = 'live'
      }
      if (socialAccessToken !=='') {
        let appState = app.context.$vsf.$magento.config.state
        const data = await generateSocialCustomerToken(socialPlatfrom, socialAccessToken)
        // @ts-ignore: Unreachable code error
        if (data && data.generateSocialCustomerToken && data.generateSocialCustomerToken.token) {
          customerStore.setIsLoggedIn(true);
          setSocialLogin(true);
          // @ts-ignore: Unreachable code error
          appState.setCustomerToken(data.generateSocialCustomerToken.token);
          eventBus.$emit('updateUserInAppHeader')
          // merge existing cart with customer cart
          const currentCartId = appState.getCartId();
          const cart = await app.context.$vsf.$magento.api.customerCart();
          const newCartId = cart.data.customerCart.id;

          try {
            if (newCartId && currentCartId && currentCartId !== newCartId) {
              const { data: dataMergeCart } = await app.context.$vsf.$magento.api.mergeCarts(
                {
                  sourceCartId: currentCartId,
                  destinationCartId: newCartId,
                },
              );

              // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
              setCart(dataMergeCart.mergeCarts);

              appState.setCartId(dataMergeCart.mergeCarts.id);
            } else {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
              setCart(cart.data.customerCart);
            }
          } catch {
            setCart(cart.data.customerCart);
          }
        }
        if(!productInCart.value && userDetails.value?.telephone !== null) {
          setSocialLogin(false);
          emit('registerSuccess', true)
          if(isLoginModalOpen.value) {
            closeModal()
            setTimeout(()=>{
            toggleLoginModal();
          },3000)
        }
       }
      }
    }
    const onSelectCode = (event) => {
      formCopy.value.countryCode = event.dialCode;
    };
    const onSelectUserCode = (event) => {
      userCountryCode.value = event.dialCode;
    };

    onMounted(async () => {
      // @ts-ignore: Unreachable code error
      myMSALObj = new Msal.UserAgentApplication(msalConfig);
      if (userDetails.value.telephone == undefined || userDetails.value.telephone == null){
        getPhone.value = true;
        currentStep.value = 2;
      }
      eventBus.$on('registrationFailed', () => {
        currentStep.value = 1;
      })
    })

    watch(() => props.form, (newForm) => {
      formCopy.value = { ...newForm };
      }, {
      immediate: true, deep: true
    });
    watch(()=>userDetails.value, () => {
      if((userDetails.value.telephone == null || userDetails.value.telephone == undefined) && socialLogin.value) {
        getPhone.value = true;
        currentStep.value = 2;
      }
    });

    return {
      loginBtnClick,
      formCopy,
      changeForm,
      checkFields,
      currentStep,
      inputEmpty,
      companySelected,
      onSelectCode,
      loginWithMicrosoft,
      getPhone,
      updateCustomerPhone,
      userCountryCode,
      userPhone,
      userCompany,
      onSelectUserCode,
      updateLoading
    };
  },
});
