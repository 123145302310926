


















import { defineComponent } from '@nuxtjs/composition-api';
import Logo from '@/almarwan/components/Logo.vue'
import Icon from '@/almarwan/components/Icon.vue'

export default defineComponent({
  components: {
    Logo,
    Icon
  },
  props: {
    currRegisteredEmail: {
      type: String,
      default: 'your email',
    },
  },
});
