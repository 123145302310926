import { UseContextReturn } from '~/types/core';
import type { PlaceOrderOutput } from '~/modules/GraphQL/types';
import { ComposableFunctionArgs } from '~/composables';

export const placeOrderCommandV2 = {
  execute: async (context: UseContextReturn, cartId: string, params?: ComposableFunctionArgs<{}>): Promise<any | null> => {
    // @ts-ignore
    const { data, errors } = await context.app.$vsf.$magento.api.placeOrderV2({ cart_id: cartId }, params?.customQuery ?? null);
    return data?.placeOrderV2 ?? null;
  },
};
