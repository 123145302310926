import { SortEnum, ProductAttributeSortInput } from '~/modules/GraphQL/types';

export function createProductAttributeSortInput(sortData: string): ProductAttributeSortInput {
  let baseData = sortData.split(/_/gi);
  if (baseData.includes('PRICE')) {
    baseData.splice(0, 1, baseData[0].toLowerCase() );
    baseData.splice(1, 1, baseData[1] === 'DESC' ? SortEnum.Desc : SortEnum.Asc);
  } else if (baseData.includes('YEAR')) {
    baseData.splice(0, 1, baseData[0].toLowerCase());
    baseData.splice(1, 1, baseData[1] === 'DESC' ? SortEnum.Desc : SortEnum.Asc);
  } else if (baseData.includes('entity')) {
    baseData.splice(0, 1, baseData[0] + '_' + baseData[1]);
    baseData.splice(1, 1, baseData[2] === 'DESC' ? SortEnum.Desc : SortEnum.Asc);
  } else if (baseData.includes('POSITION')) {
    baseData.splice(0, 1, baseData[0].toLowerCase());
    baseData.splice(1, 1, baseData[2] === 'DESC' ? SortEnum.Desc : SortEnum.Asc);
  }
  return baseData.length > 0 ? Object.fromEntries([baseData]) : {};
}
