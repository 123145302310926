






import { computed, defineComponent, ref, toRef, useFetch } from '@nuxtjs/composition-api';

export default defineComponent({
  name:'ProductTag',
  props: {
    product: {
      type: Object,
      required: true
    },
    rentOrBuy: {
      type: String,
      required: true
    }
  },
  setup(props, { emit }) {
    const product = toRef(props, 'product');
    const rentOrBuy = toRef(props, 'rentOrBuy');
    const labelClass = ref('label--green');
    const labelTag = ref('');

    const availableFor = computed( () => product.value.listings);
    const availableForTags = computed(() => product && product.value.available_for_tags)
    const getTagLabel = () => {
      if(availableForTags.value.length) {
        let array = ['','label--purple','label--red','label--purple','label--red','label--green','label--green'];
        if (availableFor.value) {
          if (availableFor.value === '763,764' && availableForTags.value.length > 1) {
            if (rentOrBuy.value === 'buy') {
              labelClass.value = array[availableForTags.value[0]?.value]
              labelTag.value = availableForTags.value[0]?.label;
            } else {
              labelClass.value = array[availableForTags.value[1]?.value]
              labelTag.value = availableForTags.value[1]?.label;
            }
          }
          if (availableFor.value === '763' || availableFor.value === '764') {
            labelClass.value = array[availableForTags.value[0]?.value]
            labelTag.value = availableForTags.value[0]?.label
          }
        }
        emit('label-name', labelTag.value)
      }
    }
    useFetch(() => {
      getTagLabel()
    })

    return {
      labelTag,
      availableForTags,
      labelClass,
      getTagLabel
    }
  }
})
