























































import { computed, defineComponent, ref } from '@nuxtjs/composition-api';
import useUser from '~/modules/customer/composables/useUser';
import { eventBus } from '~/almarwan/helpers/EventBus';
import useWishlist from '~/modules/wishlist/composables/useWishlist';

export default defineComponent({
  name: 'WishlistGuest',
  props: {
    product: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const { isAuthenticated } = useUser();
    const wishListForGuestUser = ref([]);
    const isInGuestWishlist = ref(false);
    const { addOrRemoveItem, isInWishlist } = useWishlist();
    const productInGuestWishlist = (product) => {
      const productInGuestList = wishListForGuestUser.value.find((prod) => prod.id === product.id)
      isInGuestWishlist.value = !!productInGuestList;
    }
    const removeProductFromGuestWishlist = (prod) => {
      let storedNames = []
      if (localStorage.getItem("wishListForGuestUser")) {
        storedNames = JSON.parse(localStorage.getItem("wishListForGuestUser"));
      }
      wishListForGuestUser.value = wishListForGuestUser.value.filter((product) => product.id != prod.id)
      storedNames = storedNames.filter((product) => product.id != prod.id)
      localStorage.setItem("wishListForGuestUser", JSON.stringify(storedNames));
      productInGuestWishlist(prod)
      eventBus.$emit('updateWishlistInAppHeader')
    }
    const saveProduct = (product) => {
      if (!isAuthenticated.value) {
        let storedNames = []
        if (localStorage.getItem("wishListForGuestUser")) {
          storedNames = JSON.parse(localStorage.getItem("wishListForGuestUser"));
        }
        const checkIfItIsNotSameProduct = storedNames.find((item) => item.id === product.id)
        if (!checkIfItIsNotSameProduct) {
          storedNames.push(product)
          wishListForGuestUser.value = storedNames
          localStorage.setItem("wishListForGuestUser", JSON.stringify(storedNames));
          productInGuestWishlist(product)
          eventBus.$emit('updateWishlistInAppHeader')
        }
        return
      }
      addOrRemoveItem({ product });
    }
    // @ts-ignore
    const isInWishlistCheck = computed(() => isInWishlist({ product: props.product }))
    return {
      removeProductFromGuestWishlist,
      productInGuestWishlist,
      saveProduct,
      isInWishlistCheck,
      isAuthenticated,
      isInGuestWishlist
    }
  }
})
