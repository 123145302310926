




































































































































import {
  ref,
  defineComponent,
  computed,
  useContext,
  onMounted,
  useRoute,
  useRouter, watch, onUnmounted
} from '@nuxtjs/composition-api';
import {useUiState} from '~/composables/useUiState';
import {useCart} from '~/modules/checkout/composables/useCart';
import {useWishlist} from '~/modules/wishlist/composables/useWishlist';
import {useForgotPassword} from '~/modules/customer/composables/useForgotPassword';
import {useUser} from '~/modules/customer/composables/useUser';

import LoginForm from './forms/LoginForm.vue';
import RegisterForm from './forms/RegisterForm.vue';
import ForgotPasswordForm from './forms/ForgotPasswordForm.vue';
import ResetPasswordForm from './forms/ResetPasswordForm.vue';
import ForgotPasswordThankYou from './forms/ForgotPasswordThankYou.vue';
import RegisterUserThankyou from './forms/RegisterUserThankyou.vue';
import ResetPasswordThankyou from './forms/ResetPasswordThankyou.vue';
import {
  ForgotPasswordFormFields, LoginFormFields, RegisterFormFields, FormName, ResetPasswordFormFields
} from './forms/types';

import Logo from '@/almarwan/components/Logo.vue'
import Icon from '@/almarwan/components/Icon.vue'
import {eventBus} from '@/almarwan/helpers/EventBus'
import {boolean} from 'yargs';
import {useUiNotification} from '~/composables';
import userGetters from '~/modules/customer/getters/userGetters';
import {useCustomerStore} from '~/modules/customer/stores/customer';

export default defineComponent({
  name: 'LoginModal',
  components: {
    LoginForm,
    RegisterForm,
    ForgotPasswordForm,
    ForgotPasswordThankYou,
    RegisterUserThankyou,
    ResetPasswordForm,
    ResetPasswordThankyou,
    Logo,
    Icon,
  },
  data() {
    return {
      resetForm: false,
      isWaitOver: false
    }
  },
  mounted() {
    eventBus.$on('closeForgotPassword', (val) => {
      if (val) {
        this.currentTab = 'signIn';
      }
    });
    eventBus.$on('closeResetPassword', (val) => {
      if (val) {
        this.showResetForm = false;
        this.currentTab = 'signIn';
      }
    });
    if (this.$route.query.register) {
      if (!this.isAuthenticated && this.$route.query.register === 'show') {
        this.currentTab = 'register';
      }
    }
    this.currentTab = this.authTab
    setTimeout(() => {
      this.isWaitOver= true
    }, 100);
  },
  methods: {
    currentlyDisplayedForm(val) {
      this.currentTab = val
    } ,
  },
  setup: function (_, {emit}) {
    const {isLoginModalOpen,authTab, socialLogin, setSocialLogin, oneTapLogin} = useUiState();

    if (isLoginModalOpen.value) {
      let bodyElement = document.querySelector('body')
      bodyElement.classList.add('has-overflow')
    }

    const {
      user,
      register,
      login,
      loading,
      isAuthenticated,
      error: userError,
    } = useUser();

    const {load: loadCart} = useCart();
    const {loadItemsCount, addOrRemoveItem} = useWishlist();
    const {
      request: resetPassword,
      error: forgotPasswordError,
      loading: forgotPasswordLoading,
      setNew: setNewPassword,
      result: setPasswordResult
    } = useForgotPassword();
    const {send: sendNotification} = useUiNotification();
    const currentTab =  ref('signIn');
    const customerStore = useCustomerStore();
    const registerSuccess = ref(false);
    const errorMessage = ref('');
    const loginSuccess = ref(false);
    const resetSuccess = ref(false);
    const resetEmailSent = ref(false);
    const currRegisteredEmail = ref('');
    const route = useRoute();
    const router = useRouter();
    const pwdToken = ref('');
    const closeModal = () => {
      let bodyElement = document.querySelector('body')
      bodyElement.classList.remove('has-overflow')
      if (route.value.name === 'maintenance' || route.value.name === 'credit-application') {
        router.push({name: 'home'});
      }
      emit('close')
    };
    const userDetails = computed(() => {
      return {
        firstname: userGetters.getFirstName(user.value),
        telephone: userGetters.getPhoneNumber(user.value)
      }
    });
    const showResetForm = ref(false);
    const loginForm = ref<LoginFormFields>({
      email: '',
      password: '',
    });
    const registerForm = ref<RegisterFormFields>({
      email: '',
      firstName: '',
      // lastName: '',
      password: '',
      shouldSubscribeToNewsletter: true,
      isWillToCreateAccountConfirmed: true,
      countryCode: '',
      telephone: '',
      company_local: ''
    });
    const forgotPasswordForm = ref<ForgotPasswordFormFields>({
      username: '',
    });
    const resetPasswordForm = ref<ResetPasswordFormFields>({
      newpassword: '',
      confirmpassword: ''
    });

    // @ts-expect-error Recaptcha is not registered as a Nuxt module. Its absence is handled in the code
    const {$recaptcha, $config, app:{i18n}} = useContext();
    const sectiones = [
        {
          title: i18n.t("Explore"),
          paragraph:i18n.t("Browse our complete catalog and services."),
          image:'/images/global/Search-Line.svg',
        },
        {
          title: i18n.t("Buy"),
          paragraph:i18n.t("Shop equipment, attachments, and parts."),
          image: '/images/global/Card-Line.svg'
        },
        {
          title: i18n.t("Rent"),
          paragraph:i18n.t("Find rental equipment and manage operations."),
          image:'/images/global/Folder-Line(2)2.svg'
        },
        {
          title: i18n.t("Finance"),
          paragraph:i18n.t("Submit documents for pre-approval easily."),
          image:'/images/global/Cash-Line.svg'
        },
        {
          title: i18n.t("Make Offers"),
          paragraph:i18n.t("Set the price tag and begin negotiating."),
          image:'/images/global/Document-Found-Line.svg'
        },
        {
          title: i18n.t("Service"),
          paragraph:i18n.t("Apply for maintenance tickets on the go."),
          image:'/images/global/Phone-Line 2.svg'
        },
      ]
    const isRecaptchaEnabled = ref<boolean>(typeof $recaptcha !== 'undefined' && Boolean($config.isRecaptcha));

    const getRecaptchaInfo = async (isRecaptchaOn: boolean): Promise<{ token: string | null, cleanup: () => void }> => {
      if (isRecaptchaOn) {
        $recaptcha.init();
        return {
          token: await $recaptcha.getResponse(), cleanup: () => {
            $recaptcha.reset();
          }
        };
      }
      return {
        token: null, cleanup: () => {
        }
      };
    };

    const onLoginFormSubmit = async (form: LoginFormFields) => {
      loginForm.value = form;
      setSocialLogin(false);
      const {token, cleanup} = await getRecaptchaInfo(isRecaptchaEnabled.value);
      await login({user: {...form, ...(token ? {recaptchaToken: token} : {})}});
      cleanup();
      if (!userError.value.login && userDetails.value?.telephone !== null) {
        loginSuccess.value = true;
        setTimeout(() => {
          closeModal();
        }, 2000)
        await Promise.all([loadItemsCount(), loadCart()]);
        await addGuestWishlistToAccount()
        eventBus.$emit('userLoggedIn')
      }
      else {
        errorMessage.value  = userError.value.login.toString()
        setSocialLogin(true);
        currentTab.value = 'signIn';
      }
    };

    const addGuestWishlistToAccount = () => {
      let guestWishlist = []
      if (localStorage.getItem("wishListForGuestUser")) {
        guestWishlist = JSON.parse(localStorage.getItem("wishListForGuestUser"));
        guestWishlist.forEach(async (product) => {
          await addOrRemoveItem({product})
        })
        setTimeout(() => {
          localStorage.setItem("wishListForGuestUser", JSON.stringify([]));
        }, 2000)
      }
    }

    const onRegisterFormSubmit = async (form: RegisterFormFields) => {
      registerForm.value = form;
      setSocialLogin(false);
      currRegisteredEmail.value = form.email;
      const {token, cleanup} = await getRecaptchaInfo(isRecaptchaEnabled.value);
      if (form.countryCode.length == 2) {
        form.countryCode = '0' + form.countryCode;
      }
      await register({
        user: {
          email: form.email,
          firstname: form.firstName,
          // lastname: form.lastName,
          lastname: '',
          password: form.password,
          is_subscribed: form.shouldSubscribeToNewsletter,
          telephone: '+' + form.countryCode + form.telephone,
          company_local: form.company_local,

          ...(token ? {recaptchaToken: token} : {}),
        },
      });
      cleanup();
      if (!userError.value.register) {
        //eventBus.$emit('updateCustomerPicture', 'success')
        setTimeout(() => {
          closeModal();
        }, 3000)
        registerSuccess.value = true;
        customerStore.setFirstTimeUser(true);
        await Promise.all([loadItemsCount(), loadCart()]);
        await addGuestWishlistToAccount()
      } else {
        eventBus.$emit('registrationFailed')
      }
    };

    const onForgotPasswordFormSubmit = async (form: ForgotPasswordFormFields) => {
      localStorage.setItem('resetPasswordUser', form.username);
      forgotPasswordForm.value = form;
      const {token, cleanup} = await getRecaptchaInfo(isRecaptchaEnabled.value);
      await resetPassword({email: form.username, ...(token ? {recaptchaToken: token} : {})});
      cleanup();
      resetEmailSent.value = true;
      // currentlyDisplayedForm.value = 'forgotPasswordThankYou';
    };

    const onResetPasswordFormSubmit = async (form: ResetPasswordFormFields) => {
      let userName = localStorage.getItem('resetPasswordUser');
      let urltoken = pwdToken.value;//route.value.query.token ?? '';
      resetPasswordForm.value = form;
      const {token, cleanup} = await getRecaptchaInfo(isRecaptchaEnabled.value);
      const res = await setNewPassword({tokenValue: urltoken, newPassword: form.newpassword, email: userName});
      if (setPasswordResult.value.setNewPasswordResult) {
        resetSuccess.value = true;
        localStorage.removeItem('resetPasswordUser');
      } else {
        sendNotification({
          id: Symbol('product_removed'),
          message: 'Passsword reset failed, please try again',
          type: 'danger',
          icon: 'error',
          persist: false,
          title: 'Update failed!',
        });
        showResetForm.value = false;
        resetSuccess.value = false;
        localStorage.removeItem('resetPasswordUser');
      }
      cleanup();
    };
    const showSuccess = (val) => {
      isLogged.value = true
    }
    onMounted(async () => {
      if (route.value.query.token) {
        pwdToken.value = route.value.query.token.toString();
        showResetForm.value = true;
        router.replace({'query': null}).catch(error => {
          if (error.name !== 'NavigationDuplicated') {
            throw error
          }
        });
      }
      if(oneTapLogin.value) {
        currentTab.value = 'register';
      }
    });
    const localStorageValue = ref(localStorage.getItem("isAuth"));
    const inputValue = ref(localStorageValue.value);
    const  isLogged  = ref(false);
    const  loadingLog  = ref(true);
    const context = useContext();
    if (!context.app.$vsf.$magento.config.state.getCustomerToken()) {
      localStorage.removeItem('isAuth')
    }
    watch(()=>userDetails.value, () => {
      if(userDetails.value.telephone == undefined && socialLogin.value) {
        currentTab.value = 'register';
      }
    })
    watch(() => localStorageValue, (newVal) => {
      if(newVal && context.app.$vsf.$magento.config.state.getCustomerToken()){
        window.dispatchEvent(new Event("localStorageUpdated"));
      }
    }, {immediate: true, deep: true});

    const handleLocalStorageUpdate = () => {
      isLogged.value=true;
      setTimeout(() => {
        loadingLog.value=false;
        location.reload();
      }, 4000)
    };

    onMounted(() => {
      if(process.browser){
        //window.addEventListener("storage", handleLocalStorageUpdate);
        if(localStorage.getItem('isAuth') && isAuthenticated.value){
          isLogged.value=true;
          setTimeout(() => {
            loadingLog.value=false;
            location.reload();
          }, 2000)
        }
      }
    });

    onUnmounted(() => {
        window.removeEventListener("storage", handleLocalStorageUpdate);
    });

    return {
      isLoginModalOpen,
      userError,
      loading,
      forgotPasswordError,
      forgotPasswordLoading,
      loginForm,
      registerForm,
      forgotPasswordForm,
      resetPasswordForm,
      isRecaptchaEnabled,
      onRegisterFormSubmit,
      onLoginFormSubmit,
      onForgotPasswordFormSubmit,
      closeModal,
      registerSuccess,
      currRegisteredEmail,
      onResetPasswordFormSubmit,
      showResetForm,
      resetSuccess,
      resetEmailSent,
      userDetails,
      isAuthenticated,
      loginSuccess,
      errorMessage,
      localStorageValue,
      inputValue,
      isLogged,
      loadingLog,
      authTab,
      currentTab,
      socialLogin,
      showSuccess,
      oneTapLogin,
      sectiones
    };
  },
});
