import { loadFonts, fontsToLinks } from './utils/fontLoader'

import './font-face.css';

const fonts = [{"preload":true,"source":{"path":require('~/assets/fonts/MontRegular/font.woff2'),"format":"woff2"},"classes":["font_mont_regular","font_mont_regular_normal_normal"]},{"preload":true,"source":{"path":require('~/assets/fonts/MontHeavy/font.woff2'),"format":"woff2"},"classes":["font_mont_heavy","font_mont_heavy_normal_normal"]},{"preload":true,"source":{"path":require('~/assets/fonts/MontSemiBold/font.woff2'),"format":"woff2"},"classes":["font_mont_semi_bold","font_mont_semi_bold_normal_normal"]},{"preload":true,"source":{"path":require('~/assets/fonts/MontBold/font.woff2'),"format":"woff2"},"classes":["font_mont_bold","font_mont_bold_normal_normal"]}];

if (!process.server){
  loadFonts({"ignoredEffectiveTypes":["2g","slow-2g"],"ignoreLighthouse":false,"unlockDelay":0,"prefetchCount":2});
}

export default function ({ app }) {
  app.head.link.push(...fontsToLinks(fonts));
}
