




























































import { defineComponent, onBeforeUnmount, onMounted, ref, useContext, useRoute, useRouter } from '@nuxtjs/composition-api';
import { debounce } from 'lodash-es';
import Icon from '@/almarwan/components/Icon.vue';
import { ProductInterface, Products } from '~/modules/GraphQL/types';
import useProduct from '~/modules/catalog/product/composables/useProduct';
import { useUiState } from '~/composables';
import {
  createProductAttributeFilterInput
} from '~/modules/catalog/category/composables/useFacet/input/createProductAttributeFilterInput';
import { clickOutside } from '~/components/directives/click-outside/click-outside-directive';
import { eventBus } from '~/almarwan/helpers/EventBus';

export default defineComponent({
  name: 'Search',
  components: {
    Icon,
    SearchResults: () => import('~/components/Header/SearchBar/SearchResults.vue'),
  },
  directives: { clickOutside },
  setup(_, emit) {
    const { app, app: { i18n } } = useContext();
    const { getProductList } = useProduct();
    const { setsearchBarTerm } = useUiState();
    const route = useRoute();
    const router = useRouter();
    const productSearchResults = ref<ProductInterface[] | null>(null);
    const resultFromSearch = ref([]);
    const term = ref('');
    const activeTab = ref('buy');
    const searchCatUids = {
      buy: ['NDU3', 'NTE4'],
      rent: ['NDUw']
    }
    const isSearchOpen = ref(false);
    let isRedirected = false
    const minTermLen = 3;
    const itemsPerPage = 50;
    const placeHolder = i18n.t('Search by brands, model, ref. no...');
    const searchLoading = ref(false);

    const searchFilter = () => {
      if (activeTab.value === 'buy') {
        return { category_uid: searchCatUids.buy }
      }
      if (activeTab.value === 'rent') {
        return { category_uid: searchCatUids.rent }
      }
    }
    const rawHandleSearch = async (searchTerm: string) => {
      isRedirected = false
      term.value = searchTerm;
      if (term.value === '' || term.value.length < 3) {
        productSearchResults.value = null
      } else if (process.browser) {
        const screenWidth = window.innerWidth;
        if (screenWidth <= 767 && searchTerm === '') {
          isSearchOpen.value = false
          productSearchResults.value = [];
          setsearchBarTerm('')
        } else {
          if (term.value.length < minTermLen) return;
          searchLoading.value = true;
          const productList: Products = await getProductList({
            pageSize: itemsPerPage,
            search: term.value,
            search_call: true,
            filter: createProductAttributeFilterInput(searchFilter())
          }, { products: 'search-query' }) as unknown as Products;
          resultFromSearch.value = productList?.items
          productSearchResults.value = productList?.items
          if (!isRedirected) isSearchOpen.value = true
          setsearchBarTerm(searchTerm)
          searchLoading.value = false;
        }
      }
    };
    const updateDebouncedSearch = debounce(rawHandleSearch, 300);
    const handleKeydownEnter = (searchTerm: string) => {
      searchTerm = searchTerm.replace(/^\s+/, '');
      if (searchTerm === '' || searchTerm.length < 3) {
        productSearchResults.value = null
        isSearchOpen.value = false
      }
      updateDebouncedSearch.cancel();
      rawHandleSearch(searchTerm);
    };

    const setSearch = (term) => {
      rawHandleSearch(term);
    }
    const clearSearch = () => {
      term.value = ''
      productSearchResults.value = null
      isSearchOpen.value = false
    }
    const hideSearch = () => {
      if (isSearchOpen.value) {
        isSearchOpen.value = false
        productSearchResults.value = null
        if (document) {
          document.body.classList.remove('no-scroll');
        }
      }
    };
    const changeOption = (option) => {
      activeTab.value = option
      if (term.value) {
        handleKeydownEnter(term.value)
      }
    }
    const updateRouteForProductPage = (route) => {
      const currentRoute = route.split('/')
      delete currentRoute[1]
      delete currentRoute[2]
      return currentRoute
    }
    const categorySearchUrl = async () => {
      const currentRoute = await updateRouteForProductPage(route.value.path)
      return app.localePath(`${currentRoute[0]}/c/search?term=${term.value}&tag=${activeTab.value}`);
    }
    const search = (from = false) => {
      if (term.value !== '') {
        if (route.value.name === 'product') {
          router.push({ path: `${ categorySearchUrl()}` })
        } else {
          isRedirected = true;
          router.push({ path: app.localePath(`/c/search?term=${term.value}&tag=${activeTab.value}`) })
        }
        if (from) {
          isSearchOpen.value = false
        }
      }
    }
    const mobSearchClick = () => {
      if (!isSearchOpen.value) {
        isSearchOpen.value = true
      } else if (term.value !== '') {
        search();
        isSearchOpen.value = false;
      } else {
        isSearchOpen.value = false
      }
    }
    const checkIfWeHaveResult = () => {
      if (productSearchResults.value !== null && term.value !== '') {
        isSearchOpen.value = true
      }
    }
    const handleClickOutside = () => {
      isSearchOpen.value = false
    }
    onMounted(()=> {
      eventBus.$on('clearSearchResults', () => {
        clearSearch();
      })
    })
    onBeforeUnmount(() => {
      eventBus.$off('clearSearchResults')
    })

    return {
      handleKeydownEnter,
      checkIfWeHaveResult,
      handleClickOutside,
      changeOption,
      clearSearch,
      hideSearch,
      setSearch,
      search,
      productSearchResults,
      isSearchOpen,
      placeHolder,
      activeTab,
      term,
      mobSearchClick,
      searchLoading
    }
  }
})
