














import { defineComponent, PropType } from '@nuxtjs/composition-api';

export default defineComponent({
  /**
   * Our only custom link.
   * @displayName Custom Link
   */
  name: 'CustomLink',
  props: {
    /**
     * The Html tag that will wrap the content
     */
    tag: {
      type: [String, Object] as PropType<string | { [key: string]: any }>,
      default: 'a',
    },
    /**
     * The variant of the link
     * @values primary, secondary
     */
    variant: {
      type: String as () => 'primary' | 'secondary',
      default: 'primary',
    },
  },
  computed: {
    variantClasses() {
      return {
        primary:
          'text-neutral-500 hover:text-primary-500 hover:underline active:text-primary-900',
        secondary:
          'text-neutral-500 hover:text-dark-900 active:text-primary-500',
      };
    },
  },
});
