import { readonly, ref, useContext } from '@nuxtjs/composition-api';
import { Logger } from '~/helpers/logger';
import type {
  UseUserQuotationErrors,
  UseUserQuoteErrors,
  UseUserOrderErrors,
  UseUserErrorPayOrder,
  UseUserOrderInterface,
  UseUserOrderSearchParams,
} from './useUserOrder';

/**
 * Allows fetching customer orders.
 *
 * See the {@link UseUserOrderInterface} for a list of methods and values available in this composable.
 */
export function useUserOrder(): UseUserOrderInterface {
  const { app } = useContext();
  const loading = ref(false);
  const loadingQuote = ref(false);
  const loadingSingleQuote = ref(false);
  const errorQuotation = ref<UseUserQuotationErrors>({
    quote: null,
  });
  const errorSingleQuote = ref<UseUserQuoteErrors>({
    quote: null,
  });
  const error = ref<UseUserOrderErrors>({
    search: null,
  });
  const errorPayOrder = ref<UseUserErrorPayOrder>({
    order: null,
  });

  const search = async (params: UseUserOrderSearchParams) => {
    let results = null;

    try {
      loading.value = true;

      Logger.debug('[Magento] search user orders', { params });

      const { data } = await app.$vsf.$magento.api.customerOrders(params, params?.customQuery ?? null);

      Logger.debug('[Result]:', { data });

      results = data?.customer?.orders ?? null;
      error.value.search = null;
    } catch (err) {
      error.value.search = err;
      Logger.error('useRelatedProducts/search', err);
    } finally {
      loading.value = false;
    }

    return results;
  };

  const customerQuotes = async (id = null, currentPage:any) :Promise<Array<any[]>> => {
    let results = null;
    try {
      loadingQuote.value = true;

      Logger.debug('[Magento] get user quotes');

      // @ts-ignore
      const { data, errors } = await app.$vsf.$magento.api.quotations({id, pageSize:5, currentPage});

      Logger.debug('[Result]:', { data });

      results = data?.quotations ?? [];
      // @ts-ignore
      errorQuotation.value.quote = errors ?? null;
    } catch (err) {
      errorQuotation.value.quote = err;
      Logger.error('quotations ', err);
    } finally {
      loadingQuote.value = false;
    }

    return results;
  };
  const singleQuote = async (id) :Promise<Array<any[]>> => {
    let results = null;

    try {
      loadingSingleQuote.value = true;

      Logger.debug('[Magento] get user quotes');

      // @ts-ignore
      const { data } = await app.$vsf.$magento.api.quoteDetails({id});

      Logger.debug('[Result]:', { data });

      results = data?.quotations ?? [];
      errorSingleQuote.value.quote = null;
    } catch (err) {
      errorSingleQuote.value.quote = err;
      Logger.error('quotations ', err);
    } finally {
      loadingSingleQuote.value = false;
    }

    return results;
  };

  const quotationPDF = async (id) :Promise<Array<any[]>> => {
    let results = null;

    try {
      loadingSingleQuote.value = true;

      Logger.debug('[Magento] get user quotes');

      // @ts-ignore
      const { data } = await app.$vsf.$magento.api.quotationPDF({id});

      Logger.debug('[Result]:', { data });

      results = data?.quotations ?? [];
      errorSingleQuote.value.quote = null;
    } catch (err) {
      errorSingleQuote.value.quote = err;
      Logger.error('quotations ', err);
    } finally {
      loadingSingleQuote.value = false;
    }

    return results;
  };

  const payForOrder = async (id) :Promise<Array<any[]>> => {
    let results = null;

    try {
      loadingSingleQuote.value = true;

      Logger.debug('[Magento] get user quotes');

      // @ts-ignore
      const { data, errors } = await app.$vsf.$magento.api.payForOrder({id});

      Logger.debug('[Result]:', { data });

      results = data?.quotations ?? [];
      if(errors) {
        errorPayOrder.value.order = errors;
      }
    } catch (err) {
      errorPayOrder.value.order = err;
      Logger.error('pay order error ', err);
    } finally {
      loadingSingleQuote.value = false;
    }

    return results;
  };

  const setPaymentMethodOnOrder = async(id:number, methodName: string):Promise<Array<any[]>> => {
    let results = null;
    try {

      Logger.debug('[Magento] set order payment method');

      // @ts-ignore
      const { data, errors } = await app.$vsf.$magento.api.setPaymentMethodOnOrder({order_id: id, payment_method:{code: methodName}});

      Logger.debug('[Result]:', { data });

      results = data?? [];
    } catch (err) {
      Logger.error('setPaymentMethodOnOrder error ', err);
    } finally {
    }
    return results
  }

  return {
    payForOrder,
    setPaymentMethodOnOrder,
    search,
    customerQuotes,
    singleQuote,
    quotationPDF,
    loadingSingleQuote: readonly(loadingSingleQuote),
    loadingQuote: readonly(loadingQuote),
    loading: readonly(loading),
    error: readonly(error),
    errorPayOrder: readonly(errorPayOrder),
    errorSingleQuote: readonly(errorSingleQuote),
    errorQuotation: errorQuotation
  };
}

export default useUserOrder;
export * from './useUserOrder';
