










































import {
  defineComponent
} from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'Logos',
  props: {
    isMainMenuSelected: {
      type: Number,
      default: 457
    },
    rentOrBuy: {
      type: String,
      default: 'buy'
    }
  }
})
