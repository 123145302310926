



































































import { computed, defineComponent, onMounted, ref, useContext } from '@nuxtjs/composition-api';
import SkeletonLoader from '~/components/SkeletonLoader/index.vue';
import Icon from '~/almarwan/components/Icon.vue';
import { AvailableStores, useStore, useCurrency, useMagentoConfiguration } from '~/composables';
import { clickOutside } from '~/components/directives/click-outside/click-outside-directive';

export default defineComponent({
  name: 'Locale',
  components: { Icon, SkeletonLoader },
  directives: { clickOutside },
  setup() {
    const { stores, change: changeStore, load: loadStores } = useStore();
    const { currency: currencies, change: changeCurrency, load: loadCurrencies } = useCurrency();
    const {
      selectedCurrency,
      selectedLocale,
    } = useMagentoConfiguration();
    const { app: { i18n, localePath } } = useContext();
    const currentTab = ref('language');
    const showLocalePopup = ref(false);
    const showPopUpSign = ref(true);
    const availableStores = computed<AvailableStores>(() => stores.value ?? []);
    const availableCurrencies = computed<string[]>(() => currencies.value?.available_currency_codes || []);
    const showLangPopup = () => {
      showLocalePopup.value = !showLocalePopup.value;
    }
    const handleClickOutside = () => {
      showPopUpSign.value = false;
      showLocalePopup.value = false;
    }
    const switchLocale = (locale) => {
      showLocalePopup.value = false;
      changeStore(locale);
    }
    const switchCurrency = (currencyId) => {
      showLocalePopup.value = false;
      changeCurrency({id: currencyId})
    }
    const getStoreName = (locStore) => (locStore.store_code === 'default' ? 'English' : locStore.store_name);
    onMounted(() => {
      if (stores.value && stores.value?.length) 
        return;
      loadStores();
      if (currencies.value && currencies.value?.available_currency_codes) 
        return;
      loadCurrencies();
    })
    return {
      handleClickOutside,
      showLangPopup,
      getStoreName,
      switchLocale,
      showLocalePopup,
      availableStores,
      currentTab,
      changeCurrency,
      availableCurrencies,
      selectedCurrency,
      switchCurrency
    }
  }
})
