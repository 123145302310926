import { useContext } from '@nuxtjs/composition-api';

const formatCurrency = (value: number | string, locale: string, options: Intl.NumberFormatOptions): string => {
const { app } = useContext();

  if (typeof value === 'string') {
    value = Number(value);
  }

  const formattedValue = new Intl.NumberFormat(locale, { 
    style: 'currency', 
    currencyDisplay: 'code', 
    minimumFractionDigits: 0,
    maximumFractionDigits: 0, 
    ...options 
  }).format(value);

  if (app.i18n.locale === 'ar') {
    const currencyCode = options.currency;
    const currencyNameInArabic = app.i18n.t(`currencyNames.${currencyCode}`) || currencyCode;
    return formattedValue.replace(currencyCode, currencyNameInArabic.toString());
  }

  return formattedValue;
};

export default formatCurrency;
