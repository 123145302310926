




















































import {
  computed,
  defineComponent,
  onBeforeUnmount,
  onMounted,
  ref,
  useContext,
  useRoute
} from '@nuxtjs/composition-api';
import Logo from '~/components/Header/Logo.vue';
import Navigation from '~/components/Header/Navigation.vue';
import Search from '~/components/Header/Search.vue';
import NavWishlist from '~/components/Header/NavWishlist.vue';
import Cart from '~/components/Header/Cart.vue';
import User from '~/components/Header/User.vue';
import MenuBar from '~/components/Header/MenuBar.vue';
import { useCart, useUiNotification, useUiState, useWishlist, useUiHelpers } from '~/composables';
import useUser from '~/modules/customer/composables/useUser';
import { eventBus } from '~/almarwan/helpers/EventBus';
import { useCustomerStore } from '~/modules/customer/stores/customer';
import userGetters from '~/modules/customer/getters/userGetters';
import { useCartStore } from "~/modules/checkout/stores/cart";

export default defineComponent({
  name: 'AppHeader',
  components: {
    MenuBar,
    User,
    Cart,
    NavWishlist,
    Search,
    Navigation,
    Logo
  },
  setup() {
    const { loadItemsCount: loadWishlistItemsCount, addOrRemoveItem } = useWishlist();
    const { load, user, isAuthenticated, confirmCustomerAccount, generateSocialCustomerToken } = useUser();
    const { setSocialLogin, productInCart, isLoginModalOpen, setOneTapLogin, toggleLoginModal,
      toggleMailingListModal, trackAnalytics } = useUiState();
    const { send: sendNotification } = useUiNotification();
    const { cart, load: cartLoad, setCart } = useCart();
    const customerStore = useCustomerStore();
    const { app, env } = useContext();
    const { getFullUrl } = useUiHelpers()
    const route = useRoute();
    const userLoggedIn = ref(0)
    const YOUR_GOOGLE_CLIENT_ID = env.GOOGLE_CLIENT_ID;
    const cartActiveStep = ref(0)
    const isPayBalanceCart = ref(false);
    const store = useCartStore();
    const { $patch } = useCartStore();
    const showPopUp = ref(false);
    const userPhoneNumber = computed(() => userGetters.getPhoneNumber(user.value))
    const addGuestWishlistToAccount = () => {
      let guestWishlists = []
      if (localStorage.getItem('wishListForGuestUser')) {
        guestWishlists = JSON.parse(localStorage.getItem('wishListForGuestUser'));
        guestWishlists.forEach(async (product) => {
          await addOrRemoveItem({ product })
        })
        setTimeout(() => {
          localStorage.setItem('wishListForGuestUser', JSON.stringify([]));
        }, 2000)
      }
    }
    const getCustomerLoginToken = async (response) => {
      let socialAccessToken = '';
      let socialPlatfrom = '';
      if (response && response.access_token !== undefined) {
        socialAccessToken = response.access_token;
        socialPlatfrom = 'google'
      }
      if (socialAccessToken !== '') {
        const appState = app.context.$vsf.$magento.config.state
        const data = await generateSocialCustomerToken(socialPlatfrom, socialAccessToken)
        // @ts-gnore: Unreachable code error
        if (data && data.generateSocialCustomerToken && data.generateSocialCustomerToken.token) {
          customerStore.setIsLoggedIn(true);
          setSocialLogin(true);
          const randomString = [...Array(10)].map(() => String.fromCharCode(97 + Math.floor(Math.random() * 26))).join('');
          localStorage.setItem('isAuth',randomString)
          // @ts-ignore: Unreachable code error
          appState.setCustomerToken(data.generateSocialCustomerToken.token);
          eventBus.$emit('updateUserInAppHeader')
          // merge existing cart with customer cart
          const currentCartId = appState.getCartId();
          const cart = await app.context.$vsf.$magento.api.customerCart();
          const newCartId = cart.data.customerCart.id;

          try {
            if (newCartId && currentCartId && currentCartId !== newCartId) {
              const { data: dataMergeCart } = await app.context.$vsf.$magento.api.mergeCarts(
                {
                  sourceCartId: currentCartId,
                  destinationCartId: newCartId,
                },
              );

              // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
              setCart(dataMergeCart.mergeCarts);

              appState.setCartId(dataMergeCart.mergeCarts.id);
            } else {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
              setCart(cart.data.customerCart);
            }
          } catch {
            setCart(cart.data.customerCart);
          }

          await Promise.all([loadWishlistItemsCount(), cartLoad()]);
          await addGuestWishlistToAccount()
        }
        if (!productInCart.value && userPhoneNumber !== null) {
          setSocialLogin(false);
          sendNotification({
            id: Symbol('id'),
            message: 'Login successfull.',
            type: 'success',
            icon: 'check',
            persist: false,
            title: 'Login Success',
          });
        } else if (!isLoginModalOpen.value) {
          setOneTapLogin(true)
          setTimeout(() => {
            if (!isLoginModalOpen.value) {
              toggleLoginModal();
            }
          }, 1000)
        }
      }
    }
    const oauthSignIn = (googleId) => {
      // @ts-ignore: Unreachable code error
      const client = google.accounts.oauth2.initTokenClient({
        client_id: YOUR_GOOGLE_CLIENT_ID,
        scope: 'profile \
        email \
        openid',
        include_granted_scopes: true,
        hint: googleId,
        prompt: '',
        callback: getCustomerLoginToken
      }).requestAccessToken()
    }
    const parseJwt = (token) => {
      const base64Url = token.split('.')[1];
      const base64 = base64Url?.replace(/-/g, '+')?.replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(atob(base64).split('').map( (c) => {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));
      return JSON.parse(jsonPayload);
    }
    const showInvalidEmailToast = () => {
      sendNotification({
        id: Symbol('id'),
        message: 'Your email is not verified, please try again.',
        type: 'warning',
        icon: 'error',
        persist: false,
        title: 'Email Confirmation',
      });
    }
    const handleCredentialResponse = (response) => {
      const responsePayload = parseJwt(response.credential);
      if (!responsePayload.email_verified) {
        showInvalidEmailToast();
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        initializeGoogleAccountsId();
      } else {
        oauthSignIn(responsePayload.email);
      }
      getCustomerLoginToken(response)
    }
    const initializeGoogleAccountsId = () => {
      if (window) {
        // @ts-ignore: Unreachable code error
        window.google?.accounts.id.initialize({
          client_id: YOUR_GOOGLE_CLIENT_ID,
          callback: handleCredentialResponse
        });
        // @ts-ignore: Unreachable code error
        window.google?.accounts.id.prompt();
      }
    }
    const updateUser = (async () => {
      await load();
    });
    onMounted(async () => {
      await load();
      await cartLoad();
      if (!isAuthenticated.value && localStorage.getItem('cookie-preferences')!==null) {
        initializeGoogleAccountsId();
      }
      if (route.value.query?.token) {
        toggleLoginModal()
      }
      if (route.value.query?.register && !isAuthenticated.value && route.value.query?.register === 'show') {
        toggleLoginModal()
      }
      if (route.value.query?.subscribe && !isAuthenticated.value && route.value.query?.subscribe === 'show') {
        toggleMailingListModal()
      }
      if (route.value.query?.key) {
        const confirmUser = await confirmCustomerAccount({ key: route.value.query?.key, id: Number(route.value.query?.id) })
        if (confirmUser && confirmUser?.confirmCustomerAccount) {
          toggleLoginModal();
          sendNotification({
            id: Symbol('id'),
            message: 'Your account has been activated successfully. You can now login.',
            type: 'success',
            icon: 'check',
            persist: false,
            title: 'Email Confirmation',
          });
        } else {
          sendNotification({
            id: Symbol('product_removed'),
            message: String(confirmUser),
            type: 'danger',
            icon: 'error',
            persist: false,
            title: 'Email Confirmation',
          });
        }
      }
      if (route.value.query?.quote && !isAuthenticated.value) {
        toggleLoginModal();
      }
      eventBus.$on('cookie-accepted',() => {
        if (!isAuthenticated.value) {
          initializeGoogleAccountsId();
        }
      })
      eventBus.$on('updateUserInAppHeader', () => {
        updateUser();
      })
    })
    onBeforeUnmount(() => {
      eventBus.$off('cookie-accepted');
      eventBus.$off('updateUserInAppHeader')
    })
    const toggleNav = () => {
      eventBus.$emit('toggleNav')
    }
    const cartTotalItems = computed(() => cart.value?.total_quantity ?? 0)

    store.$subscribe(async (mutation, state) => {
      if(state.activeStep > 0) {
        cartActiveStep.value = state.activeStep
      }
      else{
        cartActiveStep.value = 0
      }
      if(state.payBalanceCart) {
        isPayBalanceCart.value = true;
      }
      else {
        isPayBalanceCart.value = false;
      }
    });
    const getRightText = () => {
      if(cart.value?.total_quantity > 1) {
        return 'Items'
      }
      else {
        return 'Item'
      }
    }
    const goToCartPage = () => {
      $patch((state)=>{
        state.activeStep = 0
      })
    }
    return {
      cartTotalItems,
      userLoggedIn,
      route,
      toggleNav,
      user,
      cartActiveStep,
      isPayBalanceCart,
      getRightText,
      showPopUp,
      goToCartPage,
      isAuthenticated,
      trackAnalytics,
      getFullUrl,
    }
  },
  head() {
    let arUrl:string = this.getFullUrl('/ar')
    return {
      meta: [
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:site', content: '@almarwanheavy' }
      ],
      script: [
        {
          type: 'application/ld+json',
          json: {
            "@context": "https://schema.org/",
            "@graph": [
              { "@context": "https://schema.org/", "@type": "SiteNavigationElement", "@id": this.$i18n.locale !== 'ar'? "https://almarwan.com#main-nav menu-for-pages": "https://almarwan.com/ar#main-nav menu-for-pages", "name": `${this.$t('Home')}`, "url": this.$i18n.locale !== 'ar' ? "https://almarwan.com" : "https://almarwan.com/ar" },
              { "@context": "https://schema.org/", "@type": "SiteNavigationElement", "@id": this.$i18n.locale !== 'ar'? "https://almarwan.com#main-nav menu-for-pages": "https://almarwan.com/ar#main-nav menu-for-pages", "name": `${this.$t('About')}`, "url": this.$i18n.locale !== 'ar' ? "https://almarwan.com/about" : "https://almarwan.com/ar/about" },
              { "@context": "https://schema.org/", "@type": "SiteNavigationElement", "@id": this.$i18n.locale !== 'ar'? "https://almarwan.com#main-nav menu-for-pages": "https://almarwan.com/ar#main-nav menu-for-pages", "name": `${this.$t('Services')}`, "url": this.$i18n.locale !== 'ar' ? "https://almarwan.com/services" : "https://almarwan.com/ar/services" },
              { "@context": "https://schema.org/", "@type": "SiteNavigationElement", "@id": this.$i18n.locale !== 'ar'? "https://almarwan.com#main-nav menu-for-pages": "https://almarwan.com/ar#main-nav menu-for-pages", "name": `${this.$t('FAQ')}`, "url": this.$i18n.locale !== 'ar' ? "https://almarwan.com/faq" : "https://almarwan.com/ar/faq" },
              { "@context": "https://schema.org/", "@type": "SiteNavigationElement", "@id": this.$i18n.locale !== 'ar'? "https://almarwan.com#main-nav menu-for-pages": "https://almarwan.com/ar#main-nav menu-for-pages", "name": `${this.$t('News')}`, "url": this.$i18n.locale !== 'ar' ? "https://almarwan.com/news" : "https://almarwan.com/ar/news" },
              { "@context": "https://schema.org/", "@type": "SiteNavigationElement", "@id": this.$i18n.locale !== 'ar'? "https://almarwan.com#main-nav menu-for-pages": "https://almarwan.com/ar#main-nav menu-for-pages", "name": `${this.$t('Agencies')}`, "url": this.$i18n.locale !== 'ar' ? "https://almarwan.com/dealers" : "https://almarwan.com/ar/dealers" },
              { "@context": "https://schema.org/", "@type": "SiteNavigationElement", "@id": this.$i18n.locale !== 'ar'? "https://almarwan.com#main-nav menu-for-pages": "https://almarwan.com/ar#main-nav menu-for-pages", "name": `${this.$t('Contact Us')}`, "url":this.$i18n.locale !== 'ar' ? "https://almarwan.com/contact-us" : "https://almarwan.com/ar/contact-us" }
            ],
          }
        }
      ],
      link: [
        {
          rel: 'alternate',
          hreflang: 'en-AE',
          href: `${process.env.BASE_URL}`
        }, {
          rel: 'alternate',
          hreflang: 'ar-AE',
          href: arUrl
        }, {
          rel: 'alternate',
          hreflang: 'en-OM',
          href: `${process.env.BASE_URL}`
        }, {
          rel: 'alternate',
          hreflang: 'ar-OM',
          href: arUrl
        }, {
          rel: 'alternate',
          hreflang: 'ar-SA',
          href: arUrl
        },
        {
          rel: 'alternate',
          hreflang: 'en',
          href: `${process.env.BASE_URL}`
        }, {
          rel: 'alternate',
          hreflang: 'ar',
          href: arUrl
        }
      ]
    }
  },
});
