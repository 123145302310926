








import {
  defineComponent, ref, onMounted, useRoute, computed,
} from '@nuxtjs/composition-api';
import useRelatedProducts from '~/modules/catalog/product/composables/useRelatedProducts';
import ProductsCarousel from '~/modules/catalog/product/components/ProductsCarousel.vue';
import {useUiState} from '~/composables';

export default defineComponent({
  name: 'RelatedProducts',
  components: {
    ProductsCarousel,
  },
  props: {
    prodCategory: {
      type: Array,
      default: () => []
    },
    rentOrBuyVal: {
      type: String,
      default: ''
    },
    skuList: {
      type: Array,
      default:() => []
    }
  },
  watch: {
    skuList() {
      if(this.skuList.length > 0) {
        this.setRelatedProducts()
      }
    }
  },
  setup(props) {
    const route = useRoute();
    const { params: { id } } = route.value;
    const { skuForSimilarItem } = useUiState();
    const getTitle = ref('Recommended Products');
    const techSpecArray = [
      {
        value: 'hours',
        label: 'Working Hours',
      },
      {
        value: 'filter_milage',
        label: 'Milage',
      },
      {
        value: 'power',
        label: 'Power Type',
      },
      {
        value: 'lf_max_reach',
        label: 'Max Reach',
      },
      {
        value: 'lf_platform_cap',
        label: 'Platform Capacity',
      },
      {
        value: 'lf_max_operator_no',
        label: 'Max Number of Operators',
      },
      {
        value: 'machine_weight',
        label: 'Operating Weight',
      },
      {
        value: 'engine_power',
        label: 'Engine',
      },
      {
        value: 'as_ext_max_lay_width',
        label: 'Max Extending Length Width',
      },
      {
        value: 'crc_boom_lift_cap',
        label: 'Lifting Capacity',
      },
      {
        value: 'ex_bucket_cap',
        label: 'Bucket Capacity',
      },
      {
        value: 'fk_load_cap',
        label: 'Loading Capacity',
      },
      {
        value: 'gs_rated_power',
        label: 'Rated Power',
      },
      {
        value: 'ml_milling_width',
        label: 'Milling Width',
      },
      {
        value: 'mt_designation',
        label: 'Designation',
      },
      {
        value: 'mt_mix_cap',
        label: 'Capacity',
      },
      {
        value: 'ro_comp_width',
        label: 'Compaction Width',
      },
      {
        value: 'ro_frequency',
        label: 'Compaction Frequency',
      },
      {
        value: 'tr_trench_depth',
        label: 'Trench Depth',
      },
      {
        value: 'wl_bucket_cap',
        label: 'Bucket Capacity',
      },
      {
        value: 'tt_blade_cap',
        label: 'Blade Capacity',
      },
      {
        value: 'mhl_operating_cap',
        label: 'Max Operating Cap',
      },
      {
        value: 'engine_make',
        label: 'Engine Make',
      },
      {
        value: 'mg_blade_width',
        label: 'Blade Width',
      },
      {
        value: 'adw_cap',
        label: 'Capacity',
      },
      {
        value: 'cr_hopper_load_vol',
        label: 'Hopper Cap',
      },
      {
        value: 'pc_max_give_height',
        label: 'Max Give Reach',
      },
      {
        value: 'jk_operating_press',
        label: 'Pressure',
      },
      {
        value: 'jk_carrier_machine_tonnage',
        label: 'Carrier Range',
      },
      {
        value: 'jk_oil_flow_rate',
        label: 'Oil Flow',
      },
      {
        value: 'crb_press',
        label: 'Pressure',
      },
      {
        value: 'crb_carry_range',
        label: 'Carrier Range',
      },
      {
        value: 'crb_crush_cap',
        label: 'Crusher Capacity',
      },
      {
        value: 'vh_carry_range',
        label: 'Carrier Range',
      },
      {
        value: 'vh_stat_movement',
        label: 'Static Movement',
      },
      {
        value: 'vh_dynamic_mass',
        label: 'Dynamic Mass',
      },
      {
        value: 'certified_operator',
        label: 'Certified Operator',
      },
      {
        value: 'uses',
        label: 'Uses',
      },
      {
        value: 'boom_length',
        label: 'Boom Length'
      },
      {
        value: 'boom_sections',
        label: 'Boom Sections'
      },
      {
        value: 'chassis',
        label: 'Chassis'
      },
      {
        value: 'water_tank_capacity',
        label: 'Water Tank Capacity',
      },
      {
        value: 'drum_driven_by',
        label: 'Drum Driven By',
      },
      {
        value: 'axles_truck',
        label: 'Axles Truck',
      },
    ];
    const {
      search,
      loading,
    } = useRelatedProducts();
    const products = ref([]);

    const setRelatedProducts = async() => {
      if(route.value.name === 'product') {
        const baseSearchQuery = {
          filter: {
            sku: { eq: id },
          },
        };
        // @ts-ignore: Unreachable code error
        products.value = await search(baseSearchQuery);
      }
      else {
        if(skuForSimilarItem.value !== '') {
          getTitle.value = '';
          const baseSearchQuery = {
            filter: {
              sku: { eq: skuForSimilarItem.value?.split('|')?.[0] },
            },
          };
          // @ts-ignore: Unreachable code error
          products.value = await search(baseSearchQuery);
        }
        else {
          const baseSearchQueryList = {
            filter: {
              sku: { in: id },
            },
          };
          // @ts-ignore: Unreachable code error
          products.value = await search(baseSearchQueryList);
        }
      }

      const productsFormatted = computed(() => products.value?.map((product) => {
        let techSpec = []
        for (const el of techSpecArray) {
          if(product[el.value]) {
            techSpec.push(el)
          }
        }
        return {
          ...product,
          techSpec: techSpec,
        };
      }));

      products.value = productsFormatted.value
    }

    onMounted(async () => {
      getTitle.value = props.prodCategory.length && props.prodCategory[0]['name']?.includes('Attachment') ? 'Compatible Products' : 'Recommended Products';
      await setRelatedProducts();
    });

    return {
      products,
      loading,
      getTitle,
      setRelatedProducts
    };
  },
});
