<template>
  <div>
    <div class="compare-products" v-if="isCompareProductModalOpen">
      <template v-for="(product, index) in compareProductsData">
        <div class="compare-products__box" :key="index">
          <button class="close" @click="removeProductFromCompare(product)">
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M11.1599 10.6168C11.1995 10.6493 11.2285 10.6928 11.2433 10.7418C11.2556 10.7882 11.2556 10.837 11.2433 10.8834C11.2556 10.9327 11.2556 10.9842 11.2433 11.0334C11.2285 11.0824 11.1995 11.1259 11.1599 11.1584C11.0915 11.2333 10.9946 11.2757 10.8933 11.2751C10.7894 11.276 10.6898 11.2337 10.6183 11.1584L6.00159 6.54176L1.38492 11.1584C1.35243 11.198 1.30892 11.227 1.25992 11.2418C1.21351 11.2541 1.16467 11.2541 1.11826 11.2418C1.01418 11.2437 0.914207 11.2013 0.843256 11.1251C0.767959 11.0535 0.725713 10.954 0.726575 10.8501C0.725999 10.7487 0.76839 10.6518 0.843256 10.5834L5.45159 6.0001L0.843256 1.38343C0.767959 1.31188 0.725713 1.2123 0.726575 1.10843C0.727621 1.00495 0.769564 0.906086 0.843256 0.833431C0.914698 0.764052 1.01034 0.725098 1.10992 0.725098C1.2119 0.725602 1.31 0.764247 1.38492 0.833431L6.00159 5.4501L10.6183 0.833431C10.6932 0.764247 10.7913 0.725602 10.8933 0.725098C10.9925 0.726868 11.0876 0.765482 11.1599 0.833431C11.1982 0.870385 11.2268 0.916169 11.2433 0.966764C11.2556 1.01318 11.2556 1.06202 11.2433 1.10843C11.255 1.15775 11.255 1.20911 11.2433 1.25843C11.2285 1.30743 11.1995 1.35094 11.1599 1.38343L6.54326 6.0001L11.1599 10.6168Z" fill="#132528" stroke="#132528" stroke-width="0.5"/>
            </svg>
          </button>
          <figure>
            <nuxt-img loading="lazy" :src="product.thumbnail.url" alt=""/>
          </figure>

          <h4>{{ product.name }}</h4>
        </div>
      </template>

      <button class="compare-products__box compare-products__box--cta" v-if="compareProductsData.length === 0" @click.prevent="disabled = false">
        Add Product
      </button>

      <button class="compare-products__box compare-products__box--cta" v-if="compareProductsData.length === 0" @click.prevent="disabled = false">
        Add Product
      </button>

      <button class="compare-products__box compare-products__box--cta" v-if="compareProductsData.length === 1" @click.prevent="disabled = false">
        Add Product
      </button>

      <button class="compare-products__box compare-products__box--cta" v-if="compareProductsData.length < 3" @click.prevent="disabled = false">
        Add Product
      </button>

      <div class="compare-products__cta-placeholder" >
        <p v-if="compareProductsData.length < 2">Add at least one more product to compare</p>

        <!-- <p class="alert">You can compare up to 3 products.</p>-->

        <div>
          <!--        :disabled="[{ disabled: compareProducts.length === 0}]"-->
          <button class="btn--orange" :class="[{disabled: compareProductsData.length < 2}]"  @click="compareProductsMethod">Compare</button>
          <button class="btn--orange-outline" @click.prevent="closeCompareModel">Cancel</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {defineComponent, watch, ref, nextTick, computed, onBeforeUnmount} from "@nuxtjs/composition-api";
import {useUiState} from "~/composables";
import {useCategoryStore} from "~/modules/catalog/category/stores/category";
import compareProductsDetailModel from "~/modules/catalog/category/components/views/compareProductsDetailModel";
import {eventBus} from "~/almarwan/helpers/EventBus";

export default defineComponent({
  name: "CompareProductModel",
  components: {
    compareProductsDetailModel
  },
  data() {
    return {
      compare: false,
      disabled: true,
    }
  },
  mounted() {
    eventBus.$on('closeCompareProduct', () => {
      this.closeCompareModel()
    })
  },
  watch: {
    compareProductsData (newVal, oldVal) {
      if (newVal !== oldVal && newVal.length === 0) {
        this.closeCompareModel()
      }
    }
  },
  setup() {
    const { isCompareProductModalOpen, toggleCompareProductModal, toggleCompareProductDetailModal, isCompareProductDetailModalOpen, disappearCompare, setDisappearCompare} = useUiState();
    const { compareProducts, $state, emptyCompareProductArray, $patch} = useCategoryStore()
    const store = useCategoryStore()
    let compareProductsData = ref([])
    // let { compareProducts, $state } = storeToRefs(change)
    compareProductsData.value = compareProducts
    const compareProductsArr = computed(() => compareProducts)
    const removeProductFromCompare = (product) => {
      $state.compareProducts = compareProductsData.value.filter((item) => item.id !== product.id)
      if (compareProductsData.value.length === 0 || compareProductsData.value.length === 1) {
        closeCompareModel()
        
      }
    }
    const compareProductsMethod = () => {
      toggleCompareProductDetailModal()
      document.body.style.overflowY = 'hidden'
    }

    const closeCompareModel = () => {
      $state.compareProducts = []
      toggleCompareProductModal()
    }
    store.$subscribe(async (mutation, state) => {
      compareProductsData.value = state.compareProducts
    })
    onBeforeUnmount(() => {
      $patch((state)=>{
        state.compareProducts = []
      })
    })
    return {
      store,
      compareProductsArr,
      compareProductsData,
      compareProductsMethod,
      toggleCompareProductDetailModal,
      isCompareProductDetailModalOpen,
      closeCompareModel,
      removeProductFromCompare,
      compareProducts,
      isCompareProductModalOpen,
      toggleCompareProductModal,
      disappearCompare,
      setDisappearCompare,
      emptyCompareProductArray,
      $patch,
    }
  }
})



</script>

<style lang="scss" scoped>

</style>
