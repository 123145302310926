import { defineStore } from 'pinia';
import type { Cart, SelectedShippingMethod } from '~/modules/GraphQL/types';


interface CustomerState {
  cart: Cart,
  formReadyForSubmit: Boolean,
  shippingAdressFilled: Boolean,
  billingAddressFilled: Boolean,
  cardDetailsFilled: Boolean,
  sameAsShippingchecked: Boolean,
  tAndcChecked: Boolean,
  payNowLoading: Boolean,
  //
  totalShippingAmount: any,
  allAddressesArePickup: boolean,
  cartProductQuantity: number,
  onCheckoutPaymentPage: boolean,
  multiShippingAddresses: boolean,
  activeStep: number,
  financeProductOnCart: boolean,
  checkShippingMethod: boolean,
  payBalanceCart: boolean
}

export const useCartStore = defineStore('cart', {
  state: (): CustomerState => ({
    cart: {
      id: '', is_virtual: false, total_quantity: 0, shipping_addresses: [],

    },
    formReadyForSubmit: false,
    shippingAdressFilled: false,
    billingAddressFilled: false,
    cardDetailsFilled: false,
    sameAsShippingchecked: false,
    tAndcChecked: false,
    payNowLoading: false,
    totalShippingAmount: 0,
    allAddressesArePickup: false,
    cartProductQuantity: 0,
    onCheckoutPaymentPage: false,
    multiShippingAddresses: false,
    activeStep: 0,
    financeProductOnCart: false,
    checkShippingMethod: false,
    payBalanceCart: false
  }),
});
