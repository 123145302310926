
















import { defineComponent } from '@vue/composition-api'
import { useUiState } from '../../../../../composables';
import RelatedProducts from '~/modules/catalog/product/components/RelatedProducts.vue';
import { ref } from '@nuxtjs/composition-api';
import {useCustomerStore} from "~/modules/customer/stores/customer";

export default defineComponent({
  components: {
    RelatedProducts
  },
  setup() {
    const {isSimilarItemsModalOpen, toggleSimilarItemsModal, skuForSimilarItem, setSkuForSimilarItem} = useUiState();
    const customerStore = useCustomerStore();
    const itemName = ref('');
    const rentAndBuy = customerStore.isRentOrBuy
    if (isSimilarItemsModalOpen.value) {
      let bodyElement = document.querySelector('body')
      bodyElement.classList.add('has-overflow')
    }
    if(skuForSimilarItem.value !== '') {
      itemName.value = skuForSimilarItem.value?.split('|')?.[1]
    }
    const closeModal = () => {
        toggleSimilarItemsModal();
        setSkuForSimilarItem('')
        let bodyElement =   document.querySelector('body')
        bodyElement.classList.remove('has-overflow')
      };

    return {
      rentAndBuy,
      isSimilarItemsModalOpen,
      closeModal,
      itemName
    }
  },
})
