























import { computed, defineComponent, onMounted, ref, useContext, useRouter, watch } from '@nuxtjs/composition-api';
import SkeletonLoader from '~/components/SkeletonLoader/index.vue';
import { useUiState, useUser } from '~/composables';
import { eventBus } from '~/almarwan/helpers/EventBus';
export default defineComponent({
  name: 'User',
  components: { SkeletonLoader },
  props: {
    user: {
      type: Object,
      default: (()=>{})
    }
  },
  setup(props) {
    const { isAuthenticated } = useUser();
    const { toggleLoginModal } = useUiState();
    const router = useRouter();
    const { app } = useContext();
    const updatedImage = ref('')
    const updatedCheckImage = ref(false)
    const userInitialTxt = ref('')
    const handleAccountClick = async () => {
      const localStorageValue = localStorage.getItem('isAuth');
      if (isAuthenticated.value) {
        // if (localStorageValue == null) {
        //   window.location.reload();
        //   return
        // }
        await router.push(app.localeRoute({ name: 'customer-my-profile' }));
      } else {
        toggleLoginModal();
      }
    };
    const getUserInitial = () => {
      const name = props.user?.firstname
      if (name) {
        const nameWithoutSpaces = name.replace(/^\s+|\s+$/gm, '')
        const splitName = nameWithoutSpaces.split(" ");
        if (splitName.length === 1) {
          userInitialTxt.value = props.user.firstname[0]
          return props.user?.firstname[0]
        }
        const slug_1 = splitName[0];
        const slug_2 = splitName.pop();
        return slug_1[0] + slug_2[0]
      }
      return 'AA'
    }

    onMounted(() => {
      eventBus.$on('profilePictureUpdate', (data, justUploaded) => {
        updatedImage.value = data
        updatedCheckImage.value = justUploaded
      })
    })
    // watch(()=> user.value,() => {
    //   if (user.value?.profile_picture !== undefined) {
    //     updatedCheckImage.value = false
    //   }
    // })
    return {
      handleAccountClick,
      updatedCheckImage,
      getUserInitial,
      isAuthenticated,
      updatedImage,
      userInitialTxt
    }
  }
});
