


























































































































import {computed, defineComponent, onMounted, ref, useContext, watch} from '@nuxtjs/composition-api';
import type {PropType} from '@nuxtjs/composition-api';
import {required, email} from 'vee-validate/dist/rules';
import {ValidationProvider, ValidationObserver, extend} from 'vee-validate';
import {
  SfInput,
  SfButton,
  SfLoader,
} from '@storefront-ui/vue';
import {LoginFormFields} from './types';
import Icon from '@/almarwan/components/Icon.vue'
import {eventBus} from '~/almarwan/helpers/EventBus';
import useUser from "~/modules/customer/composables/useUser";
import {useCustomerStore} from "~/modules/customer/stores/customer";
import {useCart, useUiState, useWishlist} from "~/composables";
import userGetters from '~/modules/customer/getters/userGetters';
import {clickOutside} from "~/components/directives/click-outside/click-outside-directive";

extend('email', {
  ...email,
  message: 'Invalid email',
});

extend('required', {
  ...required,
  message: 'This field is required',
});

export default defineComponent({
  directives: { clickOutside },
  components: {
    SfInput,
    SfButton,
    SfLoader,
    ValidationObserver,
    ValidationProvider,
    Icon
  },
  data() {
    return {
      activeInput: '',
      isHovered: '',
      inputError: '',
      showPassword: false,

    }
  },
  methods: {
    setActive(item) {
        this.activeInput = item;
      // @ts-ignore: Unreachable code error
      item === 'email' ? this.$refs.formEmail.focus() : null
      // @ts-ignore: Unreachable code error
      item === 'password' ? this.$refs.formPassword.focus() : null
    },
    clearInput(item) {
      this.formCopy[item] = ''
      if (this.activeInput === item) {
        this.activeInput = '';
      }
    },

    handleKeyUp(event , invalid ,formCopy){
      if (event.key === 'Enter' || event.keyCode === 13 || event.which === 13) {
        event.preventDefault();
        if(!invalid) this.$emit('submit' , formCopy)
      }
    },
    handleHover(inputField){
      this.isHovered = inputField.target.name

      },

    handleClickOutside(event) {
        this.activeInput = '';
    },
  },
  props: {
    form: {
      type: Object as PropType<LoginFormFields>,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    showRecaptcha: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
      default: '',
    }, errorIcon: {
      type: String,
      default: '',
    },
  },

  setup(props, {emit}) {
    const formCopy = ref<LoginFormFields>();
    const {user, generateSocialCustomerToken} = useUser()
    const customerStore = useCustomerStore();
    const {app, $config, env} = useContext();
    const YOUR_GOOGLE_CLIENT_ID = env.GOOGLE_CLIENT_ID;
    const MICROSOFT_REDIRECT_URI = env.MICROSOFT_REDIRECT_URI;
    const MICROSOFT_CLIENT_ID =  env.MICROSOFT_CLIENT_ID;
    const {toggleLoginModal, isLoginModalOpen, productInCart, setSocialLogin, socialLogin} = useUiState();
    const { loadItemsCount, addOrRemoveItem} = useWishlist();
    const { load: loadCart, setCart } = useCart();
    let myMSALObj = null;

    watch(() => props.form, (newForm) => {
      formCopy.value = {...newForm};
    }, {immediate: true, deep: true});

    const changeForm = (form) => {
      emit('change-form', form);
    };

    const userDetails = computed(() => {
      return {
        telephone: userGetters.getPhoneNumber(user.value)
      }
    });

    const loginBtnClick = () => {
      // initialize Google Sign in
      // @ts-ignore: Unreachable code error
      google.accounts.id.initialize({
        client_id: YOUR_GOOGLE_CLIENT_ID,
        context: 'signin'
      })

      // @ts-ignore: Unreachable code error
      google.accounts.oauth2.initTokenClient({
        client_id: YOUR_GOOGLE_CLIENT_ID,
        scope: 'profile \
          email \
          openid',
        include_granted_scopes: true,
        callback: getCustomerLoginToken
      }).requestAccessToken()
    }
    // Config object to be passed to Msal on creation
    const msalConfig = {
      auth: {
        clientId: MICROSOFT_CLIENT_ID,
        authority: "https://login.microsoftonline.com/common",
        redirectUri: MICROSOFT_REDIRECT_URI,
      },
      cache: {
        cacheLocation: "sessionStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
      }
    };

    // Add scopes for the id token to be used at Microsoft identity platform endpoints.
    const loginRequest = {
      scopes: ["openid", "profile", "User.Read"],
      };

    const loginWithMicrosoft = async() => {
      // @ts-ignore: Unreachable code error
      myMSALObj = new Msal.UserAgentApplication(msalConfig);
      await myMSALObj.loginPopup(loginRequest)
        .then((loginResponse) => {
          setTimeout(() => {
            getAccessToken()
          }, 1000);
      }).catch(function (error) {
          console.log(error);
      });
    }
    const getAccessToken = async() => {
      await myMSALObj.acquireTokenSilent(loginRequest)
      .then((loginResponse) => {
          getCustomerLoginToken(loginResponse)
        }).catch(function (error) {
        myMSALObj.acquireTokenPopup(loginRequest).then(
          function (accessTokenResponse) {
          // Acquire token interactive success
          let accessToken = accessTokenResponse.accessToken;
          getCustomerLoginToken(accessTokenResponse)
        })
        .catch(function (error) {
          console.log(error);
        });
        console.log(error);
      });
    }

    const closeModal = () => {
      let bodyElement = document.querySelector('body')
      if (bodyElement) bodyElement.classList.remove('has-overflow')
    };

    const getCustomerLoginToken = async (response) => {
      let socialAccessToken = '';
      let socialPlatfrom = '';
      if(response && response.access_token !== undefined) {
        socialAccessToken = response.access_token;
        socialPlatfrom = 'google'
      }
      else if(response && response.accessToken !== undefined) {
        socialAccessToken = response.accessToken;
        socialPlatfrom = 'live'
      }
      if (socialAccessToken !=='') {
        //
        let appState = app.context.$vsf.$magento.config.state
        const data = await generateSocialCustomerToken(socialPlatfrom, socialAccessToken)
        // @ts-ignore: Unreachable code error
        if (data && data.generateSocialCustomerToken && data.generateSocialCustomerToken.token) {
          customerStore.setIsLoggedIn(true);
          setSocialLogin(true);
          const randomString = [...Array(10)].map(() => String.fromCharCode(97 + Math.floor(Math.random() * 26))).join('');
          localStorage.setItem('isAuth',randomString)
          // @ts-ignore: Unreachable code error
          appState.setCustomerToken(data.generateSocialCustomerToken.token);
            eventBus.$emit('updateUserInAppHeader')
          // merge existing cart with customer cart
          const currentCartId = appState.getCartId();
          const cart = await app.context.$vsf.$magento.api.customerCart();
          const newCartId = cart.data.customerCart.id;

          try {
            if (newCartId && currentCartId && currentCartId !== newCartId) {
              const { data: dataMergeCart } = await app.context.$vsf.$magento.api.mergeCarts(
                {
                  sourceCartId: currentCartId,
                  destinationCartId: newCartId,
                },
              );

              // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
              setCart(dataMergeCart.mergeCarts);

              appState.setCartId(dataMergeCart.mergeCarts.id);
            } else {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
              setCart(cart.data.customerCart);
            }
          } catch {
            setCart(cart.data.customerCart);
          }

          await Promise.all([loadItemsCount(), loadCart()]);
          await addGuestWishlistToAccount()
        }
        if(!productInCart.value && userDetails.value?.telephone !== null) {
          setSocialLogin(false);
          emit('loginSuccess', true)
          closeModal()
          setTimeout(() => {
            if (isLoginModalOpen.value) {
              toggleLoginModal();
            }
          }, 3000)
        }
      }
    }
    const addGuestWishlistToAccount = () => {
      let guestWishlist = []
      if (localStorage.getItem("wishListForGuestUser")) {
        guestWishlist = JSON.parse(localStorage.getItem("wishListForGuestUser"));
        guestWishlist.forEach(async (product) => {
          await addOrRemoveItem({product})
        })
        setTimeout(() => {
          localStorage.setItem("wishListForGuestUser", JSON.stringify([]));
        }, 2000)
      }
    }
    const inputEmpty = () => {
      if (formCopy.value['email'].length == 0 || formCopy.value['password'].length == 0) {
        return true
      }
    };
    watch(()=>userDetails.value, () => {
      if(userDetails.value.telephone !== null && userDetails.value.telephone !== undefined && socialLogin.value) {
        setSocialLogin(false);
        closeModal()
        toggleLoginModal();
      }
    });
    return {
      loginBtnClick,
      formCopy,
      changeForm,
      inputEmpty,
      loginWithMicrosoft
    };
  },
});
