

























































































import { defineComponent, onMounted, ref } from '@nuxtjs/composition-api';
import { eventBus } from '../../../../almarwan/helpers/EventBus';
import { useUiState } from '../../../../composables';

export default defineComponent({
  name: 'CookieModel',
  setup() {
    const {
      toggleCookieModal,
      isCookieModalOpen,
      setShowSignByCooki,
      showSignByCooki,
      setTrackAnalytics,
    } = useUiState();
    const cookies = ref({
      accepted: false,
      necessary: true,
      analytics: false,
      marketing: true,
    });
    const preference = ref(false);
    const noCookie = ref(false);
    const acceptAll = () => {
      cookies.value.accepted = !cookies.value.accepted;
      if (cookies.value.accepted) {
        cookies.value.analytics = true;
        cookies.value.marketing = true;
      }
      localStorage.setItem('cookie-preferences', JSON.stringify(cookies.value));
      eventBus.$emit('cookie-accepted', cookies.value);
      setTrackAnalytics(cookies.value);
      toggleCookieModal();
      setShowSignByCooki();
    };

    const closeCookie = () => {
      cookies.value.accepted = false;
      cookies.value.analytics = false;
      cookies.value.marketing = false;
      eventBus.$emit('cookie-accepted', cookies.value);
      toggleCookieModal();
      setShowSignByCooki();
    };
    const managePreference = () => {
      preference.value = true;
    };
    const goBack = () => {
      preference.value = false;
    };
    const confirmChoice = () => {
      if (cookies.value.analytics || cookies.value.marketing) {
        cookies.value.accepted = true;
      }
      localStorage.setItem('cookie-preferences', JSON.stringify(cookies.value));
      eventBus.$emit('cookie-accepted', cookies.value);
      toggleCookieModal();
      setShowSignByCooki();
    };
    const changeMarketing = () => {
      cookies.value.marketing = !cookies.value.marketing;
    };
    const changeAnalytic = () => {
      cookies.value.analytics = !cookies.value.analytics;
    };
    onMounted(() => {
      const savedCookies = JSON.parse(
        localStorage.getItem('cookie-preferences')
      );
      if (savedCookies) {
        noCookie.value = false;
        eventBus.$emit('cookie-accepted');
        const trackVals = {
          accepted: savedCookies.accepted,
          marketing: savedCookies.marketing,
          analytics: savedCookies.analytics,
        };
        setTrackAnalytics(trackVals);
        toggleCookieModal();
      } else {
        noCookie.value = true;
      }
    });

    return {
      closeCookie,
      changeMarketing,
      changeAnalytic,
      cookies,
      goBack,
      confirmChoice,
      preference,
      managePreference,
      acceptAll,
      setShowSignByCooki,
      showSignByCooki,
      isCookieModalOpen,
      noCookie,
    };
  },
  computed: {
    isRtl() {
      // Determine if RTL should be applied based on the current locale
      return this.$i18n.locale === 'ar';
    },
  },
});
