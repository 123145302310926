export interface SortingModel {
  selected: string,
  options: SortingOption[]
}

export interface SortingOption {
  label: string,
  value: SortingOptionsValuesEnum
}

export enum SortingOptionsValuesEnum {
  DEFAULT     = 'POSITION',
  RECENT_DESC = 'entity_id_DESC',
  PRICE_ASC   = 'PRICE_ASC',
  PRICE_DESC  = 'PRICE_DESC',
  YEAR_ASC    = 'YEAR_ASC',
  YEAR_DESC   = 'YEAR_DESC',
}

export const sortingOptions: SortingOption[] = [
  {
    label: 'Relevant',
    value: SortingOptionsValuesEnum.DEFAULT,
  },
  {
    label: 'Recent',
    value: SortingOptionsValuesEnum.RECENT_DESC,
  },
  {
    label: 'Lowest Price',
    value: SortingOptionsValuesEnum.PRICE_ASC,
  },
  {
    label: 'Highest Price',
    value: SortingOptionsValuesEnum.PRICE_DESC,
  },
  {
    label: 'Oldest Model',
    value: SortingOptionsValuesEnum.YEAR_ASC,
  },
  {
    label: 'Newest Model',
    value: SortingOptionsValuesEnum.YEAR_DESC,
  },
];
