/**
 * GraphQL Query that fetches products using received search term and the params
 * for filter, sort and pagination.
 */
export default `
  query getFacetData($search: String = "", $filter: ProductAttributeFilterInput, $pageSize: Int = 10, $currentPage: Int = 1, $sort: ProductAttributeSortInput, $cur_filter: String) {
    products(search: $search, filter: $filter, pageSize: $pageSize, currentPage: $currentPage, sort: $sort, cur_filter: $cur_filter) {
      aggregations {
        label
        count
        attribute_code
        options {
          count
          label
          value
          __typename
        }
        position
        __typename
      }
      items {
        __typename
        id
        uid
        sku
        name
        can_makeoffer
        can_inquiry
        country_of_origin
        country_of_manufacture
        logo_tag
        engine_model
        listings
        base_url_360
        finance_type
        as_ext_max_lay_width
        crc_boom_lift_cap
        ex_bucket_cap
        fk_load_cap
        gs_rated_power
        lf_platform_cap
        ml_milling_width
        ro_comp_width
        ro_frequency
        tr_trench_depth
        wl_bucket_cap
        engine_power
        tt_blade_cap
        mhl_operating_cap
        engine_make
        mg_blade_width
        adw_cap
        cr_hopper_load_vol
        pc_max_give_height
        jk_operating_press
        jk_carrier_machine_tonnage
        jk_oil_flow_rate
        mt_mix_cap
        lf_max_reach
        crb_press
        crb_carry_range
        crb_crush_cap
        vh_carry_range
        vh_stat_movement
        vh_dynamic_mass
        dump_payload
        max_pile_depth
        boom_length
        boom_sections
        chassis
        water_tank_capacity
        drum_driven_by
        axles_truck
        lf_max_operator_no
        power
        available_for_tags {
          label
          value
        }
        categories {
          uid
          id
          level
          name
        }
        logo_tag
        enable_finance
        reserve_fee
        stock_status
        meta_title
        meta_description
        condition
        machine_weight
        brand
        engine_model
        year
        hours
        filter_milage
        location
        certified_operator
        uses
        special_price
        special_to_date
        thumbnail {
          url
          position
          disabled
          label
        }
        url_key
        url_rewrites {
          url
        }
        price_range {
          maximum_price {
            final_price {
              currency
              value
            }
          }
          minimum_price {
            final_price {
              currency
              value
            }
            regular_price {
              currency
              value
            }
            discount {
              amount_off
              percent_off
            }
          }
        }
      }
      page_info {
        current_page
        page_size
        total_pages
      }
      total_count
    }
  }
`;
