



























































































































































import { computed, defineComponent, PropType } from '@nuxtjs/composition-api';
import productGetters from '~/modules/catalog/product/getters/productGetters';
import useWishlist from '~/modules/wishlist/composables/useWishlist';
import type { Product } from '~/modules/catalog/product/types';
import useUiHelpers from '../../../../composables/useUiHelpers';
import SkeletonLoader from '~/components/SkeletonLoader/index.vue';
import WishlistGuest from '~/components/General/WishlistGuest.vue';

export default defineComponent({
  name: 'ProductsCarousel',
  components: {
    WishlistGuest,
    Carousel:
      typeof window !== 'undefined' ? () => import('vue-owl-carousel') : null,
    SkeletonLoader,
  },
  props: {
    title: {
      type: String,
      required: false,
      default: '',
    },
    products: {
      type: Array as PropType<Product[]>,
      required: false,
      default: () => [],
    },
    loading: Boolean,
    rentOrBuyVal: {
      type: String,
      default: 'rent',
    },
  },
  setup(props) {
    const { isInWishlist } = useWishlist();
    const { numberWithCommas, getCurrentYear } = useUiHelpers();
    const mappedProducts = computed(() =>
      props.products.map((product) => ({
        ...product,
        // @ts-ignore: Unreachable code error
        isInWishlist: computed(() => isInWishlist({ product })),
      }))
    );
    const sortedProducts = computed(() => {
      // @ts-ignore
      const prodsFil = props.products.filter(
        (product) =>
          product['available_for_tags'] &&
          (product['available_for_tags'][0].label == 'For Sale' ||
            product['available_for_tags'][0].label == 'For Rent')
      );
      return prodsFil.length;
    });

    const recommendedResponsive = {
      0: {
        items: 1,
        margin: 18,
        center: true,
      },
    };
    const getProductLabel = (product) => {
      if (!product.condition) {
        return null;
      }

      if (product.condition === '621') {
        return 'Used';
      } else if (product.condition === '620') {
        return 'Like New';
      } else if (product.condition === '619') {
        return 'New';
      }
    };
    return {
      numberWithCommas,
      getProductLabel,
      mappedProducts,
      productGetters,
      recommendedResponsive,
      sortedProducts,
      getCurrentYear,
    };
  },
});
