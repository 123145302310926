import { readonly, ref, useContext } from '@nuxtjs/composition-api';
import { Logger } from '~/helpers/logger';
import { placeOrderCommand } from '~/modules/checkout/composables/useMakeOrder/commands/placeOrderCommand';
import useCart from '~/modules/checkout/composables/useCart';
import type { PlaceOrderOutput } from '~/modules/GraphQL/types';
import type { UseMakeOrderErrors, UseMakeOrderInterface } from './useMakeOrder';
import { ComposableFunctionArgs } from '~/composables';
// @ts-ignore
import { placeOrderCommandV2 } from "~/modules/checkout/composables/useMakeOrder/commands/placeOrderCommandV2";

/**
 * Allows making an order from a cart.
 *
 * See the {@link UseMakeOrderInterface} for a list of methods and values available in this composable.
 */
export function useMakeOrder(): UseMakeOrderInterface {
  const loading = ref(false);
  const loadingV2 = ref(false);
  const error = ref<UseMakeOrderErrors>({ make: null });
  const errorV2 = ref<UseMakeOrderErrors>({ make: null });
  const { cart, load: loadCart } = useCart();
  const context = useContext();

  const make = async (params?: ComposableFunctionArgs<{}>): Promise<PlaceOrderOutput | null> => {
    Logger.debug('useMakeOrder.make');
    let placedOrder = null;
    try {
      loading.value = true;
      placedOrder = await placeOrderCommand.execute(context, cart.value.id, params?.customQuery ?? null);
      error.value.make = null;
    } catch (err) {
      error.value.make = err;
      Logger.error('useMakeOrder.make', err);
    } finally {
      loading.value = false;
    }

    return placedOrder;
  };
  const makeV2 = async (params?: ComposableFunctionArgs<{}>): Promise<PlaceOrderOutput | null> => {
    Logger.debug('makeOrderV2.make');
    let placedOrder = null;
    try {
      await loadCart()
      loadingV2.value = true;
      placedOrder = await placeOrderCommandV2.execute(context, cart.value?.id, params?.customQuery ?? null);
      if (placedOrder?.errors) {
        errorV2.value.make = placedOrder.errors;
      }
    } catch (err) {
      errorV2.value.make = err;
      Logger.error('makeOrderV2.make', err);
    } finally {
      loadingV2.value = false;
    }
    return placedOrder;
  };

  return {
    make,
    makeV2,
    error: readonly(error),
    errorV2: readonly(error),
    loading: readonly(loading),
    loadingV2: readonly(loading),
  };
}

export * from './useMakeOrder';
export default useMakeOrder;
