import { useRouter, useContext } from '@nuxtjs/composition-api';
import type { Product } from '~/modules/catalog/product/types';
import productGetters from '~/modules/catalog/product/getters/productGetters';
import useCart from '~/modules/checkout/composables/useCart';
import { useProduct } from '~/modules/catalog/product/composables/useProduct';
import { Params } from '~/composables';

export const useAddToCart = () => {
  const {
    addItem: addItemToCartBase,
    isInCart,
    load
  } = useCart();
  const router = useRouter();
  const { app } = useContext();
  const { getProductPath } = useProduct();
  const addItemToCart = async (params: { product: Product, quantity: number, custom_option?: { reserve_order?: boolean, reserve_makeoffer?: number, finalize_makeoffer?: number, reserve_finance?: number }, selected_options? }) => {
    const { product, quantity, custom_option, selected_options } = params;
    // @ts-ignore
    // eslint-disable-next-line no-underscore-dangle
    const productType = product.__typename;
    switch (productType) {
      case 'SimpleProduct':
        await addItemToCartBase({
          product,
          quantity,
          custom_option,
          selected_options
        });
        await load()
        break;
      case 'BundleProduct':
      case 'ConfigurableProduct':
      case 'GroupedProduct':
        const path = app.localeRoute(getProductPath(product));

        await router.push(path);
        break;
      default:
        throw new Error(`Product Type ${productType} not supported in add to cart yet`);
    }
  };

  return {
    addItemToCart,
    isInCart,
  };
};
