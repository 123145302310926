import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _6f4fcba8 = () => interopDefault(import('../almarwan/views/home/Home.vue' /* webpackChunkName: "" */))
const _c88352b4 = () => interopDefault(import('../modules/checkout/pages/Checkout.vue' /* webpackChunkName: "" */))
const _ee37755c = () => interopDefault(import('../pages/Cms.vue' /* webpackChunkName: "pages/Cms" */))
const _4a7f6fc7 = () => interopDefault(import('../pages/contactUs.vue' /* webpackChunkName: "" */))
const _f39bf100 = () => interopDefault(import('../modules/catalog/product/components/Financing/Financing.vue' /* webpackChunkName: "" */))
const _cbef300a = () => interopDefault(import('../modules/customer/pages/MyAccount/MyAccount.vue' /* webpackChunkName: "" */))
const _5e760e69 = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressesDetails.vue' /* webpackChunkName: "" */))
const _b7035e1a = () => interopDefault(import('../modules/customer/pages/MyAccount/MyInquiries.vue' /* webpackChunkName: "" */))
const _583122e1 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyMaintenance.vue' /* webpackChunkName: "" */))
const _70a6754a = () => interopDefault(import('../modules/customer/pages/MyAccount/MyNewsletter.vue' /* webpackChunkName: "" */))
const _36bdaf8e = () => interopDefault(import('../modules/customer/pages/MyAccount/MyOffers.vue' /* webpackChunkName: "" */))
const _68e4d6a5 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyProfile/MyProfile.vue' /* webpackChunkName: "" */))
const _8efbaa2e = () => interopDefault(import('../modules/customer/pages/MyAccount/MyReviews.vue' /* webpackChunkName: "" */))
const _6d08499b = () => interopDefault(import('../modules/customer/pages/MyAccount/MyWallet.vue' /* webpackChunkName: "" */))
const _56f82ae7 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyWishlist.vue' /* webpackChunkName: "" */))
const _dc5404ae = () => interopDefault(import('../modules/customer/pages/MyAccount/OrderHistory/OrderHistory.vue' /* webpackChunkName: "" */))
const _2bb6eb3f = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressForm.vue' /* webpackChunkName: "" */))
const _6ef83985 = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressEdit.vue' /* webpackChunkName: "" */))
const _2b89e60e = () => interopDefault(import('../modules/customer/pages/MyAccount/SingleOffer.vue' /* webpackChunkName: "" */))
const _365581aa = () => interopDefault(import('../modules/customer/pages/MyAccount/OrderHistory/SingleOrder/SingleOrder.vue' /* webpackChunkName: "" */))
const _6f34d1a1 = () => interopDefault(import('../modules/customer/pages/MyAccount/OrderHistory/SingleQuote.vue' /* webpackChunkName: "" */))
const _08983306 = () => interopDefault(import('../pages/Dealers/DealersPage.vue' /* webpackChunkName: "" */))
const _04ffffb4 = () => interopDefault(import('../pages/Home.vue' /* webpackChunkName: "pages/Home" */))
const _902d7cf2 = () => interopDefault(import('../pages/Dealers/KobelcoZeroDownPayment.vue' /* webpackChunkName: "" */))
const _0aabb9a7 = () => interopDefault(import('../pages/Dealers/KobelcoLimitedOffer.vue' /* webpackChunkName: "" */))
const _9ecb47b2 = () => interopDefault(import('../modules/catalog/product/components/Maintenance/MaintenancePage.vue' /* webpackChunkName: "" */))
const _4d43dc36 = () => interopDefault(import('../almarwan/views/blog/blog-landing.vue' /* webpackChunkName: "" */))
const _5dcb8344 = () => interopDefault(import('../modules/catalog/category/components/views/OfferConfirmation.vue' /* webpackChunkName: "" */))
const _4db515d6 = () => interopDefault(import('../pages/Page.vue' /* webpackChunkName: "pages/Page" */))
const _51747f53 = () => interopDefault(import('../pages/ResetPassword.vue' /* webpackChunkName: "" */))
const _71d64c0c = () => interopDefault(import('../almarwan/views/services/Services.vue' /* webpackChunkName: "" */))
const _283dec38 = () => interopDefault(import('../pages/Checkout/ExternalCheckoutThankYou.vue' /* webpackChunkName: "pages/Checkout/ExternalCheckoutThankYou" */))
const _33b9fc73 = () => interopDefault(import('../pages/Dealers/DealersDetailsPage.vue' /* webpackChunkName: "pages/Dealers/DealersDetailsPage" */))
const _3552ae30 = () => interopDefault(import('../pages/Dealers/KobelcoLimitedOfferEn.vue' /* webpackChunkName: "pages/Dealers/KobelcoLimitedOfferEn" */))
const _aedaf8d2 = () => interopDefault(import('../almarwan/views/services/MachineryFinancing.vue' /* webpackChunkName: "" */))
const _7fea7eda = () => interopDefault(import('../almarwan/views/services/MachineryMaintenance.vue' /* webpackChunkName: "" */))
const _0b64a962 = () => interopDefault(import('../almarwan/views/services/MachineryRentals.vue' /* webpackChunkName: "" */))
const _664c86a3 = () => interopDefault(import('../almarwan/views/services/NewMachinerySale.vue' /* webpackChunkName: "" */))
const _5a6ac89b = () => interopDefault(import('../almarwan/views/services/PartsAndAttachments.vue' /* webpackChunkName: "" */))
const _61b980ba = () => interopDefault(import('../almarwan/views/services/UsedMachinerySale.vue' /* webpackChunkName: "" */))
const _82db9590 = () => interopDefault(import('../pages/Dealers/components/CustomerStories.vue' /* webpackChunkName: "pages/Dealers/components/CustomerStories" */))
const _25b12521 = () => interopDefault(import('../pages/Dealers/components/CustomProduct.vue' /* webpackChunkName: "pages/Dealers/components/CustomProduct" */))
const _9c04c568 = () => interopDefault(import('../pages/Dealers/components/DealerDetails.vue' /* webpackChunkName: "pages/Dealers/components/DealerDetails" */))
const _39549fca = () => interopDefault(import('../pages/Dealers/components/DealershipAdvantages.vue' /* webpackChunkName: "pages/Dealers/components/DealershipAdvantages" */))
const _e774f88a = () => interopDefault(import('../pages/Dealers/components/DealersLogos.vue' /* webpackChunkName: "pages/Dealers/components/DealersLogos" */))
const _c9661550 = () => interopDefault(import('../pages/Dealers/components/DealerTable.vue' /* webpackChunkName: "pages/Dealers/components/DealerTable" */))
const _bf9ed1b6 = () => interopDefault(import('../pages/Dealers/components/FinancingSupport.vue' /* webpackChunkName: "pages/Dealers/components/FinancingSupport" */))
const _d8c86832 = () => interopDefault(import('../pages/Dealers/components/Hero.vue' /* webpackChunkName: "pages/Dealers/components/Hero" */))
const _0180c902 = () => interopDefault(import('../pages/Dealers/components/RecommendedProducts.vue' /* webpackChunkName: "pages/Dealers/components/RecommendedProducts" */))
const _13e4f07c = () => interopDefault(import('../pages/Dealers/components/TrainingAvailability.vue' /* webpackChunkName: "pages/Dealers/components/TrainingAvailability" */))
const _53d6c663 = () => interopDefault(import('../modules/catalog/pages/category.vue' /* webpackChunkName: "" */))
const _52879acc = () => interopDefault(import('../almarwan/views/blog/single-page.vue' /* webpackChunkName: "" */))
const _3a9006cc = () => interopDefault(import('../modules/catalog/pages/product.vue' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/ar",
    component: _6f4fcba8,
    meta: {"isHomeRoute":true},
    name: "home___ar"
  }, {
    path: "/checkout",
    component: _c88352b4,
    name: "checkout___default"
  }, {
    path: "/Cms",
    component: _ee37755c,
    name: "Cms___default"
  }, {
    path: "/contact-us",
    component: _4a7f6fc7,
    name: "contact-us___default"
  }, {
    path: "/contactUs",
    component: _4a7f6fc7,
    name: "contactUs___default"
  }, {
    path: "/credit-application",
    component: _f39bf100,
    name: "credit-application___default"
  }, {
    path: "/customer",
    component: _cbef300a,
    meta: {"titleLabel":"My Account"},
    name: "customer___default",
    children: [{
      path: "addresses-details",
      component: _5e760e69,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details___default"
    }, {
      path: "my-inquiries",
      component: _b7035e1a,
      meta: {"titleLabel":"My inquiries"},
      name: "customer-my-inquiries___default"
    }, {
      path: "my-maintenance",
      component: _583122e1,
      meta: {"titleLabel":"My Maintenance"},
      name: "customer-my-maintenance___default"
    }, {
      path: "my-newsletter",
      component: _70a6754a,
      meta: {"titleLabel":"My newsletter"},
      name: "customer-my-newsletter___default"
    }, {
      path: "my-offers",
      component: _36bdaf8e,
      meta: {"titleLabel":"My Offers"},
      name: "customer-my-offers___default"
    }, {
      path: "my-profile",
      component: _68e4d6a5,
      meta: {"titleLabel":"My profile"},
      name: "customer-my-profile___default"
    }, {
      path: "my-reviews",
      component: _8efbaa2e,
      meta: {"titleLabel":"My reviews"},
      name: "customer-my-reviews___default"
    }, {
      path: "my-wallet",
      component: _6d08499b,
      meta: {"titleLabel":"Wallet"},
      name: "customer-my-wallet___default"
    }, {
      path: "my-wishlist",
      component: _56f82ae7,
      meta: {"titleLabel":"My wishlist"},
      name: "customer-my-wishlist___default"
    }, {
      path: "order-history",
      component: _dc5404ae,
      meta: {"titleLabel":"Order history"},
      name: "customer-order-history___default"
    }, {
      path: "addresses-details/create",
      component: _2bb6eb3f,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details-new___default"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _6ef83985,
      meta: {"titleLabel":"Addresses details"},
      props: true,
      name: "customer-addresses-details-edit___default"
    }, {
      path: "my-offers/:offerId",
      component: _2b89e60e,
      meta: {"titleLabel":"Offer Details"},
      props: true,
      name: "customer-single-offer___default"
    }, {
      path: "order-history/:orderId",
      component: _365581aa,
      meta: {"titleLabel":"Order history"},
      props: true,
      name: "customer-single-order___default"
    }, {
      path: "single-quote/:quoteId",
      component: _6f34d1a1,
      meta: {"titleLabel":"Quote Detail"},
      props: true,
      name: "customer-single-quote___default"
    }]
  }, {
    path: "/dealers",
    component: _08983306,
    name: "Dealers___default"
  }, {
    path: "/Home",
    component: _04ffffb4,
    name: "Home___default"
  }, {
    path: "/kobelco-excavators-zero-down-payment",
    component: _902d7cf2,
    name: "kobelco-excavators-zero-down-payment___default"
  }, {
    path: "/Kobelco-limited-offer",
    component: _0aabb9a7,
    name: "KobelcoLimitedOffer___default"
  }, {
    path: "/maintenance",
    component: _9ecb47b2,
    name: "maintenance___default"
  }, {
    path: "/news",
    component: _4d43dc36,
    name: "news___default"
  }, {
    path: "/offer-confirmation",
    component: _5dcb8344,
    name: "offer-confirmation___default"
  }, {
    path: "/Page",
    component: _4db515d6,
    name: "Page___default"
  }, {
    path: "/reset-password",
    component: _51747f53,
    alias: "/customer/account/createPassword",
    name: "reset-password___default"
  }, {
    path: "/ResetPassword",
    component: _51747f53,
    name: "ResetPassword___default"
  }, {
    path: "/services",
    component: _71d64c0c,
    name: "services___default"
  }, {
    path: "/ar/checkout",
    component: _c88352b4,
    name: "checkout___ar"
  }, {
    path: "/ar/Cms",
    component: _ee37755c,
    name: "Cms___ar"
  }, {
    path: "/ar/contact-us",
    component: _4a7f6fc7,
    name: "contact-us___ar"
  }, {
    path: "/ar/contactUs",
    component: _4a7f6fc7,
    name: "contactUs___ar"
  }, {
    path: "/ar/credit-application",
    component: _f39bf100,
    name: "credit-application___ar"
  }, {
    path: "/ar/customer",
    component: _cbef300a,
    meta: {"titleLabel":"My Account"},
    name: "customer___ar",
    children: [{
      path: "addresses-details",
      component: _5e760e69,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details___ar"
    }, {
      path: "my-inquiries",
      component: _b7035e1a,
      meta: {"titleLabel":"My inquiries"},
      name: "customer-my-inquiries___ar"
    }, {
      path: "my-maintenance",
      component: _583122e1,
      meta: {"titleLabel":"My Maintenance"},
      name: "customer-my-maintenance___ar"
    }, {
      path: "my-newsletter",
      component: _70a6754a,
      meta: {"titleLabel":"My newsletter"},
      name: "customer-my-newsletter___ar"
    }, {
      path: "my-offers",
      component: _36bdaf8e,
      meta: {"titleLabel":"My Offers"},
      name: "customer-my-offers___ar"
    }, {
      path: "my-profile",
      component: _68e4d6a5,
      meta: {"titleLabel":"My profile"},
      name: "customer-my-profile___ar"
    }, {
      path: "my-reviews",
      component: _8efbaa2e,
      meta: {"titleLabel":"My reviews"},
      name: "customer-my-reviews___ar"
    }, {
      path: "my-wallet",
      component: _6d08499b,
      meta: {"titleLabel":"Wallet"},
      name: "customer-my-wallet___ar"
    }, {
      path: "my-wishlist",
      component: _56f82ae7,
      meta: {"titleLabel":"My wishlist"},
      name: "customer-my-wishlist___ar"
    }, {
      path: "order-history",
      component: _dc5404ae,
      meta: {"titleLabel":"Order history"},
      name: "customer-order-history___ar"
    }, {
      path: "addresses-details/create",
      component: _2bb6eb3f,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details-new___ar"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _6ef83985,
      meta: {"titleLabel":"Addresses details"},
      props: true,
      name: "customer-addresses-details-edit___ar"
    }, {
      path: "my-offers/:offerId",
      component: _2b89e60e,
      meta: {"titleLabel":"Offer Details"},
      props: true,
      name: "customer-single-offer___ar"
    }, {
      path: "order-history/:orderId",
      component: _365581aa,
      meta: {"titleLabel":"Order history"},
      props: true,
      name: "customer-single-order___ar"
    }, {
      path: "single-quote/:quoteId",
      component: _6f34d1a1,
      meta: {"titleLabel":"Quote Detail"},
      props: true,
      name: "customer-single-quote___ar"
    }]
  }, {
    path: "/ar/dealers",
    component: _08983306,
    name: "Dealers___ar"
  }, {
    path: "/ar/Home",
    component: _04ffffb4,
    name: "Home___ar"
  }, {
    path: "/ar/kobelco-excavators-zero-down-payment",
    component: _902d7cf2,
    name: "kobelco-excavators-zero-down-payment___ar"
  }, {
    path: "/ar/Kobelco-limited-offer",
    component: _0aabb9a7,
    name: "KobelcoLimitedOffer___ar"
  }, {
    path: "/ar/maintenance",
    component: _9ecb47b2,
    name: "maintenance___ar"
  }, {
    path: "/ar/news",
    component: _4d43dc36,
    name: "news___ar"
  }, {
    path: "/ar/offer-confirmation",
    component: _5dcb8344,
    name: "offer-confirmation___ar"
  }, {
    path: "/ar/Page",
    component: _4db515d6,
    name: "Page___ar"
  }, {
    path: "/ar/reset-password",
    component: _51747f53,
    alias: "/customer/account/createPassword",
    name: "reset-password___ar"
  }, {
    path: "/ar/ResetPassword",
    component: _51747f53,
    name: "ResetPassword___ar"
  }, {
    path: "/ar/services",
    component: _71d64c0c,
    name: "services___ar"
  }, {
    path: "/Checkout/ExternalCheckoutThankYou",
    component: _283dec38,
    name: "Checkout-ExternalCheckoutThankYou___default"
  }, {
    path: "/Dealers/DealersDetailsPage",
    component: _33b9fc73,
    name: "Dealers-DealersDetailsPage___default"
  }, {
    path: "/Dealers/DealersPage",
    component: _08983306,
    name: "Dealers-DealersPage___default"
  }, {
    path: "/Dealers/KobelcoLimitedOffer",
    component: _0aabb9a7,
    name: "Dealers-KobelcoLimitedOffer___default"
  }, {
    path: "/Dealers/KobelcoLimitedOfferEn",
    component: _3552ae30,
    name: "Dealers-KobelcoLimitedOfferEn___default"
  }, {
    path: "/Dealers/KobelcoZeroDownPayment",
    component: _902d7cf2,
    name: "Dealers-KobelcoZeroDownPayment___default"
  }, {
    path: "/Kobelco-limited-offer/en",
    component: _3552ae30,
    name: "KobelcoLimitedOfferEn___default"
  }, {
    path: "/services/machinery-financing",
    component: _aedaf8d2,
    name: "machinery-financing___default"
  }, {
    path: "/services/machinery-maintenance",
    component: _7fea7eda,
    name: "machinery-maintenance___default"
  }, {
    path: "/services/machinery-rentals",
    component: _0b64a962,
    name: "machinery-rentals___default"
  }, {
    path: "/services/new-machinery-sales",
    component: _664c86a3,
    name: "new-machinery-sales___default"
  }, {
    path: "/services/parts-attachments-sales",
    component: _5a6ac89b,
    name: "parts-attachments-sales___default"
  }, {
    path: "/services/used-machinery-sales",
    component: _61b980ba,
    name: "used-machinery-sales___default"
  }, {
    path: "/ar/Checkout/ExternalCheckoutThankYou",
    component: _283dec38,
    name: "Checkout-ExternalCheckoutThankYou___ar"
  }, {
    path: "/ar/Dealers/DealersDetailsPage",
    component: _33b9fc73,
    name: "Dealers-DealersDetailsPage___ar"
  }, {
    path: "/ar/Dealers/DealersPage",
    component: _08983306,
    name: "Dealers-DealersPage___ar"
  }, {
    path: "/ar/Dealers/KobelcoLimitedOffer",
    component: _0aabb9a7,
    name: "Dealers-KobelcoLimitedOffer___ar"
  }, {
    path: "/ar/Dealers/KobelcoLimitedOfferEn",
    component: _3552ae30,
    name: "Dealers-KobelcoLimitedOfferEn___ar"
  }, {
    path: "/ar/Dealers/KobelcoZeroDownPayment",
    component: _902d7cf2,
    name: "Dealers-KobelcoZeroDownPayment___ar"
  }, {
    path: "/ar/Kobelco-limited-offer/en",
    component: _3552ae30,
    name: "KobelcoLimitedOfferEn___ar"
  }, {
    path: "/ar/services/machinery-financing",
    component: _aedaf8d2,
    name: "machinery-financing___ar"
  }, {
    path: "/ar/services/machinery-maintenance",
    component: _7fea7eda,
    name: "machinery-maintenance___ar"
  }, {
    path: "/ar/services/machinery-rentals",
    component: _0b64a962,
    name: "machinery-rentals___ar"
  }, {
    path: "/ar/services/new-machinery-sales",
    component: _664c86a3,
    name: "new-machinery-sales___ar"
  }, {
    path: "/ar/services/parts-attachments-sales",
    component: _5a6ac89b,
    name: "parts-attachments-sales___ar"
  }, {
    path: "/ar/services/used-machinery-sales",
    component: _61b980ba,
    name: "used-machinery-sales___ar"
  }, {
    path: "/Dealers/components/CustomerStories",
    component: _82db9590,
    name: "Dealers-components-CustomerStories___default"
  }, {
    path: "/Dealers/components/CustomProduct",
    component: _25b12521,
    name: "Dealers-components-CustomProduct___default"
  }, {
    path: "/Dealers/components/DealerDetails",
    component: _9c04c568,
    name: "Dealers-components-DealerDetails___default"
  }, {
    path: "/Dealers/components/DealershipAdvantages",
    component: _39549fca,
    name: "Dealers-components-DealershipAdvantages___default"
  }, {
    path: "/Dealers/components/DealersLogos",
    component: _e774f88a,
    name: "Dealers-components-DealersLogos___default"
  }, {
    path: "/Dealers/components/DealerTable",
    component: _c9661550,
    name: "Dealers-components-DealerTable___default"
  }, {
    path: "/Dealers/components/FinancingSupport",
    component: _bf9ed1b6,
    name: "Dealers-components-FinancingSupport___default"
  }, {
    path: "/Dealers/components/Hero",
    component: _d8c86832,
    name: "Dealers-components-Hero___default"
  }, {
    path: "/Dealers/components/RecommendedProducts",
    component: _0180c902,
    name: "Dealers-components-RecommendedProducts___default"
  }, {
    path: "/Dealers/components/TrainingAvailability",
    component: _13e4f07c,
    name: "Dealers-components-TrainingAvailability___default"
  }, {
    path: "/ar/Dealers/components/CustomerStories",
    component: _82db9590,
    name: "Dealers-components-CustomerStories___ar"
  }, {
    path: "/ar/Dealers/components/CustomProduct",
    component: _25b12521,
    name: "Dealers-components-CustomProduct___ar"
  }, {
    path: "/ar/Dealers/components/DealerDetails",
    component: _9c04c568,
    name: "Dealers-components-DealerDetails___ar"
  }, {
    path: "/ar/Dealers/components/DealershipAdvantages",
    component: _39549fca,
    name: "Dealers-components-DealershipAdvantages___ar"
  }, {
    path: "/ar/Dealers/components/DealersLogos",
    component: _e774f88a,
    name: "Dealers-components-DealersLogos___ar"
  }, {
    path: "/ar/Dealers/components/DealerTable",
    component: _c9661550,
    name: "Dealers-components-DealerTable___ar"
  }, {
    path: "/ar/Dealers/components/FinancingSupport",
    component: _bf9ed1b6,
    name: "Dealers-components-FinancingSupport___ar"
  }, {
    path: "/ar/Dealers/components/Hero",
    component: _d8c86832,
    name: "Dealers-components-Hero___ar"
  }, {
    path: "/ar/Dealers/components/RecommendedProducts",
    component: _0180c902,
    name: "Dealers-components-RecommendedProducts___ar"
  }, {
    path: "/ar/Dealers/components/TrainingAvailability",
    component: _13e4f07c,
    name: "Dealers-components-TrainingAvailability___ar"
  }, {
    path: "/",
    component: _6f4fcba8,
    meta: {"isHomeRoute":true},
    name: "home___default"
  }, {
    path: "/ar/news/pages/:pageNo*",
    component: _4d43dc36,
    name: "news-page___ar"
  }, {
    path: "/ar/c/:slug*",
    component: _53d6c663,
    name: "category___ar"
  }, {
    path: "/ar/dealers/:block_identifier",
    component: _33b9fc73,
    name: "DealershipDetails___ar"
  }, {
    path: "/ar/news/:id(\\d+)/:title",
    component: _52879acc,
    name: "single-news___ar"
  }, {
    path: "/ar/p/:id/:slug.html",
    component: _3a9006cc,
    name: "product___ar"
  }, {
    path: "/news/pages/:pageNo*",
    component: _4d43dc36,
    name: "news-page___default"
  }, {
    path: "/ar/:slug+",
    component: _4db515d6,
    name: "page___ar"
  }, {
    path: "/c/:slug*",
    component: _53d6c663,
    name: "category___default"
  }, {
    path: "/dealers/:block_identifier",
    component: _33b9fc73,
    name: "DealershipDetails___default"
  }, {
    path: "/news/:id(\\d+)/:title",
    component: _52879acc,
    name: "single-news___default"
  }, {
    path: "/p/:id/:slug.html",
    component: _3a9006cc,
    name: "product___default"
  }, {
    path: "/:slug+",
    component: _4db515d6,
    name: "page___default"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
