import { CustomQuery, UseContextReturn } from '~/types/core';
import type { SetShippingMethodsOnCartInputV2, Cart } from '~/modules/GraphQL/types';

export const setShippingMethodsOnCartV2Command = {
  execute: async (context: UseContextReturn, shippingMethodParams: SetShippingMethodsOnCartInputV2, customQuery: CustomQuery): Promise<Cart | null> => {
    // @ts-ignore
    const { data } = await context.app.$vsf.$magento.api.setShippingMethodsOnCartV2(shippingMethodParams, customQuery);

    // TODO: Find out why 'Cart' doesn't match the type of the response data.
    return (data?.setShippingMethodsOnCartV2?.cart as unknown as Cart) ?? null;
  },
};
