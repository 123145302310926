




























































































































































































































































































































































































































































































import { defineComponent, useContext, computed } from '@nuxtjs/composition-api';
import Icon from '../almarwan/components/Icon.vue';
import SubscribeWidget from '../almarwan/components/SubscribeWidget.vue';
import CustomLink from '../components/custom/CLink.vue';
import {
  useStore,
  useUiState,
  useUser,
  AvailableStores,
  useCurrency,
  useMagentoConfiguration,
} from '../composables';

export default defineComponent({
  name: 'FooterSection',
  components: {
    CustomLink,
    Icon,
    SubscribeWidget,
  },
  data() {
    return {
      explore: [],
      usefulLinks: [],
      getInTouch: [],
      selectedLocale: 'English',
      showLanDd: false,
      showCurrDd: false,
    };
  },
  setup() {
    const { stores, change: changeStore } = useStore();
    const { isAuthenticated } = useUser();
    const { toggleMailingListModal, toggleLoginModal, trackAnalytics } =
      useUiState();
    const { currency: currencies, change: changeCurrency } = useCurrency();
    const { selectedCurrency } = useMagentoConfiguration();
    const availableStores = computed<AvailableStores>(() => stores.value ?? []);
    const availableCurrencies = computed<string[]>(
      () => currencies.value?.available_currency_codes || []
    );

    return {
      trackAnalytics,
      isAuthenticated,
      toggleLoginModal,
      toggleMailingListModal,
      stores,
      availableStores,
      availableCurrencies,
      selectedCurrency,
      changeStore,
      changeCurrency,
    };
  },
  mounted() {
    this.explore = [
      {
        title: this.$i18n.t('About Al Marwan'),
        path: '/about',
      },
      {
        title: this.$i18n.t('Al Marwan Group'),
        path: 'https://almarwangroup.com/',
      },
      {
        title: this.$i18n.t('Al Marwan Construct'),
        path: 'https://almarwanconstruct.com/',
      },
      {
        title: this.$i18n.t('Latest news'),
        path: '/news',
      },
      {
        title: this.$i18n.t('Our services'),
        path: '/services',
      },
    ];

    this.usefulLinks = [
      {
        title: this.$i18n.t('Terms & Conditions'),
        path: '/terms',
      },
      {
        title: this.$i18n.t('Location'),
        path: '/contact-us',
      },
      {
        title: this.$i18n.t('Privacy Policy'),
        path: '/privacy-policy',
      },
    ];

    this.getInTouch = [
      {
        icon: 'message',
        path: "https://wa.me/971565433222?text=Hello, I'd like to learn about your current offers on heavy machinery.",
        title: '+(971) 56 543 3222',
      },
      {
        icon: 'mobile',
        path: 'tel:+971565433222',
        title: '+(971) 56 543 3222',
      },
      {
        icon: 'envelope',
        path: 'mailto:hello@almarwan.com',
        title: 'hello@almarwan.com',
      },
    ];
    this.selectedLocale = this.$i18n.locale === 'ar' ? 'Arabic' : 'English';
  },
  methods: {
    showLogin() {
      if (this.isAuthenticated) {
        return;
      }
      this.toggleLoginModal();
    },
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    switchLocale(locale) {
      this.showLanDd = false;
      this.changeStore(locale);
    },
    switchCurrency(currencyId) {
      this.showCurrDd = false;
      this.changeCurrency({ id: currencyId });
    },
    getStoreName(locStore) {
      return locStore.store_code === 'default'
        ? 'English'
        : locStore.store_name;
    },
    getStoreNameTxt(locStore) {
      return locStore.store_code === 'default'
        ? 'English'
        : locStore.store_name;
    },
  },
});
