



































































import { defineComponent } from '@nuxtjs/composition-api';

import MessageBox from '../../almarwan/components/MessageBox.vue';
import Icon from '../../almarwan/components/Icon.vue';
import { useNewsletter } from '../../composables/useNewsletter';
import { useUiNotification } from '../../composables/useUiNotification';

export default defineComponent({
  name: 'SubscribeWidget',
  props: {
    symbol: {
      type: Boolean,
      required: false,
      default: () => true,
    },
    isFooter: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },
  components: {
    MessageBox,
    Icon,
  },
  data() {
    return {
      placeHolder: '',
      activeInput: false,
      email: '',
      loading: false,
      showErrorMsg: false,
    }
  },
  setup() {
    const { send: sendNotification } = useUiNotification();
    const { updateSubscription } = useNewsletter();
    return {
      sendNotification,
      updateSubscription
    }
  },
  async mounted() {
    this.placeHolder = this.$i18n.t('Your Email address').toString();
  },
  methods: {
    async subscribe() {
      const response = await this.updateSubscription({ email: this.email });
      this.loading = false;
      // @ts-ignore: Unreachable code error
      if (response && response?.errors) {
        // @ts-ignore: Unreachable code error
        this.sendNotification({
          id: Symbol('product_added_to_cart_from_wishlist'),
          // @ts-ignore: Unreachable code error
          message: response?.errors[0]?.message,
          type: 'warning',
          icon: 'check',
          persist: false,
          title: 'Newsletter',
        });
      } else {
        this.sendNotification({
          id: Symbol('product_added_to_cart_from_wishlist'),
          message: 'Newsletter subscription successfull.',
          type: 'success',
          icon: 'check',
          persist: false,
          title: 'Newsletter',
        });
      }
    },
    toggleAuthModal() {
      if (
        this.email.match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
      ) {
        this.loading = true;
        this.subscribe();
      } else {
        this.showErrorMsg = true;
        setTimeout(() => (this.showErrorMsg = false), 2000);
      }
    },
  }
});
