



























































































































































































































/* eslint-disable @typescript-eslint/no-unsafe-argument */
import {
  SfLoader,
  SfNotification,
  SfSidebar,
  SfHeading,
  SfButton,
  SfProperty,
  SfPrice,
  SfCollectedProduct,
  SfQuantitySelector,
  SfBadge,
  SfImage,
} from '@storefront-ui/vue';
import {
  computed,
  defineComponent,
  ref,
  useRouter,
  useContext,
  onMounted,
  onBeforeUnmount,
} from '@nuxtjs/composition-api';
import { debounce } from 'lodash-es';
import cartGetters from '~/modules/checkout/getters/cartGetters';
import {
  useUiState,
  useUiNotification,
  useExternalCheckout,
  useImage,
} from '~/composables';
import { useCart } from '~/modules/checkout/composables/useCart';
import { useUser } from '~/modules/customer/composables/useUser';
// import stockStatusEnum from '~/enums/stockStatusEnum';
import SvgImage from '~/components/General/SvgImage.vue';
import type { ConfigurableCartItem, BundleCartItem, CartItemInterface } from '~/modules/GraphQL/types';
import CouponCode from './CouponCode.vue';
import Icon from '@/almarwan/components/Icon.vue'
import useUiHelpers from "../composables/useUiHelpers";

export default defineComponent({
  name: 'CartSidebar',
  components: {
    SfLoader,
    SfNotification,
    SfSidebar,
    SfButton,
    SfHeading,
    SfProperty,
    SfPrice,
    SfCollectedProduct,
    SfQuantitySelector,
    SfBadge,
    CouponCode,
    SvgImage,
    SfImage,
    Icon
  },
  computed: {
    isRtl() {
      // Determine if RTL should be applied based on the current locale
      if(this.$i18n.locale === 'ar') {
        return 'left';
      }
      else {
        return 'right';
      }
      
    },
  },
  setup() {
    const { initializeCheckout } = useExternalCheckout();
    const { isCartSidebarOpen, toggleCartSidebar, toggleLoginModal, setProductInCart } = useUiState();
    const { getMagentoImage, imageSizes } = useImage();
    const { numberWithCommas } = useUiHelpers()
    const router = useRouter();
    const { app } = useContext();
    const {
      cart,
      removeItem,
      updateItemQty,
      load: loadCart,
      loading,
    } = useCart();
    const { isAuthenticated } = useUser();
    const { send: sendNotification, notifications } = useUiNotification();

    const products = computed(() => cartGetters
      .getItems(cart.value)
      .filter(Boolean)
      .map((item) => ({
        ...item,
        product: {
          ...item.product,
          ...[(item as ConfigurableCartItem).configured_variant ?? {}],
          original_sku: item.product.sku,
        },
      })));
    const totals = computed(() => cartGetters.getTotals(cart.value));
    const discount = computed(() => -cartGetters.getDiscountAmount(cart.value));
    const totalItems = computed(() => cartGetters.getTotalItems(cart.value));
    const getAttributes = (product: ConfigurableCartItem) => product.configurable_options || [];
    const getBundles = (product: BundleCartItem) => product.bundle_options?.map((b) => b.values).flat() || [];
    const visible = ref(false);
    const tempProduct = ref();
    const cartExpanded = ref(true);
    const getCategoryName = (prod) => {
      var prodCat = prod?.categories?.filter((cat) => cat.level === 3);
      return prodCat[0]?.name;
    };

    onMounted(() => {
      if (!cart.value?.id) {
        loadCart();
      }
      let bodyElement = document.querySelector('html')
      bodyElement.style.overflow = 'hidden';
    });

    const goToCheckout = async () => {
      if(!isAuthenticated.value) {
        setProductInCart(true)
        toggleLoginModal()
        return
      }
      const redirectUrl = initializeCheckout({ baseUrl: '/checkout' });
      await router.push(app.localePath(redirectUrl));
    };

    const sendToRemove = ({ product }: { product: CartItemInterface }) => {
      if (notifications.value.length > 0) {
        notifications.value[0].dismiss();
      }

      visible.value = true;
      tempProduct.value = product;
    };

    const actionRemoveItem = async (product: CartItemInterface) => {
      await removeItem({ product });
      visible.value = false;

      sendNotification({
        id: Symbol('product_removed'),
        message: product['name'] + ' has been successfully removed from your cart.',
        type: 'success',
        icon: 'check',
        persist: false,
        title: 'Product removed',
      });
    };
    const delayedUpdateItemQty = debounce(
      (params) => updateItemQty(params),
      1000,
    );
    // const isInStock = (product: CartItemInterface) => cartGetters.getStockStatus(product) === stockStatusEnum.inStock;

    const collapseCart = () => {
      cartExpanded.value = false;
    }
    onBeforeUnmount(() => {
      let bodyElement = document.querySelector('html')
      bodyElement.style.overflow = '';
    })

    return {
      sendToRemove,
      actionRemoveItem,
      loading,
      isAuthenticated,
      products,
      removeItem,
      delayedUpdateItemQty,
      isCartSidebarOpen,
      notifications,
      visible,
      tempProduct,
      toggleCartSidebar,
      goToCheckout,
      totals,
      totalItems,
      cartGetters,
      getAttributes,
      getBundles,
      // isInStock,
      imageSizes,
      getMagentoImage,
      discount,
      getCategoryName,
      cartExpanded,
      numberWithCommas
    };
  },
});
