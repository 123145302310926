<template>
  <div class="status-box status-box--is-error">
    <Icon name="alert" />

    <p>
      Make sure you enter the right email address.
    </p>
  </div>
</template>

<script>
import Icon from '@/almarwan/components/Icon.vue'

export default {
  name: "MessageBox",
  components: {
    Icon
  }
}
</script>

<style scoped>

</style>
