import { Logger } from '~/helpers/logger';
import { Cart, UpdateCartItemsInput } from '~/modules/GraphQL/types';
import { VsfContext } from '~/composables/context';

export const updateInstallationCommand = {
  execute: async (context: VsfContext, {
    currentCart,
    product,
    quantity,
    customizable_options,
    custom_option,
    customQuery = { updateCartItems: 'updateCartItems' },
  }) => {
    Logger.debug('[Magento]: Update product quantity on cart', {
      product,
      quantity,
      customizable_options,
      currentCart,
    });

    const updateCartParams: UpdateCartItemsInput = {
      cart_id: currentCart.id,
      cart_items: [
        {
          cart_item_uid: product.uid,
          quantity,
          customizable_options,
          custom_option
        },
      ],
    };

    const result = await context.$magento.api.updateCartItems(updateCartParams, customQuery);

    Logger.debug('[Result]:', result?.data);

    return result?.data?.updateCartItems.cart as unknown as Cart;
  },
};
