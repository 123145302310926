



















































import { defineComponent, ref, watch } from '@nuxtjs/composition-api';
import type { PropType } from '@nuxtjs/composition-api';
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import { required, email } from 'vee-validate/dist/rules';
import { ForgotPasswordFormFields } from './types';
import Icon from '@/almarwan/components/Icon.vue'
import {eventBus} from '@/almarwan/helpers/EventBus'

extend('email', {
  ...email,
  message: 'Invalid email',
});

extend('required', {
  ...required,
  message: 'This field is required',
});

export default defineComponent({
  components: {
    ValidationObserver,
    ValidationProvider,
    Icon
  },
  data(){
    return {
      activeInput: ''
    }
  },
  methods: {
    setActive(item) {
      this.activeInput = item
      // @ts-ignore: Unreachable code error
      item === 'email' ? this.$refs.formEmail.focus() : null
    },
    backToLogin() {
      eventBus.$emit('closeResetPassword', true)
    }
  },
  props: {
    form: {
      type: Object as PropType<ForgotPasswordFormFields>,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    showRecaptcha: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const formCopy = ref<ForgotPasswordFormFields>();
    watch(() => props.form, (newForm) => { formCopy.value = { ...newForm }; }, { immediate: true, deep: true });
    const changeForm = (form) => {
      emit('change-form', form);
    };
    return { formCopy, changeForm };
  },
});
