

















































































import {
  ref,
  defineComponent,
} from '@nuxtjs/composition-api';
import { useUiState } from '~/composables/useUiState';
import { useNewsletter } from '~/composables/useNewsletter';

import Logo from '@/almarwan/components/Logo.vue'
import Icon from '@/almarwan/components/Icon.vue'

export default defineComponent({
  components: {
    Logo,
    Icon,
  },
  data(){
    return {
      activeInput: ''
    }
  },
  methods: {
    setActive(item) {
      this.activeInput = item
      // @ts-ignore: Unreachable code error
      item === 'email' ? this.$refs.formEmail.focus() : null
    }
  },
  setup(_, { emit }) {
    const showError = ref(false);
    const showSuccess = ref(false);
    const showErrorMessage = ref(false);
    const email = ref('');
    const showForm = ref(true);

    const { isMailingListModalOpen } = useUiState();

    const { updateSubscription, loading, error } = useNewsletter();

    if (isMailingListModalOpen.value) {
      let bodyElement = document.querySelector('body')
      bodyElement.classList.add('has-overflow')
    };

    const closeModal = () => {
      let bodyElement = document.querySelector('body')
      bodyElement.classList.remove('has-overflow')
      emit('close')
    };

    const subscribe = async () => {
      showErrorMessage.value = false

      if(!email.value) {
        showErrorMessage.value = true;
        return
      }
      const response = await updateSubscription({ email: email.value });
      // @ts-ignore: Unreachable code error
      if(response?.data?.subscribeEmailToNewsletter?.status === "SUBSCRIBED") {
        showSuccess.value = true
        showForm.value = false
        // @ts-ignore: Unreachable code error
      } else if(response?.errors[0].message.includes('already subscribed')) {
        showError.value = true
        showForm.value = false
      }

    };

    return {
      showError,
      showSuccess,
      showErrorMessage,
      email,
      subscribe,
      isMailingListModalOpen,
      closeModal,
      showForm,
      loading,
    };
  },
});
