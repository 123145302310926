





































import {
  defineComponent,
  useRouter,
  useContext, watch, ref, computed,
} from '@nuxtjs/composition-api';
import {
  useUiState,
  useStore,
  AvailableStores,
  useCurrency, useMagentoConfiguration
} from '~/composables';
import {useUser} from '~/modules/customer/composables/useUser';
import Icon from "~/almarwan/components/Icon.vue";
import {eventBus} from "@/almarwan/helpers/EventBus"

export default defineComponent({
  name: 'NavComponent',
  components: {
    Icon
  },
  data() {
    return {
      selectedLocale: ''
      // navLinks: [
      //   {
      //     name: 'Home',
      //     path: '/',
      //   },
      //   {
      //     name: 'About',
      //     path: '/about',
      //   },
      //   {
      //     name: 'Services',
      //     path: '/services',
      //   },
      //   {
      //     name: 'Agencies',
      //     path: '/dealers',
      //   },
      //   {
      //     name: 'FAQ',
      //     path: '/faq',
      //   },
      //   {
      //     name: 'News',
      //     path: '/news',
      //   },
      //   {
      //     name: 'Contact Us',
      //     path: '/contact-us',
      //   }
      // ]
    }
  },
  props: {
    toggleVal: {
      type: Boolean,
      default: false,
    }
  },
  watch: {
    '$route'() {
      this.isActive = false
      let bodyElement = document.querySelector('body')
      bodyElement.classList.remove('has-overflow')
    },

  },
  mounted() {
    eventBus.$on('toggleNav', () => {
      this.isActive = true

    })
    eventBus.$on('closeNav', () => {
      this.isActive = false
      this.showLanfDd = false;
      let bodyElement = document.querySelector('body')

    })
    eventBus.$on('closeSideBar', (data) => {
      if (this.isActive) {
        this.closeNav()
      }
    })
    if(this.$i18n.locale == 'ar'){
      this.selectedLocale =  'Arabic';
    }
    else {
      this.selectedLocale = 'English'
    }
  },
  beforeDestroy() {
    eventBus.$off('toggleNav');
    eventBus.$off('closeSideBar');
    eventBus.$off('closeNav');
    this.showLanfDd = false;
    this.showCurrDd = false;
  },
  methods: {
    closeNav() {
      this.isActive = false
      this.showLanfDd = false;
    },
    switchLocale(locale) {
      this.showLanfDd = false;
      this.changeStore(locale);
      this.closeNav()
    },
    switchCurrency (currencyId) {
      this.showCurrDd = false;
      this.changeCurrency({id: currencyId})
    },
    getStoreName(locStore) {
      if(locStore.store_code === 'default') {
        return 'English';
      }
      else {
        return locStore.store_name
      }
    },
    getStoreNameTxt(locStore) {
      if(locStore.store_code === 'default') {
        
        return 'English';
      }
      else {
        
        return 'عربي'
      }
    },
  },
  setup(props, {emit}) {
    const router = useRouter();
    const {app} = useContext();
    const {app: { i18n }} = useContext();
    const {isAuthenticated} = useUser();
    const {toggleLoginModal} = useUiState();
    const { stores, change: changeStore, load: loadStores } = useStore();
    const { currency: currencies, change: changeCurrency, load: loadCurrencies } = useCurrency();
    const { selectedCurrency, selectedLocale } = useMagentoConfiguration();
    const isActive = ref(false);
    const showLanfDd = ref(false);
    const showCurrDd = ref(false);
    const availableStores = computed<AvailableStores>(() => stores.value ?? []);
    const availableCurrencies = computed<string[]>(() => currencies.value?.available_currency_codes || []);
    const navLinks = [
      {
        name: i18n.t('Home'),
        path: '/',
      },
      {
        name: i18n.t('About'),
        path: '/about',
      },
      {
        name: i18n.t('Services'),
        path: '/services',
      },
      {
        name: i18n.t('Agencies'),
        path: '/dealers',
      },
      {
        name: i18n.t('FAQ'),
        path: '/faq',
      },
      {
        name: i18n.t('News'),
        path: '/news',
      },
      {
        name: i18n.t('Contact Us'),
        path: '/contact-us',
      }
    ]

    const handleWishlistClick = async () => {
      if (isAuthenticated.value) {
        await router.push(app.localeRoute({name: 'customer-my-wishlist'}));
      } else {
        toggleLoginModal();
      }
    };
    watch(() => props.toggleVal, () => {
      if (props.toggleVal) {
        emit('isOpen', 'false')
        isActive.value = false
        showLanfDd.value = false
        showCurrDd.value = false
      }
    })
    return {
      handleWishlistClick,
      toggleLoginModal,
      isActive,
      navLinks,
      showLanfDd,
      showCurrDd,
      changeStore,
      availableStores,
      availableCurrencies,
      changeCurrency,
      selectedCurrency
    }
  }
});
