


































































































import {computed, useRouter, useContext, onMounted, ref, useRoute, defineComponent} from '@nuxtjs/composition-api';
import { eventBus } from '@/almarwan/helpers/EventBus'
import { useUiState } from '~/composables';
import { useUser } from '~/modules/customer/composables/useUser';
import userGetters from '~/modules/customer/getters/userGetters';
import { useCart } from '~/modules/checkout/composables/useCart';
import {useWishlistStore} from "~/modules/wishlist/store/wishlistStore";
import Icon from "~/almarwan/components/Icon.vue";
import {useCartStore} from "~/modules/checkout/stores/cart";

export default defineComponent({
  name: "Mobile-Nav",
  components: {
    Icon
  },
  data() {
    return {
      activeMobileFilter: false,
      sideBarOpen: false
    }
  },
  mounted() {
    eventBus.$on('removeShowResultBtn', ((data)=>{
      if(!data) {
        this.activeMobileFilter = false
        this.removeScrollBlockers()
      }
    }))
  },
  watch: {
    activeMobileFilter (newVal, oldVal) {
      if(newVal !== oldVal) {
        eventBus.$emit('checkIfShowWhatsappIcon', this.activeMobileFilter)
      }
    },
  },
  methods: {
    removeScrollBlockers() {
      const $body = document.querySelector('body');
      if ($body) {
        $body.style.overflow = '';
        $body.style.position = '';
        $body.style.width = '';
      }
      const bodyElement = document.querySelector('body');
      bodyElement?.classList.remove('overflow--hidden')
    },
    callClearAllMobileBtn () {
      this.activeMobileFilter = false;
      eventBus.$emit('clickClearAllBtn')
    },
    toggleNav() {
      eventBus.$emit('toggleNav')
      // this.isActive = true
    },
    toggleAuthModal() {
      if(!this.isAuthenticated) {
        this.toggleLoginModal();
      }
    },
    showSidebar() {
      eventBus.$emit('showSidebar')

      const bodyElement = document.querySelector('body')
      const filterCta = document.querySelector('.header__addon .filter-cta')
      bodyElement?.classList.add('overflow--hidden')
      filterCta?.classList.add('active')
    },
    showCatFilter() {
      this.sideBarOpen = !this.sideBarOpen;
      if(this.sideBarOpen){
        eventBus.$emit('showSidebar')
        this.activeMobileFilter = true
        const bodyElement = document.querySelector('body')
        const filterCta = document.querySelector('.form-wrapp .filter-cta');

        bodyElement?.classList.add('overflow--hidden')
        filterCta?.classList.add('active')

        const $body = document.querySelector('body');
        $body.style.overflow = 'hidden';
        $body.style.position = 'fixed';
        $body.style.width = '100%';
      }
      else {
        this.activeMobileFilter = false
        eventBus.$emit('closeSideBarToCategory')
      }
    },
    toggleWishlist() {
      if(!this.isAuthenticated) {
        this.toggleLoginModal();
        eventBus.$emit('toggleWishlist')
      }
      else {
        this.$router.push({ path: '/customer/my-wishlist',})
      }
    }
  },
  setup(props) {
    const { toggleFilterSidebar, toggleLoginModal, toggleCartSidebar, isCartSidebarOpen, emptySearchResult } = useUiState();
    const { cart } = useCart();
    const { user, isAuthenticated } = useUser();
    const router = useRouter();
    const route = useRoute();
    const { app } = useContext();
    const wishlistStore = useWishlistStore();
    const guestWishlist = ref([])
    const store = useCartStore();
    const {$patch} = useCartStore();
    const cartActiveStep = ref(0)

    const wishlistItemsQty = computed(() => wishlistStore.wishlist?.items_count ?? 0);

    const wishlistHasProducts = computed(() => wishlistItemsQty.value > 0);

    const updateGuestWishlist = () => {
      if (localStorage.getItem("wishListForGuestUser")) {
        guestWishlist.value = JSON.parse(localStorage.getItem("wishListForGuestUser"))
      }
    }
    const userDetails = computed(() => {
      return {
        firstname: userGetters.getFirstName(user.value),
        profilePic: userGetters.getProfilePicture(user.value)
      }
    });
    const getUserInitial = computed(() => {
      let name = userDetails.value.firstname
      if (name) {
        let splitName = name.split(" ");

        if (splitName.length === 1) {
          return userDetails.value.firstname[0]
        }

        let slug_1 = splitName[0];
        let slug_2 = splitName.pop();
        return slug_1[0] + slug_2[0]
      }
    });
    const closeCartPreview = () => {
      toggleCartSidebar();
    }

    const ifTextHasSpace = (text) => {
      if (text && text.includes(' ')) {
        let name = text.split(' ')
        return name[0]
      }
      return text
    }

    const handleAccountClick = async () => {
      if (isAuthenticated.value) {
        await router.push(app.localeRoute({ name: 'customer-my-profile' }));
      } else {
        toggleLoginModal();
      }
    };
    const callApplyFilterMobileBtn = () => {
      eventBus.$emit('showResults', true)
    }
    const isWishlistPage = computed(() => route.value.path.includes('/customer/my-wishlist'))
    const checkIfCheckoutPage = computed(() => route.value.path.includes('/checkout'))
    const checkIfCategoryPage = computed(() => route.value.path.includes('/c/'))

    store.$subscribe(async (mutation, state) => {
      if(state.activeStep > 0) {
        cartActiveStep.value = state.activeStep
      }
      else{
        cartActiveStep.value = 0
      }
    });
    onMounted(async () => {
      eventBus.$on('updateWishlistInAppHeader', () => {
        updateGuestWishlist();
      })
    });

    return {
      checkIfCategoryPage,
      route,
      callApplyFilterMobileBtn,
      ifTextHasSpace,
      userDetails,
      guestWishlist,
      updateGuestWishlist,
      wishlistHasProducts,
      wishlistItemsQty,
      isAuthenticated,
      toggleLoginModal,
      getUserInitial,
      handleAccountClick,
      closeCartPreview,
      cartTotalItems: computed(() => cart.value?.total_quantity ?? 0),
      isWishlistPage,
      checkIfCheckoutPage,
      isCartSidebarOpen,
      cartActiveStep,
      emptySearchResult
    }
  }
})
